import React, { useEffect, useState } from 'react';
import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  CardHeader,
  Container,
  Spinner,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { Colxx } from 'components/common/CustomBootstrap';
import { injectIntl } from 'react-intl';
import IntlMessages from 'helpers/IntlMessages';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { editPatient, getPatientDetail } from 'utils/API/api';
import LableValueCard from 'components/cards/LableValueCard';
import CONSTANTS from 'utils/CONSTANTS';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
// import { getGenderLabel } from 'helpers/Utils';
import PatientDetailTab from './tabs/patient-detail-tab';
import PatientAddress from './tabs/patient-address';
import PatientEmergencyContact from './tabs/patient-emergency-contact';
import PatientFeedback from './tabs/patient-feedback';
import PatientReport from './tabs/patient-report';

import PatientVisitPage from './tabs/patient-visitpage';
import staticimg from '../../../assets/img/blog/small-2.jpg';
import PatientOrder from './tabs/patient-order';

const PatientDetail = () => {
  const { id: patientId } = useParams();
  const [patientDetails, setPatientDetails] = useState({});
  const [activeTab, setActiveTab] = useState('details');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const patientName =
    patientDetails?.firstName || patientDetails?.lastName
      ? `${patientDetails?.firstName || "-"} ${patientDetails?.lastName || ""}`
      : null;

  useEffect(() => {
    (async () => {
      setLoading(true);

      const res = await getPatientDetail(patientId);
      if (res !== -1) {
        setPatientDetails(res?.data?.data);
      }
      setLoading(false);
    })();
  }, [patientId, refresh]);

  const getActiveTab = (tab) => {
    switch (tab) {
      case 'details':
        return <PatientDetailTab patientDetails={patientDetails} />;
      case 'address':
        return <PatientAddress />;
      case 'emergencyContact':
        return <PatientEmergencyContact />;
      case 'feedback':
        return <PatientFeedback />;
      case 'report':
        return <PatientReport />;
      case 'patientvisitPage':
        return <PatientVisitPage />;
      case 'PatientOrder':
        return <PatientOrder />;
      default:
        return <PatientDetailTab patientDetails={patientDetails} />;
    }
  };

  const getGovernmentType = (type) => {
    let value = '';
    if (type === 'Aadhar Card') {
      value = 'aadharCard';
    }
    return value;
  };

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.EDIT_PATIENT_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;

      console.log(tempValue);
      if (tempValue) {
        payload[field.name] = tempValue;
      }
      if (
        field.name === 'isEmailVerified' ||
        field.name === 'isPremiumMembership' ||
        field.name === 'isMobileVerified'
      ) {
        payload[field.name] = e.target[field.name].checked;
      }
    });

    if (e.target?.governmentId?.files && e.target?.governmentId?.files[0]) {
      payload.govermentId = e.target?.governmentId?.files[0];
      delete payload.governmentId;
    }

    // if (payload?.gender) {
    //   payload.gender = getGenderLabel(payload.gender);
    // }
    if (payload?.governmentType) {
      payload.governmentType = getGovernmentType(payload?.governmentType);
    }
    if (payload?.loginWith) {
      payload.loginWith = payload.loginWith.toLowerCase();
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return formData;
  };

  const editPatientHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (patientDetails?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await editPatient(patientDetails?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const handleChange = (e) => {
    (async () => {
      const payload = new FormData();

      if (e.target?.files[0]) {
        payload.append('profilePic', e.target.files[0]);
        const res = await editPatient(patientDetails?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
        }
      }

      if (e.target?.files[0] === null) {
        payload.append('profilePic', staticimg);
        const res = await editPatient(patientDetails?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
        }
      }
    })();
  };

  return (
    <>
      <Row>
        <Colxx lg="12" md="6" xl="6">
          <Row>
            <Colxx lg="6" xl="12" className="mb-4">
              {patientName || !loading ? (
                <LableValueCard
                  title={patientName}
                  value={patientDetails?.email}
                  logo={patientDetails?.profilePic}
                  btn={handleChange}
                />
              ) : (
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              )}
            </Colxx>
          </Row>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12">
          <Row>
            <Colxx xxs="12" xl="12" className="col-left">
              <Card className="mb-4">
                <CardHeader>
                  <Nav tabs className="card-header-tabs">
                    {CONSTANTS.PATIENT_TABS.map((data) => (
                      <NavItem key={data.id}>
                        <Card
                          className={classnames({
                            active: activeTab === data.tabId,
                            'nav-link': true,
                          })}
                          onClick={() => setActiveTab(data.tabId)}
                        >
                          <IntlMessages id={data.Label} />
                        </Card>
                      </NavItem>
                    ))}
                  </Nav>
                </CardHeader>

                <TabContent activeTab={activeTab}>
                  {CONSTANTS.PATIENT_TABS.map((tab) => (
                    <TabPane tabId={tab.tabId} key={tab.tabId}>
                      <Row>
                        <Colxx sm="12">
                          <CardBody>{getActiveTab(tab.tabId)}</CardBody>
                        </Colxx>
                      </Row>
                    </TabPane>
                  ))}
                </TabContent>

                <Button
                  outline
                  color="primary"
                  style={{
                    marginLeft: 'auto',
                    marginBottom: '14px',
                    position: 'absolute',
                    right: '15px',
                    top: '-50px',
                    zIndex: '100',
                  }}
                  className="top-right-button"
                  onClick={() => setEditModalOpen((previous) => !previous)}
                >
                  <IntlMessages id="survey.add-new-edit" />
                </Button>

                <AddNewModalWithOutButton
                  sidebarMenu="EDIT_PATIENT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editPatient}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editPatientHandler}
                  formData={patientDetails}
                />
              </Card>
            </Colxx>
          </Row>
        </Colxx>
      </Row>
    </>
  );
};
export default injectIntl(PatientDetail);
