import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS, { GENDER, ROUTES } from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  addDoctor,
  deleteDoctor,
  editDoctor,
  getAllDoctor,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import useHttp from 'components/Hook/Use-http';
import { NotificationManager } from 'components/common/react-notifications';
// import staticimg from '../../../assets/img/blog/small-2.jpg';

const Doctor = ({ match }) => {
  const [doctorList, setDoctorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenCSV, setModalOpenCSV] = useState(false);
  const [doctorData, setDoctorData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const API = useHttp();
  // const navigate = useNavigate()
  const handleDeleteDoctor = (clientId) => {
    setDeleteId(clientId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteDoctor = async () => {
    if (deleteId) {
      const res = await deleteDoctor(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditDoctor = (editValue) => {
    if (editValue) {
      setDoctorData({ ...editValue });
      setEditModalOpen(true);
    }
  };
  // const handleViewDoctor = (viewValue) => {
  //   if (viewValue) {
  //     console.log('object');

  //     // `${ROUTES.DOCTOR}/${doctorItem?.id}`;
  //   }
  // };

  // Block Doctor
  const handleBlockDoctor = async (id, checked) => {
    console.log(checked);
    const payload = {
      isBlocked: checked ? '1' : '0',
    };
    const formData = new FormData()
    formData.append('isBlocked', payload?.isBlocked)
    // api.sendRequest(
    //   blockAPI,
    //   () => {
    //     setLoading((prev) => !prev);
    //   },
    //   payload
    // );
    if (id) {

      setLoading((prev) => !prev);
      const res = await editDoctor(id, formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
      }
      setLoading((prev) => !prev);
    }

    // (async () => {

    // })();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllDoctor();
      if (res !== -1) {
        setDoctorList(
          res?.data?.data.map((doctorItem, index) => {
            return {
              no: index + 1,
              ...doctorItem,
              fullName: `${doctorItem?.firstName} ${doctorItem?.lastName}`,
              name: `${doctorItem?.firstName} ${doctorItem?.lastName}`,
              view: `${ROUTES.DOCTOR}/${doctorItem?.id}`,
              deleteData: {
                buttonLabel: 'Delete',
                id: doctorItem?.id,
                onClick: handleDeleteDoctor,
              },
              blockData: {
                checked: !doctorItem?.isBlocked,
                id: doctorItem?.id,
                onClick: handleBlockDoctor,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...doctorItem,
                  profilePic: '',
                },
                onClick: handleEditDoctor,
              },
            };
          })
        );
      }
      setLoading(false);
    })();
  }, [refresh]);

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
      if (field.name === 'isVerified') {
        payload[field.name] = e.target[field.name].checked;
      }
    });

    if (e.target?.profilePic?.files && e.target?.profilePic?.files[0]) {
      payload.profilePic = e.target?.profilePic?.files[0] || null;
    }
    if (e.target?.aadharCard?.files && e.target?.aadharCard?.files[0]) {
      payload.aadharCard = e.target?.aadharCard?.files[0];
    }
    if (payload?.gender) {
      payload.gender = GENDER[payload.gender];
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return formData;
  };
  const prepareEditFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_EDIT_FIRST_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
      if (field.name === 'isVerified') {
        payload[field.name] = e.target[field.name].checked;
      }
    });

    if (e.target?.profilePic?.files && e.target?.profilePic?.files[0]) {
      payload.profilePic = e.target?.profilePic?.files[0] || null;
    }
    if (e.target?.aadharCard?.files && e.target?.aadharCard?.files[0]) {
      payload.aadharCard = e.target?.aadharCard?.files[0];
    }
    if (payload?.gender) {
      payload.gender = GENDER[payload.gender];
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return formData;
  };

  const editDoctorHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (doctorData?.id) {
        const formData = prepareEditFormData(e);

        setLoading(true);
        const res = await editDoctor(doctorData?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addDoctorHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);
      // const payload = new FormData();
      setLoading(true);
      const res = await addDoctor(formData);

      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }

      setLoading(false);
    })();
  };
  const addDoctorCSVHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    function csvToArr(stringVal, splitter) {
      const [keys, ...rest] = stringVal
        .trim()
        .split('\n')
        .map((item) => item.split(splitter));
      const formedArr = rest.map((item) => {
        const object = {};
        keys.forEach((key, index) => {
          object[key] = item.at(index);
        });
        return object;
      });
      return formedArr;
    }
    const BulkEntry = (data) => {
      try {
        const success = Array(data.length).fill(0);
        data.forEach((entry, i) => {
          const payload = new FormData();
          const entryObj = Object.entries(entry);
          entryObj.forEach((el) => {
            payload.append(el[0], el[1]);
          });
          (async () => {
            API.sendRequest(
              CONSTANTS.API.addDoctor,
              () => {
                success[i] = 'success';
              },
              payload
            );
          })();
        });
        setRefresh((previous) => !previous);
        setModalOpenCSV(!modalOpenCSV);
        setTimeout(() => {
          if (success.findIndex((el) => el !== 'success') === -1) {
            NotificationManager.success(
              'all data added successfully',
              '',
              3000,
              null,
              null,
              ''
            );
          }
        }, 100 * data.length);
      } catch (error) {
        NotificationManager.error('something went wrong', '', 3000, null, null);
      }
    };
    const csvFileInput = document.querySelector('#csvDoctor');
    const file = csvFileInput.files[0];
    const reader = new FileReader();

    reader.onload = function (el) {
      console.log('content: ', el.target.result);

      const data = csvToArr(el.target.result, ',');
      console.log(data);
      BulkEntry(data);
      setLoading(false);
    };
    reader.readAsText(file);
    console.log('available');
  };
  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    style={{ marginRight: '10px' }}
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpenCSV(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.importcsv} />
                  </Button>
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addDoctor} />
                  </Button>
                </Container>
                <AddNewModalWithOutButton
                  sidebarMenu="DOCTOR_CSV_MODAL"
                  // dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.importcsv}
                  modalOpen={modalOpenCSV}
                  toggleModal={() => setModalOpenCSV(!modalOpenCSV)}
                  onSubmit={addDoctorCSVHandler}
                  formData={{}}
                />
                <AddNewModalWithOutButton
                  sidebarMenu="DOCTOR_MODAL"
                  dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.addDoctor}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addDoctorHandler}
                  formData={{}}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="DOCTOR_EDIT_FIRST_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editDoctor}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editDoctorHandler}
                  formData={doctorData}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.DOCTOR_LIST}
                  items={doctorList}
                  advisorId={CONSTANTS.TABLE_ID.doctor}
                  filterParams="fullName"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteDoctor}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default Doctor;
