/* eslint-disable no-dupe-keys */
/* eslint-disable no-shadow */
import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS, { ROUTES } from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import { editAppointment, getAllAppointment } from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import moment from 'moment';
// import IntlMessages from 'helpers/IntlMessages';
// import AlertPopup from 'components/alert-popup';

const Appointment = ({ match }) => {
  const [appointmentList, setAppointmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  // const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  // const [deleteId, setDeleteId] = useState(null);

  // console.log(appointmentList);

  const handleEditAppointment = (editValue) => {
    if (editValue) {
      console.log(editValue);
      setAppointmentData({ ...editValue });
      setEditModalOpen(true);
    }
  };



  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.APPOINTMENT_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });

    return payload;
  };

  const editAppointmentHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (appointmentData?.id) {
        const formData = prepareFormData(e);

        // console.log(formData, 'without UTC');


        formData.slotStartTime = moment(formData?.slotStartTime, 'HH:mm:ss').utc().utcOffset('+05:30').format('HH:mm:ss')

        formData.slotEndTime = moment(formData?.slotEndTime, 'HH:mm:ss').utc().utcOffset('+05:30').format('HH:mm:ss')
        formData.date = moment(`${formData?.date} ${formData?.slotStartTime}`, 'YYYY-MM-DD HH:mm:ss').utc().utcOffset('+05:30').format('YYYY-MM-DD')
        formData.followupDaysDateAndTime = moment(`${formData?.followupDaysDateAndTime} ${formData?.slotStartTime}`, 'YYYY-MM-DD HH:mm:ss').utc().utcOffset('+05:30').format('YYYY-MM-DD')

        // console.log(formData, 'with UTC');

        setLoading(true);
        const res = await editAppointment(appointmentData?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllAppointment();
      if (res !== -1) {
        setAppointmentList(
          res?.data?.data.map((Appointment, index) => {
            return {
              no: index + 1,
              ...Appointment,
              patientName: {
                value: `${Appointment?.patient?.firstName} ${Appointment?.patient?.lastName}`,
                link: `${ROUTES.PATIENT}/${Appointment?.patient?.id}`,
              },
              doctorName: {
                value: `${Appointment?.doctor?.firstName} ${Appointment?.doctor?.lastName}`,
                link: `${ROUTES.DOCTOR}/${Appointment?.doctor?.id}`,
              },
              patientFullName: `${Appointment?.patient?.firstName} ${Appointment?.patient?.lastName}`,
              cancellationDateAndTime: Appointment?.cancellationDateAndTime ? moment(Appointment?.cancellationDateAndTime).format('HH:mm:ss YYYY-MM-DD') : '-',
              cancellationFees: Appointment?.cancellationFees || '-',
              cancellationFeesInPercentage: Appointment?.cancellationFeesInPercentage || '-',
              slotStartTimeD: moment(Appointment?.slotStartTime, 'HH:mm:ss').format('LT') || '-',
              slotEndTimeD: moment(Appointment?.slotEndTime, 'HH:mm:ss').format('LT') || '-',
              slotStartTime: moment(Appointment.slotStartTime, 'HH:mm:ss').format('HH:mm:ss'),
              slotEndTime: moment(Appointment.slotEndTime, 'HH:mm:ss').format('HH:mm:ss'),
              dateD: moment(Appointment?.date).format('YYYY-MM-DD'),
              date: moment(Appointment?.date).format('YYYY-MM-DD'),
              followupDaysDateAndTime: moment(Appointment?.followupDaysDateAndTime).format('YYYY-MM-DD'),
              // doctorName: `${AppointmentList?.doctor?.firstName} ${AppointmentList?.doctor?.lastName}`,
              isUserVisited: Appointment.isUserVisited ? 'Yes' : 'No',
              isDoctorVisited: Appointment.isDoctorVisited ? 'Yes' : 'No',
              isReschedule: Appointment.isReschedule ? 'Yes' : 'No',
              slotStartTime: Appointment.slotStartTime,
              isCanceled: Appointment.isCanceled ? 'Yes' : 'No',
              isFakeBooking: Appointment.isFakeBooking ? 'Yes' : 'No',
              cancellationDuration: `${(
                Appointment?.cancellationDuration / 60
              ).toFixed()} min`,
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...Appointment,
                  slotStartTimeD: moment(Appointment?.slotStartTime, 'HH:mm:ss').format('LT') || '-',
                  slotEndTimeD: moment(Appointment?.slotEndTime, 'HH:mm:ss').format('LT') || '-',
                  slotStartTime: moment(Appointment.slotStartTime, 'HH:mm:ss').format('HH:mm:ss'),
                  slotEndTime: moment(Appointment.slotEndTime, 'HH:mm:ss').format('HH:mm:ss'),
                  slotEndTime: moment(Appointment.slotEndTime, 'HH:mm:ss').format('HH:mm:ss'),
                  dateD: moment(Appointment?.date).format('YYYY-MM-DD'),
                  date: moment(Appointment?.date).format('YYYY-MM-DD'),
                  followupDaysDateAndTime: moment(Appointment?.followupDaysDateAndTime).format('YYYY-MM-DD'),
                },
                onClick: handleEditAppointment,
              },
            };
          })
        );
      }
      setLoading(false);
    })();
  }, [refresh]);
  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="APPOINTMENT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editAppointment}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editAppointmentHandler}
                  formData={appointmentData}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.APPOINTMENT_LIST}
                  items={appointmentList}
                  advisorId={CONSTANTS.TABLE_ID.Appointment}
                  filterParams="patientFullName"
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};

export default Appointment;
