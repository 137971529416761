import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';

import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import useHttp from 'components/Hook/Use-http';
// import { NotificationManager } from 'components/common/react-notifications';

const Administration = ({ match }) => {
    const [getAllSubAdmin, setGetAllSubAdmin] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [editData, setEditData] = useState(null)
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const api = useHttp()


    const addSubAdminHandler = (e) => {

        e.preventDefault()

        const payload = {
            name: e.target.name.value,
            email: e.target.email.value,
            role: 'subAdmin'
        }

        const ADD_SUBADMIN_API = { ...CONSTANTS.API.addSubAdmin }
        api.sendRequest(ADD_SUBADMIN_API, () => {
            setModalOpen(false)
            setRefresh(prev => !prev)
        }, payload)

    }

    const editSubAdminHandler = (e) => {

        e.preventDefault()
        const payload = {
            name: e.target.name.value,
            email: e.target.email.value,
        }

        // console.log(payload);

        const EDIT_SUB_ADMIN_API = { ...CONSTANTS.API.editSubAdmin }
        EDIT_SUB_ADMIN_API.endpoint = EDIT_SUB_ADMIN_API.endpoint.replace(':id', editData?.id)

        api.sendRequest(EDIT_SUB_ADMIN_API, () => {
            setEditData(null)
            setRefresh(prev => !prev)
        }, payload)

    }

    const deleteHandler = (id) => {

        setDeleteId(id);
        setIsConfirmationOpen(true);
    }
    const deleteSubAdminHandler = () => {
        const DELETE_SUBADMIN_API = { ...CONSTANTS.API.deleteSubAdmin }
        DELETE_SUBADMIN_API.endpoint = DELETE_SUBADMIN_API.endpoint.replace(':id', deleteId)
        api.sendRequest(DELETE_SUBADMIN_API, () => {
            setIsConfirmationOpen(false)
            setDeleteId(null)
            setRefresh(prev => !prev)
        })
    }

    useEffect(() => {
        const GET_SUB_ADMIN_API = { ...CONSTANTS.API.getAllSubAdmin }

        api.sendRequest(GET_SUB_ADMIN_API, (res) => {
            setGetAllSubAdmin(res?.data?.map((data, i) => {
                return {
                    ...data,
                    no: i + 1,
                    deleteData: {
                        buttonLabel: 'Delete',
                        id: data?.id,
                        onClick: deleteHandler,
                    },

                    editData: {
                        buttonLabel: 'Edit',
                        id: data?.id,
                        onClick: () => {
                            setEditData(data)
                        },
                    },
                }
            }))
        })
    }, [refresh])
    return (
        <Suspense fallback={<div className="" />}>
            <Switch>
                <Route
                    path={`${match.url}`}
                    exact
                    render={() =>
                        !api.isLoading ? (
                            <>
                                <Container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        maxWidth: '100%',
                                        margin: '0px',
                                        padding: '0',
                                        marginTop: '-10px',
                                        marginBottom: '15px',
                                        marginLeft: '-27px',
                                    }}
                                >

                                    <Button
                                        outline
                                        color="primary"
                                        className="top-right-button"
                                        onClick={() => setModalOpen(prev => !prev)}
                                    >
                                        <IntlMessages id={CONSTANTS.TABLE_ID.addSubAdmin} />
                                    </Button>
                                </Container>

                                <AddNewModalWithOutButton
                                    sidebarMenu="ADMINISTRATION_MODAL"
                                    dataId={CONSTANTS.TABLE_ID.add}
                                    modalTitle={CONSTANTS.TABLE_ID.addSubAdmin}
                                    modalOpen={modalOpen}
                                    toggleModal={() => setModalOpen(prev => !prev)}
                                    onSubmit={addSubAdminHandler}
                                    formData={{}}
                                />

                                <AddNewModalWithOutButton
                                    sidebarMenu="ADMINISTRATION_MODAL"
                                    modalTitle={CONSTANTS.TABLE_ID.editSubAdmin}
                                    modalOpen={editData !== null}
                                    toggleModal={() => setEditData(null)}
                                    onSubmit={editSubAdminHandler}
                                    formData={editData}
                                />

                                <ViewTable
                                    headers={CONSTANTS.TABLE_HEADER.ADMINISTRATION_LIST}
                                    items={getAllSubAdmin}
                                    advisorId={CONSTANTS.TABLE_ID.subAdmin}
                                    filterParams='name'
                                />

                                <AlertPopup
                                    isOpen={isConfirmationOpen}
                                    positiveText="Yes"
                                    negativeText="No"
                                    warning="Are you sure you want to delete?"
                                    onNegative={() => {
                                        setIsConfirmationOpen(false);
                                        setDeleteId(null);
                                    }}
                                    onPositive={deleteSubAdminHandler}
                                />
                            </>
                        ) : (
                            <>
                                <Container className="d-flex justify-content-center align-items-center">
                                    <Spinner
                                        animation="border"
                                        className="d-inline-flex m-2 "
                                        color="$theme-color-yellow-granola"
                                    />
                                </Container>
                            </>
                        )
                    }
                />

                <Redirect to="/error" />
            </Switch>
        </Suspense>
    )
}

export default Administration