import moment from 'moment';
import { getTime } from './CONSTANTS';

function commasapratedNumber(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export const isNonEmpty = (value) => value && value.trim() !== '';

export const AnalyticscallRecordsParsers = (callls) => {
  return callls.map((callItem, index) => {
    return {
      ...callItem,
      no: index + 1,
      ...callItem?.Advisor,
      createdAt: moment(callItem?.createdAt).local().format('Do MMMM, YYYY'),
      createdTime: moment(callItem?.createdAt).local().format('h:mm:ss a'),
      V_Get: `₹${
        callItem?.callCost -
        callItem?.advisorCahrge +
        Math.round(callItem?.taxPerc / 100)
      }`,
      callDuration: getTime(callItem?.callCost),
      advisorCahrge: `₹${callItem?.advisorCahrge}`,
      callCost: `₹${callItem?.callCost}`,
      taxPerc: `${callItem?.taxPerc ? callItem?.taxPerc : 0}%`,
      serviceChargePerc: `${
        callItem?.serviceChargePerc ? callItem?.serviceChargePerc : 0
      }%`,
    };
  });
};

export const CallHistoryTableData = (callls) => {
  return callls.map((callItem, index) => {
    return {
      ...callItem,
      no: index + 1,
      userNumber: callItem.userMobile,
      callDuration: getTime(callItem.callDuration),
      callCharges: `₹${callItem.callCost}`,
      date: moment(callItem?.createdAt).local().format('Do MMMM, YYYY'),
      // Time :
    };
  });
};
export const ChatHistoryTableData = (callls) => {
  return callls.map((callItem, index) => {
    return {
      // ...callItem,
      no: index + 1,
      userNumber: callItem.userMobile,
      chatDuration: getTime(callItem.duration),
      chatCharges: `₹${callItem.userCost}`,
      date: moment(callItem?.createdAt).local().format('Do MMMM, YYYY'),
      // Time :
    };
  });
};

export default commasapratedNumber;
