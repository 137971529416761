import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS, { ROUTES } from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import { deleteComplaint, editComplaint, getAllComplaint } from 'utils/API/api';
// import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
// import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import moment from 'moment';
import { NotificationManager } from 'components/common/react-notifications';

const Complaint = ({ match }) => {
  const [complaintList, setComplaintList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const STATUS_OPTIONS = [
    { id: 'Pending', label: 'Pending', value: 'pending' },
    { id: 'Resolve', label: 'Resolve', value: 'resolve' },
  ];

  const handleDeleteComplaint = (clientId) => {
    setDeleteId(clientId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteComplaint = async () => {
    if (deleteId) {
      const res = await deleteComplaint(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  //   const handleEditOrder = (editValue) => {
  //     if (editValue) {
  //       console.log(editValue);
  //       setOrderData({ ...editValue });
  //       setEditModalOpen(true);
  //     }
  //   };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllComplaint();
      if (res !== -1) {
        setComplaintList(
          res?.data?.data.map((complaintItem, index) => {
            return {
              no: index + 1,
              ...complaintItem,
              patientName: {
                value: `${complaintItem?.patient?.firstName || "-"} ${complaintItem?.patient?.lastName || ""}`,
                link: `${ROUTES.PATIENT}/${complaintItem?.patient?.id}`,
              },
              doctorName: {
                value: `${complaintItem?.doctor?.firstName || "-"} ${complaintItem?.doctor?.lastName || ""}`,
                link: `${ROUTES.DOCTOR}/${complaintItem?.doctor?.id}`,
              },
              patientFullName: `${complaintItem?.patient?.firstName || "-"} ${complaintItem?.patient?.lastName || ""}`,
              deleteData: {
                buttonLabel: 'Delete',
                id: complaintItem?.id,
                onClick: handleDeleteComplaint,
              },
              date:
                complaintItem?.createdAt &&
                moment.utc(complaintItem?.createdAt).utcOffset('+05:30').format('YYYY-MM-DD'),
              rdate:
                complaintItem?.resolveDate ? moment(complaintItem?.resolveDate
                ).format('YYYY-MM-DD') : '-',
              status: {
                options: STATUS_OPTIONS,
                defaultOption: complaintItem?.status,
                onChange: (val) => {
                  (async () => {
                    if (complaintItem?.id) {
                      let payload = {};
                      const tempValue = val.target.value;
                      if (tempValue) {
                        console.log(tempValue);
                        payload = { status: `${tempValue}` };
                        const respo = await editComplaint(
                          complaintItem?.id,
                          payload,
                          complaintItem?.patientId

                        );
                        if (respo !== -1) {

                          NotificationManager.success(
                            'Status Updated Successfully!',
                            'Success',
                            3000,
                            null,
                            ''
                          );
                          setRefresh(prev => !prev)
                        }

                      }


                    }
                  })();
                },
              },
            };
          })
        );
      }
      setLoading(false);
    })();
  }, [refresh]);

  //   const prepareFormData = (e) => {
  //     const payload = {};
  //     CONSTANTS.RIGHT_SIDEBAR_FIELD.PLAN_MODAL.forEach((field) => {
  //       const tempValue = e.target[field.name].value;
  //       console.log(field.name, tempValue);
  //       if (tempValue) {
  //         payload[field.name] = tempValue;
  //       }
  //     });

  //     return payload;
  //   };

  //   const editOrderHandler = (e) => {
  //     e.preventDefault();
  //     (async () => {
  //       if (orderData?.id) {
  //         const formData = prepareFormData(e);

  //         setLoading(true);
  //         const res = await editOrder(orderData?.id, formData);
  //         if (res !== -1) {
  //           setRefresh((previous) => !previous);
  //           setEditModalOpen(!editModalOpen);
  //         }
  //         setLoading(false);
  //       }
  //     })();
  //   };

  //   const addOrderHandler = (e) => {
  //     e.preventDefault();
  //     (async () => {
  //       const formData = prepareFormData(e);

  //       setLoading(true);
  //       const res = await addOrder(formData);
  //       if (res !== -1) {
  //         setRefresh((previous) => !previous);
  //         setModalOpen(!modalOpen);
  //       }
  //       setLoading(false);
  //     })();
  //   };

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                {/* <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addOrder} />
                  </Button>
                </Container> */}
                {/* <AddNewModalWithOutButton
                  sidebarMenu="PLAN_MODAL"
                  dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.addOrder}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addOrderHandler}
                  formData={{}}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="PLAN_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editOrder}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editOrderHandler}
                  formData={orderData}
                /> */}

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.COMPLAINT_LIST}
                  items={complaintList}
                  advisorId={CONSTANTS.TABLE_ID.complaint}
                  filterParams="patientFullName"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteComplaint}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};

export default Complaint;
