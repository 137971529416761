import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import { deletePatientFeedback, getAllPatientFeedback } from 'utils/API/api';
import AlertPopup from 'components/alert-popup';

const PatientFeedback = () => {
  const { id: patientId } = useParams();
  const [patientFeedbackList, setPatientFeedbackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeletePatientFeedback = (patientFeedbackId) => {
    setDeleteId(patientFeedbackId);
    setIsConfirmationOpen(true);
  };

  const confirmDeletePatientFeedback = async () => {
    if (deleteId) {
      const res = await deletePatientFeedback(deleteId, patientId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
      const res = await getAllPatientFeedback(patientId);
      if (res !== -1) {
        setPatientFeedbackList(
          res?.data?.data.map((patientFeedbackItem, index) => {
            return {
              no: index + 1,
              ...patientFeedbackItem,
              name: `${patientFeedbackItem?.doctor?.firstName} ${patientFeedbackItem?.doctor?.lastName}`,
              deleteData: {
                buttonLabel: 'Delete',
                id: patientFeedbackItem?.id,
                onClick: handleDeletePatientFeedback,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      {!loading ? (
        <>
          <ViewTable
            headers={CONSTANTS.TABLE_HEADER.PATIENT_FEEDBACK_LIST}
            items={patientFeedbackList}
            advisorId={CONSTANTS.TABLE_ID.patientFeedback}
            filterParams="name"
          />

          <AlertPopup
            isOpen={isConfirmationOpen}
            positiveText="Yes"
            negativeText="No"
            warning="Are you sure you want to delete?"
            onNegative={() => {
              setIsConfirmationOpen(false);
              setDeleteId(null);
            }}
            onPositive={confirmDeletePatientFeedback}
          />
        </>
      ) : (
        <>
          <Container className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              className="d-inline-flex m-2 "
              color="$theme-color-yellow-granola"
            />
          </Container>
        </>
      )}
    </Suspense>
  );
};
export default PatientFeedback;
