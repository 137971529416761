/* eslint-disable no-unused-vars */
import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner, Input, Label } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
    addMedicineOrder,
    deleteMedicineOrder,
    editMedicineOrder,
    editOrderTracking,
    getAllMedicine,
    getAllOrder,
    getOneOrder,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
// import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { NotificationManager } from 'components/common/react-notifications';

const PatientOrderDetails = ({ match }) => {
    const [medicineOrderList, setMedicineOrderList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [medicineOrderData, setMedicineOrderData] = useState({});
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const [orderStatus, setOrderStatus] = useState(null)
    const [orderData, setOrderData] = useState({});
    const [tarackOrderData, setTarackOrderData] = useState({})

    const handleDeleteMedicineOrder = (clientId) => {
        setDeleteId(clientId);
        setIsConfirmationOpen(true);
    };
    const { id } = useParams()


    const confirmDeleteMedicineOrder = async () => {
        if (deleteId) {
            const res = await deleteMedicineOrder(deleteId);
            if (res !== -1) {
                setRefresh((previous) => !previous);
                setIsConfirmationOpen(false);
            }
        }
    };

    const handleEditMedicineOrder = (editValue) => {
        if (editValue) {
            setMedicineOrderData({ ...editValue });
            setEditModalOpen(true);
        }
    };

    const MEDICINE_ORDER_OPTIONS = [
        { label: 'Pending', value: 'pending' },
        { label: 'Accepted', value: 'accepted' },
        { label: 'Dispatch', value: 'dispatch' },
        { label: 'Delivered', value: 'delivered' },
    ];


    const prepareFormData = (e) => {
        const payload = {};
        CONSTANTS.RIGHT_SIDEBAR_FIELD.MEDICINE_ORDER_MODAL.forEach((field) => {
            const tempValue = e.target[field.name].value;
            if (tempValue) {
                payload[field.name] = tempValue;
            }
        });
        console.log(e, 'payload');

        return payload;
    };

    const editMedicineOrderHandler = (e) => {
        e.preventDefault();
        (async () => {
            if (medicineOrderData?.id) {
                const formData = prepareFormData(e);

                setLoading(true);
                const res = await editMedicineOrder(medicineOrderData?.id, formData);
                if (res !== -1) {
                    setRefresh((previous) => !previous);
                    setEditModalOpen(!editModalOpen);
                }
                setLoading(false);
            }
        })();
    };

    const addMedicineOrderHandler = (e) => {
        e.preventDefault();
        (async () => {
            const formData = prepareFormData(e);

            setLoading(true);
            const res = await addMedicineOrder(formData);
            if (res !== -1) {
                setRefresh((previous) => !previous);
                setModalOpen(!modalOpen);
            }
            setLoading(false);
        })();
    };
    const handleAddDeliveryData = (editValue) => {
        console.log(editValue);
        if (editValue) {
            setOrderData({ ...editValue });
            setModalOpen((prev) => !prev);
        }
    };
    const editOrderHandler = (e) => {
        e.preventDefault();

        if (e.target.deliveryBoyContactNumber.value.length !== 10) {
            NotificationManager.error(
                'Error message',
                'Mobile no should be valid 10 digits',
                2000,
                () => {
                    alert('callback');
                },
                null,
                null
            );
        } else {
            (async () => {
                console.log(orderData);
                if (orderData?.id) {
                    console.log('inside data', e?.target?.deliveryBoyName?.value);
                    const payload = {
                        status: 'dispatch',
                        deliveryBoyName: e?.target?.deliveryBoyName?.value,
                        deliveryBoyContactNumber: e?.target?.deliveryBoyContactNumber?.value
                    }
                    // const formData = prepareFormData(e);
                    console.log(payload, 'py');
                    // console.log(formData);
                    // formData = { ...formData, status: 'dispatch' }
                    // console.log(formData, 'adfa');

                    setLoading(true);
                    const res = await editOrderTracking(
                        orderData?.id,
                        payload
                    );
                    if (res !== -1) {

                        setModalOpen(!modalOpen);
                        NotificationManager.success(
                            'Success message',
                            'Delivery Boy Data Successfully Added',
                            2000,
                            () => {
                                alert('callback');
                            },
                            null,
                            null
                        );
                        setRefresh((previous) => !previous);
                    }
                    setLoading(false);
                }
            })();
        }
    };
    useEffect(() => {
        (async () => {
            setLoading(true);
            let orderList = [];
            let medicineList = [];
            const resp = await getAllOrder();
            if (resp !== -1) {
                orderList = resp?.data?.data.map((item) => {
                    return {
                        label: `${item?.patient?.firstName} ${item?.patient?.lastName} - ${item?.id}`,
                        value: item?.id,
                    };
                });
            }
            const response = await getAllMedicine();
            if (response !== -1) {
                medicineList = response?.data?.data.map((item) => {
                    return {
                        label: item?.name,
                        value: item?.id,
                    };
                });
            }
            setFormFields(() => {
                const tempValue =
                    CONSTANTS.RIGHT_SIDEBAR_FIELD.MEDICINE_ORDER_MODAL.forEach(
                        (field) => {
                            const newObj = field;
                            if (newObj.name === 'status') {
                                newObj.option = MEDICINE_ORDER_OPTIONS;
                            } else if (newObj.name === 'medicineId') {
                                newObj.option = medicineList;
                            } else if (newObj.name === 'orderId') {
                                newObj.option = orderList;
                            }
                            return newObj;
                        }
                    );
                return tempValue;
            });
            const res = await getOneOrder(id);
            if (res !== -1) {
                setOrderStatus(res?.data?.data?.orderTracking?.status)
                setTarackOrderData(res?.data?.data?.orderTracking)
                setMedicineOrderList(
                    res?.data?.data?.medicineOrders?.map((medicineOrderItem, index) => {
                        return {
                            no: index + 1,
                            ...medicineOrderItem,
                            medicineName: medicineOrderItem?.medicine?.name || '',
                            // deleteData: {
                            //     buttonLabel: 'Delete',
                            //     id: medicineOrderItem?.id,
                            //     onClick: handleDeleteMedicineOrder,
                            // },
                            strength: medicineOrderItem?.strength[0] || '-',
                            // editData: {
                            //     buttonLabel: 'Edit',
                            //     id: {
                            //         ...medicineOrderItem,
                            //         profilePic: '',
                            //     },
                            //     onClick: handleEditMedicineOrder,
                            // },
                        };
                    })
                );
            }
            setLoading(false);
        })();
    }, [refresh]);

    return (
        <Suspense fallback={<div className="loading" />}>
            <Switch>
                <Route
                    path={`${match.url}`}
                    exact
                    render={() =>
                        !loading ? (
                            <>
                                <Container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        maxWidth: '100%',
                                        margin: '0px',
                                        padding: '0',
                                        marginTop: '-10px',
                                        marginBottom: '15px',
                                        marginLeft: '-27px',
                                    }}
                                >
                                    {/* <Button
                                        outline
                                        color="primary"
                                        className="top-right-button"
                                        onClick={() => setModalOpen(true)}
                                    >
                                        <IntlMessages id={CONSTANTS.TABLE_ID.addMedicineOrder} />
                                    </Button> */}
                                    <Label className='mt-2 mr-3 font-weight-bolder'> Order Status</Label>
                                    <Input className='w-25' type="select" onChange={(val) => {
                                        (async () => {
                                            if (tarackOrderData?.id) {
                                                let payload = {};
                                                const tempValue = val.target.value;
                                                if (tempValue) {
                                                    if (tempValue === 'dispatch') {
                                                        payload = { status: `${tempValue}` };
                                                        handleAddDeliveryData(tarackOrderData);
                                                    } else {
                                                        payload = { status: `${tempValue}` };
                                                        const respo = await editOrderTracking(
                                                            tarackOrderData?.id,
                                                            payload
                                                        );
                                                        if (respo !== -1) {
                                                            NotificationManager.success(
                                                                'Status Updated Successfully!',
                                                                'Success',
                                                                3000,
                                                                null,
                                                                ''
                                                            );
                                                        }
                                                    }
                                                }



                                            }
                                        })();
                                    }} defaultValue={orderStatus}>
                                        {MEDICINE_ORDER_OPTIONS?.map((item) => (
                                            <option key={`role_${item?.id}`} value={item?.value}>
                                                {item?.label ? item?.label : item?.value}
                                            </option>
                                        ))}
                                    </Input>
                                </Container>
                                {/* <AddNewModalWithOutButton
                                    sidebarMenu="MEDICINE_ORDER_MODAL"
                                    dataId={CONSTANTS.TABLE_ID.add}
                                    modalTitle={CONSTANTS.TABLE_ID.addMedicineOrder}
                                    modalOpen={modalOpen}
                                    toggleModal={() => setModalOpen(!modalOpen)}
                                    onSubmit={addMedicineOrderHandler}
                                    formData={{}}
                                    formFields={formFields}
                                /> */}

                                {/* <AddNewModalWithOutButton
                                    sidebarMenu="MEDICINE_ORDER_MODAL"
                                    modalTitle={CONSTANTS.TABLE_ID.editMedicineOrder}
                                    modalOpen={editModalOpen}
                                    toggleModal={() => setEditModalOpen(!editModalOpen)}
                                    onSubmit={editMedicineOrderHandler}
                                    formData={medicineOrderData}
                                    formFields={formFields}
                                /> */}
                                <AddNewModalWithOutButton
                                    modalAlign="center"
                                    sidebarMenu="ORDER_FORM_MODAL"
                                    dataId={CONSTANTS.TABLE_ID.add}
                                    modalTitle={CONSTANTS.TABLE_ID.addDeliveryboyInfo}
                                    modalOpen={modalOpen}
                                    toggleModal={() => {
                                        setRefresh(prev => !prev)
                                        setModalOpen(!modalOpen)
                                    }}
                                    onSubmit={editOrderHandler}
                                    formData={{}}
                                />
                                <ViewTable
                                    headers={CONSTANTS.TABLE_HEADER.MEDICINE_ORDER_LIST}
                                    items={medicineOrderList}
                                    advisorId={CONSTANTS.TABLE_ID.medicineOrder}
                                    filterParams="medicineName"
                                />

                                <AlertPopup
                                    isOpen={isConfirmationOpen}
                                    positiveText="Yes"
                                    negativeText="No"
                                    warning="Are you sure you want to delete?"
                                    onNegative={() => {
                                        setIsConfirmationOpen(false);
                                        setDeleteId(null);
                                    }}
                                    onPositive={confirmDeleteMedicineOrder}
                                />
                            </>
                        ) : (
                            <>
                                <Container className="d-flex justify-content-center align-items-center">
                                    <Spinner
                                        animation="border"
                                        className="d-inline-flex m-2 "
                                        color="$theme-color-yellow-granola"
                                    />
                                </Container>
                            </>
                        )
                    }
                />
                <Redirect to="/error" />
            </Switch>

        </Suspense>
    );
};

export default PatientOrderDetails