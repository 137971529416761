/* eslint-disable no-sparse-arrays */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Input } from 'reactstrap';
import SwitchExamples from 'containers/forms/SwitchExamples';
import { Link } from 'react-router-dom';
import Doctor from 'views/app/doctor';
import Appointment from 'views/app/appointment';
import Assesment from 'views/app/assesment';
import Prescription from 'views/app/prescription';
import Medicine from 'views/app/medicine';
import TestReport from 'views/app/test-report';
import FollowUp from 'views/app/follow-up';
import Notification from 'views/app/notification';
import Complaint from 'views/app/complaint';
import DoctorDetail from 'views/app/doctor/doctor-details';
import Patient from 'views/app/patient';
// eslint-disable-next-line prettier/prettier
import PatientDetail from 'views/app/patient/patient-details';
import Plan from 'views/app/plan';
import Order from 'views/app/order';
import Faq from 'views/app/faq';
import MedicineOrder from 'views/app/medicine-order';
import LabTest from 'views/app/lab-test';
import Symptoms from 'views/app/symptoms';
import Speciality from 'views/app/speciality';
import SettingPage from 'views/app/setting';
import Dashboard from 'views/app/dashboard';
import Profile from '../assets/img/blog/small-2.jpg';
import UserMedicineOrder from 'views/app/medicine-order/user-medicine-details';
import PatientOrderDetails from 'views/app/order/patient-order-details';
import Report from 'views/app/report';
import Administration from 'views/app/administration';

export const PROJECT_STATUS = [
  {
    id: 0,
    value: 'Pending',
  },
  {
    id: 1,
    value: 'On Going',
  },
  {
    id: 2,
    value: 'Done',
  },
];

const textRanders = ({ value }) => <>{value}</>;
const RouterLinkRanders = ({ value }) => (
  <Link to={`/app${value.link}`}>{value.value}</Link>
);

const ImageRanders = ({ value }) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = Profile;
  }
  return (
    <div>
      <img className="list-item-pic" src={imageSrc} alt={value} />
    </div>
  );
};

const RenderActionSelect = ({ value }) => {
  const { options, defaultOption, onChange } = value;
  return (
    <div>
      <Input type="select" onChange={onChange} defaultValue={defaultOption}>
        {options?.map((item) => (
          <option key={`role_${item?.id}`} value={item?.value}>
            {item?.label ? item?.label : item?.value}
          </option>
        ))}
      </Input>
    </div>
  );
};

const RenderActionButton = ({ value }) => {
  const { buttonLabel, id, onClick } = value;
  return (
    <div>
      <Button
        outline
        onClick={() => {
          onClick(id);
        }}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

const RenderToggleButton = ({ value }) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <SwitchExamples
        onChange={() => {
          onClick(id, checked);
        }}
        checked={!checked}
      />
    </div>
  );
};

const ViewButtonRanders = ({ value }) => (
  <Link to={`/app${value}`}>
    <Button outline>View</Button>
  </Link>
);
const ViewButton = ({ value }) => {
  const { id, onClick } = value;
  return (
    <div>
      <Button
        onClick={() => {
          onClick(id);
        }}
        outline
      >
        View
      </Button>
    </div>
  );
};
const CONSTANTS = {
  BASE_URL: 'https://backend.mindsmith.co.in/api/v1',
  TABLE_ID: {
    add: 'modal.button',
    plan: 'table.react-table-plan',
    speciality: 'table.react-table-speciality',
    Appointment: 'table.react-table-appointment',
    editAppointment: 'appointment.edit-new-modal-title',
    addPlan: 'plan.add-new-modal-title',
    editPlan: 'plan.edit-new-modal-title',
    editSpeciality: 'speciality.edit-new-modal-title',
    addSpeciality: 'speciality.add-new-modal-title',
    doctor: 'table.react-table-doctor',
    subAdmin: 'table.react-table-subAdmin',
    addDoctor: 'doctor.add-new-modal-title',
    addSubAdmin: 'admin.add-new-modal-title',
    importcsv: 'doctor.import-csv',
    editDoctor: 'doctor.edit-new-modal-title',
    editSubAdmin: 'subAdmin.edit-new-modal-title',
    editChemistData: 'chemist.edit-new-modal-title',
    addChemistData: 'chemist.add-new-modal-title',
    editLLabManagerData: 'Lab.edit-new-modal-title',
    addLabManagerData: 'Lab.add-new-modal-title',

    addDeliveryboyInfo: 'delivery.add-new-modal-title',
    patient: 'table.react-table-patient',
    addPatient: 'patient.add-new-modal-title',
    editPatient: 'patient.edit-new-modal-title',
    medicine: 'table.react-table-medicine',
    addMedicine: 'medicine.add-new-modal-title',
    editMedicine: 'medicine.edit-new-modal-title',
    order: 'table.react-table-order',
    complaint: 'table.react-table-complaint',
    faq: 'table.react-table-faq',
    assesment: 'table.react-table-assesment',
    addAssesment: 'assesment.add-new-modal-title',
    editAssesment: 'assesment.edit-new-modal-title',
    addFaq: 'faq.add-new-modal-title',
    editFaq: 'faq.edit-new-modal-title',
    medicineOrder: 'table.react-table-medicineOrder',
    patientMedicineOrder: 'table.react-table-patientOrder',
    addMedicineOrder: 'medicineOrder.add-new-modal-title',
    editMedicineOrder: 'medicineOrder.edit-new-modal-title',
    feedback: 'table.react-table-feedback',
    addFeedback: 'feedback.add-new-modal-title',
    editFeedback: 'feedback.edit-new-modal-title',
    labTest: 'table.react-table-labTest',
    addLabTest: 'labTest.add-new-modal-title',
    editLabTest: 'labTest.edit-new-modal-title',
    symptoms: 'table.react-table-symptoms',
    addSymptoms: 'symptoms.add-new-modal-title',
    editSymptoms: 'symptoms.edit-new-modal-title',
    doctorCertificate: 'table.react-table-doctorCertificate',
    addDoctorCertificate: 'doctorCertificate.add-new-modal-title',

    doctorAvailability: 'table.react-table-doctorAvailability',
    addDoctorAvailability: 'doctorAvailability.add-new-modal-title',
    editDoctorAvailability: 'doctorAvailability.edit-new-modal-title',
    editDoctorCertificate: 'doctorCertificate.edit-new-modal-title',
    editDoctorUpdatePassword: 'doctorAvailability.edit-update-password',
    editChemistUpdatePassword: 'Chemist.edit-update-password',
    editLabManagerUpdatePassword: 'Lab.edit-update-password',

    doctorPublishedPapers: 'table.react-table-doctorPublishedPapers',
    addDoctorPublishedPapers: 'doctorPublishedPapers.add-new-modal-title',
    editDoctorPublishedPapers: 'doctorPublishedPapers.edit-new-modal-title',
    patientAddress: 'table.react-table-patientAddress',
    addPatientAddress: 'patientAddress.add-new-modal-title',
    editPatientAddress: 'patientAddress.edit-new-modal-title',
    patientFeedback: 'table.react-table-patientFeedback',
    addPatientFeedback: 'patientFeedback.add-new-modal-title',
    editPatientFeedback: 'patientFeedback.edit-new-modal-title',
    patientEmergencyContact: 'table.react-table-patientEmergencyContact',
    addPatientEmergencyContact: 'patientEmergencyContact.add-new-modal-title',
    editPatientEmergencyContact: 'patientEmergencyContact.edit-new-modal-title',
    patientReport: 'table.react-table-patientReport',
    addPatientReport: 'patientReport.add-new-modal-title',
    editPatientReport: 'patientReport.edit-new-modal-title',
    doctorManagementFeedback: 'table.react-table-doctorManagementFeedback',
    doctorVisitedPatientList: 'table.react-table-doctorVisitedPatientList',
    patientVisitedDoctorList: 'table.react-table-patientVisitedDoctorList',
  },

  API: {
    getAllReport: {
      type: 'GET',
      endpoint: '/admin/report',
    },
    getAllReportById: {
      type: 'GET',
      endpoint: '/admin/report/?patientId=:id',
    },
    updatereport: {
      type: 'PATCH',
      endpoint: '/admin/report/:id',
    },
    getOneReport: {
      type: 'GET',
      endpoint: '/admin/report/:id',
    },
    addReport: {
      type: 'POST',
      endpoint: '/admin/report',
    },
    deleteReport: {
      type: 'DELETE',
      endpoint: '/admin/report/:id',
    },
    addDoctor: {
      type: 'POST',
      endpoint: '/admin/doctor/signup',
    },
    visiterPage: {
      type: 'GET',
      endpoint: '/patientVisitor/?doctorId=:id',
    },

    patientVisiterPage: {
      type: 'GET',
      endpoint: '/patientVisitor/?patientId=:id',
    },
    getAllOrders: {
      type: 'GET',
      endpoint: '/admin/order',
    },

    // Admin api
    addRole: {
      type: 'POST',
      endpoint: '/admin/signup',
    },
    addSubAdmin: {
      type: 'POST',
      endpoint: '/admin/add',
    },
    getAllSubAdmin: {
      type: 'GET',
      endpoint: '/admin/getAll?role=subAdmin',
    },

    editSubAdmin: {
      type: 'PATCH',
      endpoint: '/admin/update/:id',
    },
    deleteSubAdmin: {
      type: 'DELETE',
      endpoint: '/admin/delete/:id',
    },
  },
  TABLE_HEADER: {
    PLAN_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Price',
        accessor: 'price',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Hours',
        accessor: 'hours',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],
    SPECIALITY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Pic',
        accessor: 'image',
        cellClass: 'list-item-pic w-10',
        Cell: ImageRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },

      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],
    DOCTOR_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Pic',
        accessor: 'profilePic',
        cellClass: 'list-item-pic w-10',
        Cell: ImageRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading',
        Cell: ViewButtonRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Block',
        accessor: 'blockData',
        cellClass: 'list-item-heading',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: '',
        accessor: 'deleteData',
        cellClass: 'list-item-heading ',
        Cell: RenderActionButton,
      },
    ],
    PATIENT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'view',
        cellClass: 'list-item-heading',
        Cell: ViewButtonRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Block',
        accessor: 'blockData',
        cellClass: 'list-item-heading',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    MEDICINE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Price',
        accessor: 'price',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Discount',
        accessor: 'discount',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Description',
        accessor: 'description',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Strength',
        accessor: 'strength',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    PATIENT_ORDER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: ViewButtonRanders,
      },

      {
        Header: 'Status',
        accessor: 'status',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionSelect,
      },
      {
        Header: 'Total Amount',
        accessor: 'total',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Confirm Date',
        accessor: 'confirmDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Dispatch Date',
        accessor: 'orderDispatchDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Estimate-Delivery Date',
        accessor: 'estimateDeliveryTime',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
    ],
    ORDER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: ViewButtonRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionSelect,
      },
      {
        Header: 'Total Amount',
        accessor: 'total',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Confirm Date',
        accessor: 'confirmDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Dispatch Date',
        accessor: 'orderDispatchDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Estimate-Delivery Date',
        accessor: 'estimateDeliveryTime',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      // {
      //   Header: 'Action',
      //   accessor: 'deleteData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
    ],

    COMPLAINT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Patient Name',
        accessor: 'patientName',
        cellClass: 'list-item-heading w-5',
        Cell: RouterLinkRanders,
      },
      {
        Header: 'Doctor Name',
        accessor: 'doctorName',
        cellClass: 'list-item-heading w-5',
        Cell: RouterLinkRanders,
      },
      {
        Header: 'Complain',
        accessor: 'complain',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Title',
        accessor: 'title',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionSelect,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Resolve Date',
        accessor: 'rdate',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      // {
      //   Header: 'Action',
      //   accessor: 'deleteData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
    ],

    APPOINTMENT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'patientName',
        accessor: 'patientName',
        cellClass: 'list-item-heading',
        Cell: RouterLinkRanders,
      },
      {
        Header: 'doctorName',
        accessor: 'doctorName',
        cellClass: 'list-item-heading',
        Cell: RouterLinkRanders,
      },
      {
        Header: 'Date',
        accessor: 'dateD',
        cellClass: 'list-item-heading w-90',
        Cell: textRanders,
      },
      {
        Header: 'SlotStartTime',
        accessor: 'slotStartTimeD',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'SlotEndTime',
        accessor: 'slotEndTimeD',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'UserVisited',
        accessor: 'isUserVisited',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'DoctorVisited',
        accessor: 'isDoctorVisited',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Reschedule',
        accessor: 'isReschedule',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationFees',
        accessor: 'cancellationFees',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationFeesInPercentage',
        accessor: 'cancellationFeesInPercentage',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationDateAndTime',
        accessor: 'cancellationDateAndTime',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationDuration',
        accessor: 'cancellationDuration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Canceled',
        accessor: 'isCanceled',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      // {
      //   Header: 'Action',
      //   accessor: 'deleteData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    FAQ_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: ViewButton,
      },
      {
        Header: 'Question',
        accessor: 'questionD',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },

      {
        Header: 'Answer',
        accessor: 'answerD',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    ASSESMENT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Specialization',
        accessor: 'specialization',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Price',
        accessor: 'price',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],
    MEDICINE_ORDER_USER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading',
        Cell: ViewButtonRanders,
      },
      {
        Header: 'Patient Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Mobile No.',
        accessor: 'mobile',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Total Amount (₹)',
        accessor: 'total',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Order Status',
        accessor: 'status',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
    ],
    MEDICINE_ORDER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Medicine',
        accessor: 'medicineName',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Price (₹)',
        accessor: 'price',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Discount (%)',
        accessor: 'discount',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Strength',
        accessor: 'strength',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      // {
      //   Header: 'Action',
      //   accessor: 'deleteData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
      // {
      //   Header: 'Action',
      //   accessor: 'editData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
    ],

    REPORT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Patient Name',
        accessor: 'patientName',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Lab Test Name',
        accessor: 'labTestName',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Charges (₹)',
        accessor: 'charges',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Report',
        accessor: 'fileView',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],
    LAB_REPORT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Patient Name',
        accessor: 'patientName',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Lab Test Name',
        accessor: 'labTestName',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Charges (₹)',
        accessor: 'charges',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Report',
        accessor: 'fileView',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      // {
      //   Header: 'Action',
      //   accessor: 'deleteData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
      // {
      //   Header: 'Action',
      //   accessor: 'editData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
    ],

    LAB_TEST_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Charge',
        accessor: 'charges',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    SYMPTOMS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Description',
        accessor: 'description',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    DOCTOR_AVAILABILITY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Day',
        accessor: 'day',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Start Time',
        accessor: 'startTime2',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'End Time',
        accessor: 'endTime2',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    DOCTOR_AWARD_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },

      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    DOCTOR_PUBLISHED_PAPERS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Link',
        accessor: 'link',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    PATIENT_ADDRESS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Address 1',
        accessor: 'addressLine1',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Address 2',
        accessor: 'addressLine2',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Pin Code',
        accessor: 'pinCode',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'City',
        accessor: 'city',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'State',
        accessor: 'state',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Country',
        accessor: 'country',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    PATIENT_EMERGENCY_CONTACT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Relation',
        accessor: 'relation',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],
    PATIENT_REPORT: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Lab Test Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Charges (₹)',
        accessor: 'charges',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Report',
        accessor: 'fileView',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      // {
      //   Header: 'Email',
      //   accessor: 'email',
      //   cellClass: 'list-item-heading',
      //   Cell: textRanders,
      // },
      {
        Header: 'Edit',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: 'Delete',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    PATIENT_FEEDBACK_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Feedback',
        accessor: 'feedback',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Rating',
        accessor: 'rating',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],

    DOCTOR_MANAGEMENT_FEEDBACK_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Feedback',
        accessor: 'feedback',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Rating',
        accessor: 'rating',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'deleteData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
    ],
    PATIENT_VISITED_DOCTOR_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Diagnosis',
        accessor: 'diagnosis',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-90',
        Cell: textRanders,
      },
      {
        Header: 'SlotStartTime',
        accessor: 'slotStartTime',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'SlotEndTime',
        accessor: 'slotEndTime',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'UserVisited',
        accessor: 'isUserVisited',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'DoctorVisited',
        accessor: 'isDoctorVisited',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Reschedule',
        accessor: 'isReschedule',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'DoctorNote',
        accessor: 'doctorNote',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'PatientNote',
        accessor: 'patientNote',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationFees',
        accessor: 'cancellationFees',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationFeesInPercentage',
        accessor: 'cancellationFeesInPercentage',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationDateAndTime',
        accessor: 'cancellationDateAndTime',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationDuration',
        accessor: 'cancellationDuration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'FakeBooking',
        accessor: 'isFakeBooking',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'NextFollowUpDays',
        accessor: 'nextFollowUpDays',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'PatientRecommandation',
        accessor: 'patientRecommandation',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Canceled',
        accessor: 'isCanceled',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
    ],
    DOCTOR_VISITED_PATIENT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Diagnosis',
        accessor: 'diagnosis',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-90',
        Cell: textRanders,
      },
      {
        Header: 'SlotStartTime',
        accessor: 'slotStartTime',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'SlotEndTime',
        accessor: 'slotEndTime',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'UserVisited',
        accessor: 'isUserVisited',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'DoctorVisited',
        accessor: 'isDoctorVisited',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Reschedule',
        accessor: 'isReschedule',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'DoctorNote',
        accessor: 'doctorNote',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'PatientNote',
        accessor: 'patientNote',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationFees',
        accessor: 'cancellationFees',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationFeesInPercentage',
        accessor: 'cancellationFeesInPercentage',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationDateAndTime',
        accessor: 'cancellationDateAndTime',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'CancellationDuration',
        accessor: 'cancellationDuration',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'FakeBooking',
        accessor: 'isFakeBooking',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'NextFollowUpDays',
        accessor: 'nextFollowUpDays',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'PatientRecommandation',
        accessor: 'patientRecommandation',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Canceled',
        accessor: 'isCanceled',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },

      // {
      //   Header: 'Rating',
      //   accessor: 'rating',
      //   cellClass: 'list-item-heading',
      //   Cell: textRanders,
      // },
      // {
      //   Header: 'Action',
      //   accessor: 'deleteData',
      //   cellClass: 'list-item-heading',
      //   Cell: RenderActionButton,
      // },
    ],
    ADMINISTRATION_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellClass: 'list-item-heading',
        Cell: textRanders,
      },

      {
        Header: 'Action',
        accessor: 'editData',
        cellClass: 'list-item-heading',
        Cell: RenderActionButton,
      },
      {
        Header: '',
        accessor: 'deleteData',
        cellClass: 'list-item-heading ',
        Cell: RenderActionButton,
      },
    ],
  },

  PERSONAL_NOTIFICATION: [
    {
      no: 1,
      label: 'doctorlist',
      tagId: 'forms.selectDoctor',
      id: 'doctorlist',
      type: 'select',
      name: 'doctorlist',
      messages: 'doctorlist',
      apiKey: 'doctorlist',
    },
    {
      no: 2,
      label: 'title',
      tagId: 'forms.title',
      id: 'title',
      type: 'text',
      name: 'title',
      placeholder: 'Title',
      messages: 'title',
      apiKey: 'title',
    },
    {
      no: 3,
      label: 'Notification Message',
      tagId: 'forms.notificationMessage',
      type: 'text',
      name: 'notificationMessage',
      placeholder: '',
      messages: 'Notification Message',
      apiKey: 'notificationMessage',
    },
  ],
  PERSONAL_PATIENT_NOTIFICATION: [
    {
      no: 1,
      label: 'patientlist',
      tagId: 'forms.selectPatient',
      id: 'patientlist',
      type: 'select',
      name: 'patientlist',
      messages: 'patientlist',
      apiKey: 'patientlist',
    },
    {
      no: 2,
      label: 'title',
      tagId: 'forms.title',
      id: 'title',
      type: 'text',
      name: 'title',
      placeholder: 'Title',
      messages: 'title',
      apiKey: 'title',
    },
    {
      no: 3,
      label: 'Notification Message',
      tagId: 'forms.notificationMessage',
      type: 'text',
      name: 'notificationMessage',
      placeholder: '',
      messages: 'Notification Message',
      apiKey: 'notificationMessage',
    },
  ],
  PERSONAL_GROUP_NOTIFICATION: [
    {
      no: 1,
      label: 'grouplist',
      tagId: 'forms.selectGroup',
      id: 'grouplist',
      type: 'select',
      name: 'grouplist',
      messages: 'grouplist',
      apiKey: 'grouplist',
      required: true,
    },
    {
      no: 1,
      label: 'title',
      tagId: 'forms.title',
      id: 'title',
      type: 'text',
      name: 'title',
      placeholder: 'Title',
      messages: 'title',
      apiKey: 'title',
    },
    {
      no: 2,
      label: 'Notification Message',
      tagId: 'forms.notificationMessage',
      type: 'text',
      name: 'notificationMessage',
      placeholder: 'Message',
      messages: 'Notification Message',
      apiKey: 'notificationMessage',
    },
  ],
  RIGHT_SIDEBAR_FIELD: {
    PLAN_MODAL: [
      {
        no: 0,
        Label: 'plan.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'plan.price',
        name: 'price',
        id: 'price',
        type: 'number',
        required: true,
      },
      {
        no: 3,
        Label: 'plan.hours',
        name: 'hours',
        id: 'hours',
        type: 'number',
        required: true,
      },
      {
        no: 4,
        Label: 'plan.duration',
        name: 'duration',
        id: 'duration',
        type: 'number',
        required: true,
      },
    ],
    SPECIALITY_MODAL: [
      {
        no: 0,
        Label: 'speciality.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 0,
        Label: 'speciality.profilePic',
        name: 'image',
        id: 'image',
        type: 'file',
        required: false,
      },
    ],
    DOCTOR_CSV_MODAL: [
      {
        no: 0,
        Label: 'doctor.csv',
        name: 'csvDoctor',
        id: 'csvDoctor',
        type: 'file',
        required: true,
      },
    ],
    DOCTOR_MODAL: [
      {
        no: 0,
        Label: 'doctor.firstName',
        name: 'firstName',
        id: 'firstName',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'doctor.lastName',
        name: 'lastName',
        id: 'lastName',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'doctor.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      {
        no: 3,
        Label: 'doctor.password',
        name: 'password',
        id: 'password',
        type: 'password',
        required: true,
      },
    ],
    DOCTOR_EDIT_FIRST_MODAL: [
      {
        no: 0,
        Label: 'doctor.firstName',
        name: 'firstName',
        id: 'firstName',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'doctor.lastName',
        name: 'lastName',
        id: 'lastName',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'doctor.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      {
        no: 3,
        Label: 'doctor.password',
        name: 'password',
        id: 'password',
        required: false,
        type: 'password',
      },
    ],
    DOCTOR_EDIT_PIC: [
      {
        no: 0,
        Label: 'doctor.profilePic',
        name: 'profilePic',
        id: 'profilePic',
        type: 'file',
        required: true,
      },
    ],
    CEMISTDATA_MODAL: [
      {
        no: 0,
        Label: 'setting.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },

      {
        no: 1,
        Label: 'setting.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      {
        no: 2,
        Label: 'setting.password',
        name: 'password',
        id: 'password',
        type: 'password',
        required: true,
      },
    ],
    CEMISTDATA_EDIT_MODAL: [
      {
        no: 2,
        Label: 'setting.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },

      {
        no: 4,
        Label: 'setting.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      // {
      //   no: 4,
      //   Label: 'setting.password',
      //   name: 'email',
      //   id: 'email',
      //   type: 'password',
      //   required: true,
      // },
    ],
    DOCTOR_EDIT_MODAL: [
      {
        no: 16,
        Label: 'doctor.prefix',
        name: 'prefix',
        id: 'prefix',
        type: 'select',
        option: [
          { id: 0, value: 'Mr' },
          { id: 1, value: 'Mrs' },
          { id: 2, value: 'Ms' },
          { id: 3, value: 'Dr' },
        ],
        required: false,
      },
      {
        no: 2,
        Label: 'doctor.firstName',
        name: 'firstName',
        id: 'firstName',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'doctor.lastName',
        name: 'lastName',
        id: 'lastName',
        type: 'text',
        required: false,
      },
      {
        no: 4,
        Label: 'doctor.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: false,
      },
      {
        no: 6,
        Label: 'doctor.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: false,
      },
      {
        no: 15,
        Label: 'doctor.gender',
        name: 'gender',
        id: 'gender',
        type: 'select',
        option: [
          {
            id: 0,
            value: 'M',
            label: 'Male',
          },
          {
            id: 1,
            value: 'F',
            label: 'Female',
          },
          {
            id: 2,
            value: 'O',
            label: 'Other',
          },
        ],
        required: false,
      },

      {
        no: 8,
        Label: 'doctor.isVerified',
        name: 'isVerified',
        id: 'isVerified',
        type: 'checkbox',
        required: false,
      },
      {
        no: 1,
        Label: 'doctor.aadharCard',
        name: 'aadharCard',
        id: 'aadharCard',
        type: 'file',
        required: false,
      },
      {
        no: 17,
        Label: 'doctor.category',
        name: 'category',
        id: 'category',
        type: 'select',
        option: [
          { id: 0, value: 'therapist', label: 'Therapist' },
          { id: 1, value: 'doctor', label: 'Doctor' },
        ],
        required: false,
      },
      // {
      //   no: 17,
      //   Label: 'doctor.language',
      //   name: 'language',
      //   id: 'language',
      //   type: 'select',

      // option: [
      //   { id: 0, value: 'English', label: 'English' },
      //   { id: 1, value: 'Gujarati', label: 'Gujarati' },
      //   { id: 2, value: 'Hindi', label: 'Hindi' },
      //   { id: 3, value: 'Bengali', label: 'Bengali' },
      //   { id: 4, value: 'Tamil', label: 'Tamil' },
      //   { id: 5, value: 'Telugu', label: 'Telugu' },
      //   { id: 6, value: 'Marathi', label: 'Marathi' },
      //   { id: 7, value: 'Urdu', label: 'Urdu' },
      //   { id: 8, value: 'Kannada', label: 'Kannada' },
      //   { id: 9, value: 'Malayalam', label: 'Malayalam' },
      //   { id: 10, value: 'Odia', label: 'Odia' },
      //   { id: 11, value: 'Punjabi', label: 'Punjabi' },
      //   { id: 12, value: 'Sanskrit', label: 'Sanskrit' },
      //   { id: 13, value: 'Assamese', label: 'Assamese' },
      //   { id: 14, value: 'Sindhi', label: 'Sindhi' },
      //   { id: 15, value: 'Nepali', label: 'Nepali' },
      // ],
      //   required: false,
      // },

      {
        no: 9,
        Label: 'doctor.bachelorEducation',
        name: 'bachelorEducation',
        id: 'bachelorEducation',
        type: 'text',
        required: false,
      },
      {
        no: 10,
        Label: 'doctor.masterEducation',
        name: 'masterEducation',
        id: 'masterEducation',
        type: 'text',
        required: false,
      },
      {
        no: 11,
        Label: 'doctor.phdEducation',
        name: 'phdEducation',
        id: 'phdEducation',
        type: 'text',
        required: false,
      },
      {
        no: 12,
        Label: 'doctor.doctorRegistrationNumber',
        name: 'doctorRegistrationNumber',
        id: 'doctorRegistrationNumber',
        type: 'text',
        required: false,
      },
      {
        no: 13,
        Label: 'doctor.experience',
        name: 'experience',
        id: 'experience',
        type: 'number',
        required: false,
      },
      {
        no: 14,
        Label: 'doctor.personalDetail',
        name: 'personalDetail',
        id: 'personalDetail',
        type: 'text',
        required: false,
      },

      {
        no: 18,
        Label: 'doctor.sessionChargePerFiftyMinute',
        name: 'sessionChargePerFiftyMinute',
        id: 'sessionChargePerFiftyMinute',
        type: 'number',
        required: false,
      },
      {
        no: 19,
        Label: 'doctor.speciality',
        name: 'speciality',
        id: 'speciality',
        type: 'select',
        required: false,
      },
      {
        no: 20,
        Label: 'doctor.addressLine1',
        name: 'addressLine1',
        id: 'addressLine1',
        type: 'textarea',
        height: '70px',
        required: false,
      },
      {
        no: 21,
        Label: 'doctor.addressLine2',
        name: 'addressLine2',
        id: 'addressLine2',
        type: 'textarea',
        height: '70px',
        required: false,
      },
      {
        no: 22,
        Label: 'doctor.country',
        name: 'country',
        id: 'country',
        type: 'text',
        required: false,
      },
      {
        no: 23,
        Label: 'doctor.state',
        name: 'state',
        id: 'state',
        type: 'text',
        required: false,
      },
      {
        no: 24,
        Label: 'doctor.city',
        name: 'city',
        id: 'city',
        type: 'text',
        required: false,
      },
      {
        no: 25,
        Label: 'doctor.pinCode',
        name: 'pinCode',
        id: 'pinCode',
        type: 'number',
        required: false,
      },
    ],
    ORDER_FORM_MODAL: [
      {
        no: 2,
        Label: 'DeliveryBoy Name',
        name: 'deliveryBoyName',
        id: 'deliveryBoyName',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'Contact Number',
        name: 'deliveryBoyContactNumber',
        id: 'deliveryBoyContactNumber',
        type: 'number',
        required: true,
      },
    ],

    PATIENT_MODAL: [
      {
        no: 2,
        Label: 'patient.firstName',
        name: 'firstName',
        id: 'firstName',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'patient.lastName',
        name: 'lastName',
        id: 'lastName',
        type: 'text',
        required: true,
      },
      {
        no: 4,
        Label: 'patient.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      {
        no: 3,
        Label: 'doctor.password',
        name: 'password',
        id: 'password',
        required: true,
        type: 'password',
      },
      {
        no: 6,
        Label: 'patient.countryCode',
        name: 'countryCode',
        id: 'countryCode',
        type: 'number',
        required: true,
      },
      {
        no: 7,
        Label: 'patient.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: false,
      },

      {
        no: 10,
        Label: 'patient.loginWith',
        name: 'loginWith',
        id: 'loginWith',
        type: 'select',
        option: [
          { id: 0, value: 'google', label: 'Google' },
          { id: 1, value: 'apple', label: 'Apple' },
        ],
        required: false,
      },
      {
        no: 15,
        Label: 'patient.gender',
        name: 'gender',
        id: 'gender',
        type: 'select',
        option: [
          {
            id: 0,
            value: 'male',
            label: 'Male',
          },
          {
            id: 1,
            value: 'female',
            label: 'Female',
          },
          {
            id: 2,
            value: 'other',
            label: 'Other',
          },
        ],
        required: false,
      },
      {
        no: 17,
        Label: 'patient.governmentType',
        name: 'governmentType',
        id: 'governmentType',
        type: 'select',
        option: [{ id: 0, value: 'Aadhar Card' }],
        required: false,
      },
      {
        no: 1,
        Label: 'patient.governmentId',
        name: 'governmentId',
        id: 'governmentId',
        type: 'file',
        required: false,
      },
    ],
    EDIT_PATIENT_MODAL: [
      {
        no: 2,
        Label: 'patient.firstName',
        name: 'firstName',
        id: 'firstName',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'patient.lastName',
        name: 'lastName',
        id: 'lastName',
        type: 'text',
        required: true,
      },
      {
        no: 4,
        Label: 'patient.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },

      {
        no: 6,
        Label: 'patient.countryCode',
        name: 'countryCode',
        id: 'countryCode',
        type: 'number',
        required: true,
      },
      {
        no: 7,
        Label: 'patient.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: false,
      },

      {
        no: 10,
        Label: 'patient.loginWith',
        name: 'loginWith',
        id: 'loginWith',
        type: 'select',
        option: [
          { id: 0, value: 'google', label: 'Google' },
          { id: 1, value: 'apple', label: 'Apple' },
        ],
        required: false,
      },
      {
        no: 15,
        Label: 'patient.gender',
        name: 'gender',
        id: 'gender',
        type: 'select',
        option: [
          {
            id: 0,
            value: 'male',
            label: 'Male',
          },
          {
            id: 1,
            value: 'female',
            label: 'Female',
          },
          {
            id: 2,
            value: 'other',
            label: 'Other',
          },
        ],
        required: false,
      },
      {
        no: 17,
        Label: 'patient.governmentType',
        name: 'governmentType',
        id: 'governmentType',
        type: 'select',
        option: [{ id: 0, value: 'Aadhar Card' }],
        required: false,
      },
      {
        no: 1,
        Label: 'patient.governmentId',
        name: 'governmentId',
        id: 'governmentId',
        type: 'file',
        required: false,
      },
    ],
    PATIENT_EDIT_MODAL: [
      {
        no: 2,
        Label: 'patient.firstName',
        name: 'firstName',
        id: 'firstName',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'patient.lastName',
        name: 'lastName',
        id: 'lastName',
        type: 'text',
        required: false,
      },
      {
        no: 4,
        Label: 'patient.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: false,
      },

      {
        no: 6,
        Label: 'patient.countryCode',
        name: 'countryCode',
        id: 'countryCode',
        type: 'number',
        required: true,
      },
      {
        no: 7,
        Label: 'patient.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',

        required: false,
      },

      {
        no: 15,
        Label: 'patient.gender',
        name: 'gender',
        id: 'gender',
        type: 'select',
        option: [
          {
            id: 0,
            value: 'male',
            label: 'Male',
          },
          {
            id: 1,
            value: 'female',
            label: 'Female',
          },
          {
            id: 2,
            value: 'other',
            label: 'Other',
          },
        ],
        required: false,
      },
      {
        no: 17,
        Label: 'patient.governmentType',
        name: 'governmentType',
        id: 'governmentType',
        type: 'select',
        option: [{ id: 0, value: 'Aadhar Card' }],
        required: false,
      },
      {
        no: 1,
        Label: 'patient.governmentId',
        name: 'governmentId',
        id: 'governmentId',
        type: 'file',
        required: false,
      },
    ],

    MEDICINE_MODAL: [
      {
        no: 0,
        Label: 'medicine.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'medicine.price',
        name: 'price',
        id: 'price',
        type: 'number',
        required: true,
      },
      {
        no: 3,
        Label: 'medicine.description',
        name: 'description',
        id: 'description',
        type: 'text',
        required: true,
      },
      {
        no: 4,
        Label: 'medicine.discount',
        name: 'discount',
        id: 'discount',
        type: 'number',
        required: true,
      },
      {
        no: 5,
        Label: 'medicine.strength',
        name: 'strength',
        id: 'strength',
        type: 'string',
        required: true,
      },
    ],

    FAQ_MODAL: [
      {
        no: 0,
        Label: 'faq.type',
        name: 'type',
        id: 'type',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'faq.question',
        name: 'question',
        id: 'question',
        type: 'textarea',
        height: '130px',
        required: true,
      },
      {
        no: 3,
        Label: 'faq.answer',
        name: 'answer',
        id: 'answer',
        type: 'textarea',
        height: '130px',
        required: true,
      },
      {
        no: 4,
        Label: 'faq.isActive',
        name: 'isActive',
        id: 'isActive',
        type: 'checkbox',
        required: false,
      },
    ],

    MEDICINE_ORDER_MODAL: [
      {
        no: 0,
        Label: 'medicineOrder.medicine',
        name: 'medicineId',
        id: 'medicineId',
        type: 'select',
        required: true,
      },
      {
        no: 2,
        Label: 'medicineOrder.orderId',
        name: 'orderId',
        id: 'orderId',
        type: 'select',
        required: true,
      },
      {
        no: 3,
        Label: 'medicineOrder.price',
        name: 'price',
        id: 'price',
        type: 'number',
        required: true,
      },
      {
        no: 4,
        Label: 'medicineOrder.discount',
        name: 'discount',
        id: 'discount',
        type: 'number',
        required: true,
      },
      {
        no: 5,
        Label: 'medicineOrder.status',
        name: 'status',
        id: 'status',
        type: 'select',
        required: true,
      },
      {
        no: 6,
        Label: 'medicineOrder.quantity',
        name: 'quantity',
        id: 'quantity',
        type: 'text',
        required: true,
      },
    ],

    REPORT_MODAL: [
      {
        no: 0,
        Label: 'feedback.labTestId',
        name: 'labTestId',
        id: 'labTestId',
        type: 'select',
        required: true,
      },

      {
        no: 1,
        Label: 'feedback.patientId',
        name: 'patientId',
        id: 'patientId',
        type: 'select',
        required: true,
      },
      {
        no: 2,
        Label: 'feedback.report',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'feedback.files',
        name: 'files',
        id: 'files',
        type: 'file',
        required: true,
      },
    ],
    EDIT_REPORT_MODAL: [
      {
        no: 0,
        Label: 'feedback.labTestId',
        name: 'labTestId',
        id: 'labTestId',
        type: 'select',
        required: true,
      },

      {
        no: 1,
        Label: 'feedback.patientId',
        name: 'patientId',
        id: 'patientId',
        type: 'select',
        required: true,
      },
      {
        no: 2,
        Label: 'feedback.report',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'feedback.files',
        name: 'files',
        id: 'files',
        type: 'file',
        required: false,
      },
    ],

    LAB_TEST_MODAL: [
      {
        no: 1,
        Label: 'labTest.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'labTest.charge',
        name: 'charges',
        id: 'charges',
        type: 'number',
        required: true,
      },
    ],
    ASSESMENT_MODAL: [
      {
        no: 1,
        Label: 'assesment.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'assesment.specialization',
        name: 'specialization',
        id: 'specialization',
        type: 'select',

        required: true,
      },
      {
        no: 3,
        Label: 'assesment.price',
        name: 'price',
        id: 'price',
        type: 'number',
        required: true,
      },
    ],

    SYMPTOMS_MODAL: [
      {
        no: 1,
        Label: 'symptoms.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'symptoms.description',
        name: 'description',
        id: 'description',
        type: 'textarea',
        height: '90px',
        required: true,
      },
    ],
    DOCTOR_CERTIFICATE_MODAL: [
      {
        no: 1,
        Label: 'doctorCertificate.day',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
    ],
    DOCTOR_AVAILABILITY_MODAL: [
      {
        no: 1,
        Label: 'doctorAvailability.day',
        name: 'day',
        id: 'day',
        type: 'select',
        option: [
          { id: 0, value: 'Monday' },
          { id: 1, value: 'Tuesday' },
          { id: 2, value: 'Wednesday' },
          { id: 3, value: 'Thursday' },
          { id: 4, value: 'Friday' },
          { id: 5, value: 'Saturday' },
          { id: 6, value: 'Sunday' },
        ],
        required: true,
      },
      {
        no: 2,
        Label: 'doctorAvailability.startTime',
        name: 'startTime',
        id: 'startTime',
        type: 'time',
        required: true,
      },
      {
        no: 3,
        Label: 'doctorAvailability.endTime',
        name: 'endTime',
        id: 'endTime',
        type: 'time',
        required: true,
      },
    ],

    DOCTOR_EDIT_AVAILABILITY_MODAL: [
      {
        no: 1,
        Label: 'doctorAvailability.startTime',
        name: 'startTime',
        id: 'startTime',
        type: 'time',
        required: true,
      },
      {
        no: 2,
        Label: 'doctorAvailability.endTime',
        name: 'endTime',
        id: 'endTime',
        type: 'time',
        required: true,
      },
    ],

    DOCTOR_EDIT_PASSWORD_MODAL: [
      {
        no: 1,
        Label: 'doctor.password',
        name: 'password',
        id: 'password',
        required: true,
        type: 'password',
      },
    ],

    DOCTOR_PUBLISHED_PAPERS_MODAL: [
      {
        no: 1,
        Label: 'doctorPublishedPapers.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'doctorPublishedPapers.link',
        name: 'link',
        id: 'link',
        type: 'url',
        required: true,
      },
      {
        no: 3,
        Label: 'doctorPublishedPapers.file',
        name: 'file',
        id: 'file',
        type: 'file',
        required: true,
      },
    ],
    EDIT_DOCTOR_PUBLISHED_PAPERS_MODAL: [
      {
        no: 1,
        Label: 'doctorPublishedPapers.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'doctorPublishedPapers.link',
        name: 'link',
        id: 'link',
        type: 'url',
        required: true,
      },
      {
        no: 3,
        Label: 'doctorPublishedPapers.file',
        name: 'file',
        id: 'file',
        type: 'file',
        required: false,
      },
    ],

    PATIENT_ADDRESS_MODAL: [
      {
        no: 5,
        Label: 'patientAddress.addressLine1',
        name: 'addressLine1',
        id: 'addressLine1',
        type: 'textarea',
        height: '70px',
        required: true,
      },
      {
        no: 4,
        Label: 'patientAddress.addressLine2',
        name: 'addressLine2',
        id: 'addressLine2',
        type: 'textarea',
        height: '70px',
        required: true,
      },
      {
        no: 0,
        Label: 'patientAddress.pinCode',
        name: 'pinCode',
        id: 'pinCode',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'patientAddress.city',
        name: 'city',
        id: 'city',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'patientAddress.state',
        name: 'state',
        id: 'state',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'patientAddress.country',
        name: 'country',
        id: 'country',
        type: 'text',
        required: true,
      },
    ],

    PATIENT_EMERGENCY_CONTACT_MODAL: [
      {
        no: 0,
        Label: 'patientEmergencyContact.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'patientEmergencyContact.relation',
        name: 'relation',
        id: 'relation',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'patientEmergencyContact.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'patientEmergencyContact.email',
        name: 'email',
        id: 'email',
        type: 'text',
        required: true,
      },
    ],

    PATIENT_REPORT_MODAL: [
      {
        no: 0,
        Label: 'feedback.labTestId',
        name: 'labTestId',
        id: 'labTestId',
        type: 'select',
        required: true,
      },
      {
        no: 1,
        Label: 'feedback.report',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },

      {
        no: 2,
        Label: 'patientReport.report',
        name: 'files',
        id: 'files',
        type: 'file',
        required: true,
      },
    ],
    EDIT_PATIENT_REPORT_MODAL: [
      {
        no: 0,
        Label: 'feedback.labTestId',
        name: 'labTestId',
        id: 'labTestId',
        type: 'select',
        required: true,
      },
      {
        no: 1,
        Label: 'feedback.report',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },

      {
        no: 2,
        Label: 'patientReport.report',
        name: 'files',
        id: 'files',
        type: 'file',
        required: false,
      },
    ],
    APPOINTMENT_MODAL: [
      {
        no: 0,
        Label: 'appointment.slot-start-time',
        name: 'slotStartTime',
        id: 'slotStartTime',
        type: 'time',
        required: true,
      },
      {
        no: 2,
        Label: 'appointment.slot-end-time',
        name: 'slotEndTime',
        id: 'slotEndTime',
        type: 'time',
        required: true,
      },
      {
        no: 3,
        Label: 'appointment.date',
        name: 'date',
        id: 'date',
        type: 'date',
        required: true,
      },
      {
        no: 4,
        Label: 'appointment.isUserVisited',
        name: 'isUserVisited',
        id: 'isUserVisited',
        type: 'text',
        required: true,
      },
      {
        no: 5,
        Label: 'appointment.isDoctorVisited',
        name: 'isDoctorVisited',
        id: 'isDoctorVisited',
        type: 'text',
        required: true,
      },
      {
        no: 6,
        Label: 'appointment.isReschedule',
        name: 'isReschedule',
        id: 'isReschedule',
        type: 'text',
        required: true,
      },
      {
        no: 7,
        Label: 'appointment.isFakeBooking',
        name: 'isFakeBooking',
        id: 'isFakeBooking',
        type: 'text',
        required: true,
      },
      {
        no: 8,
        Label: 'appointment.nextFollowUpDays',
        name: 'followupDaysDateAndTime',
        id: 'followupDaysDateAndTime',
        type: 'date',
        required: true,
      },
    ],
    DOCTOR_EDIT_VISIT_PAGE: [
      {
        no: 0,
        Label: 'docVisitPage.reason',
        name: 'reason',
        id: 'reason',
        type: 'text',
        required: false,
      },
      {
        no: 1,
        Label: 'docVisitPage.diagnosis',
        name: 'diagnosis',
        id: 'diagnosis',
        type: 'text',
        required: false,
      },
      {
        no: 2,
        Label: 'docVisitPage.slot-start-time',
        name: 'slotStartTime',
        id: 'slotStartTime',
        type: 'time',
        required: false,
      },
      {
        no: 3,
        Label: 'docVisitPage.slot-end-time',
        name: 'slotEndTime',
        id: 'slotEndTime',
        type: 'time',
        required: false,
      },
      {
        no: 4,
        Label: 'docVisitPage.date',
        name: 'date',
        id: 'date',
        type: 'date',
        required: false,
      },
      {
        no: 5,
        Label: 'docVisitPage.isUserVisited',
        name: 'isUserVisited',
        id: 'isUserVisited',
        type: 'text',
        required: false,
      },
      {
        no: 6,
        Label: 'docVisitPage.isDoctorVisited',
        name: 'isDoctorVisited',
        id: 'isDoctorVisited',
        type: 'text',
        required: false,
      },
      {
        no: 7,
        Label: 'docVisitPage.isReschedule',
        name: 'isReschedule',
        id: 'isReschedule',
        type: 'text',
        required: false,
      },
      {
        no: 8,
        Label: 'docVisitPage.isFakeBooking',
        name: 'isFakeBooking',
        id: 'isFakeBooking',
        type: 'text',
        required: false,
      },
      {
        no: 9,
        Label: 'docVisitPage.nextFollowUpDays',
        name: 'nextFollowUpDays',
        id: 'nextFollowUpDays',
        type: 'text',
        required: false,
      },
      {
        no: 10,
        Label: 'docVisitPage.duration',
        name: 'duration',
        id: 'duration',
        type: 'text',
        required: false,
      },
      {
        no: 12,
        Label: 'docVisitPage.doctorNote',
        name: 'doctorNote',
        id: 'doctorNote',
        type: 'text',
        required: false,
      },
      {
        no: 13,
        Label: 'docVisitPage.patientNote',
        name: 'patientNote',
        id: 'patientNote',
        type: 'text',
        required: false,
      },
      {
        no: 14,
        Label: 'docVisitPage.cancellationFees',
        name: 'cancellationFees',
        id: 'cancellationFees',
        type: 'text',
        required: false,
      },
    ],
    ADMINISTRATION_MODAL: [
      {
        no: 0,
        Label: 'admin.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'admin.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
    ],
  },

  DOCTOR_TABS: [
    {
      no: 0,
      Label: 'tab.react-table-doctor_detail',
      name: 'Detail',
      id: 'detail',
      tabId: 'details',
    },
    {
      no: 1,
      Label: 'tab.react-table-doctor_availability',
      name: 'Availability',
      id: 'availability',
      tabId: 'availability',
    },
    {
      no: 2,
      Label: 'tab.react-table-doctor_publishedPapers',
      name: 'Published Papers',
      id: 'publishedPapers',
      tabId: 'publishedPapers',
    },
    {
      no: 3,
      Label: 'tab.react-table-doctor_managementFeedback',
      name: 'Management Feedback',
      id: 'managementFeedback',
      tabId: 'managementFeedback',
    },
    {
      no: 4,
      Label: 'tab.react-table-doctor_visitPage',
      name: 'visitPage',
      id: 'visitPage',
      tabId: 'visitPage',
    },
    {
      no: 5,
      Label: 'tab.react-table-doctor_certificationPage',
      name: 'certificationPage',
      id: 'certificationPage',
      tabId: 'certificationPage',
    },
  ],

  PATIENT_TABS: [
    {
      no: 0,
      Label: 'tab.react-table-patient_detail',
      name: 'Detail',
      id: 'detail',
      tabId: 'details',
    },
    {
      no: 1,
      Label: 'tab.react-table-patient_address',
      name: 'Address',
      id: 'address',
      tabId: 'address',
    },
    {
      no: 2,
      Label: 'tab.react-table-patient_feedback',
      name: 'Feedback',
      id: 'feedback',
      tabId: 'feedback',
    },
    {
      no: 3,
      Label: 'tab.react-table-patient_emergency_contact',
      name: 'Emergency Contact',
      id: 'emergencyContact',
      tabId: 'emergencyContact',
    },
    {
      no: 4,
      Label: 'tab.react-table-patient_report',
      name: 'Report',
      id: 'report',
      tabId: 'report',
    },
    {
      no: 4,
      Label: 'tab.react-table-patient_visitpage',
      name: 'PatientvisitPage',
      id: 'patientvisitPage',
      tabId: 'patientvisitPage',
    },
    {
      no: 5,
      Label: 'tab.react-table-patient_orderpage',
      name: 'PatientOrder',
      id: 'PatientOrder',
      tabId: 'PatientOrder',
    },
  ],

  // PROFILE_INFO: [
  //   {
  //     no: 0,
  //     Label: 'table.react-table-profile_user',
  //     name: 'Profile',
  //     id: 'Profile',
  //     tabId: 'Profile',
  //   },
  // ],
};

export const CURRANT_USER = {
  user: {},
};

export const TIME_DIFFERENT = (date1, date2) => {
  const years = date1.diff(date2, 'year');
  date2.add(years, 'years');

  const months = date1.diff(date2, 'months');
  date2.add(months, 'months');

  const days = date1.diff(date2, 'days');
  date2.add(days, 'days');

  const hours = date1.diff(date2, 'hours');
  date2.add(hours, 'hours');

  const minutes = date1.diff(date2, 'minutes');
  date2.add(minutes, 'minutes');

  const seconds = date1.diff(date2, 'seconds');

  if (years > 0) {
    return `${years} Year`;
  }

  if (months > 0) {
    return `${months} Months`;
  }

  if (days > 0) {
    return `${days} Days`;
  }

  if (hours > 0) {
    return `${hours} Hours`;
  }

  if (minutes > 0) {
    return `${minutes} Minutes`;
  }

  return `${seconds} Seconds`;
};

export const getTime = (time) => {
  if (+time / (365 * 24 * 60 * 60) > 1) {
    return `${(+time / (365 * 24 * 60 * 60)).toFixed(2)} Y`;
  }
  if (+time / (7 * 24 * 60 * 60) > 1) {
    return `${(+time / (7 * 24 * 60 * 60)).toFixed(2)} W`;
  }
  if (+time / (24 * 60 * 60) > 1) {
    return `${(+time / (24 * 60 * 60)).toFixed(2)} D`;
  }
  if (+time / (60 * 60) > 1) {
    return `${(+time / (60 * 60)).toFixed(2)} H`;
  }
  if (+time / 60 > 1) {
    return `${(+time / 60).toFixed(2)} M`;
  }
  return `${(+time / (365 * 24 * 60 * 60)).toFixed(2)} S`;
};

export const DATE_FORMAT = {
  STANDARD_DISPLAY: 'DD-MM-YYYY',
};

export const ROUTES = {
  FORGOT_PASSWORD: '/forgot-password',
  UPDATE_PASSWORD: '/forgot-password/:updatePasswordId',
  RESET_PASSWORD: '/resetPassword/:token',
  REGISTER: '/register',
  DOCTOR: '/doctor',
  DOCTOR_DETAIL: '/doctor/:id',
  PATIENT: '/patient',
  PATIENT_DETAIL: '/patient/:id',
  PLAN: '/plan',
  APPOINTMENT: '/appointment',
  // QUESTION: '/question',
  ASSESMENT: '/assesment',
  PRESCRIPTION: '/prescription',
  MEDICINE: '/medicine',
  TEST_REPORT: '/test-report',
  FOLLOW_UP: '/follow-up',
  NOTIFICATION: '/notification',
  COMPLAINT: '/complaint',
  FEEDBACK: '/feedback',
  ORDER: '/order',
  ORDER_DETAILS: '/order/:id',
  SETTING: '/setting',
  DASHBOARD: '/dashboard',
  FAQ: '/faq',
  MEDICINE_ORDER: '/medicineOrder',
  MEDICINE_ORDER_DETAILS: '/medicineOrder/:id',
  SYMPTOMS: '/symptoms',
  LAB_TEST: '/labTest',
  REPORT: '/report',
  SPECIALITY: '/speciality',
  ADMINISTRATION: '/administration',
};

export const ALL_ROUTES = [
  {
    path: ROUTES.DASHBOARD,
    component: Dashboard,
    exact: true,
  },
  {
    path: ROUTES.DOCTOR,
    component: Doctor,
    exact: true,
  },
  {
    path: ROUTES.DOCTOR_DETAIL,
    component: DoctorDetail,
    exact: true,
  },
  {
    path: ROUTES.PATIENT,
    component: Patient,
    exact: true,
  },
  {
    path: ROUTES.PATIENT_DETAIL,
    component: PatientDetail,
    exact: true,
  },
  {
    path: ROUTES.PLAN,
    component: Plan,
    exact: true,
  },
  {
    path: ROUTES.APPOINTMENT,
    component: Appointment,
    exact: true,
  },
  // {
  //   path: ROUTES.QUESTION,
  //   component: Question,
  //   exact: true,
  // },
  {
    path: ROUTES.ASSESMENT,
    component: Assesment,
    exact: true,
  },
  {
    path: ROUTES.PRESCRIPTION,
    component: Prescription,
    exact: true,
  },
  {
    path: ROUTES.MEDICINE,
    component: Medicine,
    exact: true,
  },
  {
    path: ROUTES.REPORT,
    component: Report,
    exact: true,
  },
  {
    path: ROUTES.FOLLOW_UP,
    component: FollowUp,
    exact: true,
  },
  {
    path: ROUTES.NOTIFICATION,
    component: Notification,
    exact: true,
  },
  {
    path: ROUTES.COMPLAINT,
    component: Complaint,
    exact: true,
  },

  {
    path: ROUTES.ORDER,
    component: Order,
    exact: true,
  },
  {
    path: ROUTES.ORDER_DETAILS,
    component: PatientOrderDetails,
    exact: true,
  },
  {
    path: ROUTES.SETTING,
    component: SettingPage,
    exact: true,
  },
  {
    path: ROUTES.FAQ,
    component: Faq,
    exact: true,
  },
  {
    path: ROUTES.MEDICINE_ORDER,
    component: MedicineOrder,
    exact: true,
  },
  {
    path: ROUTES.MEDICINE_ORDER_DETAILS,
    component: UserMedicineOrder,
    exact: true,
  },
  {
    path: ROUTES.LAB_TEST,
    component: LabTest,
    exact: true,
  },
  ,
  {
    path: ROUTES.SYMPTOMS,
    component: Symptoms,
    exact: true,
  },
  {
    path: ROUTES.SPECIALITY,
    component: Speciality,
    exact: true,
  },
  {
    path: ROUTES.ADMINISTRATION,
    component: Administration,
    exact: true,
  },
];

export const DISPLAY_TEXT_TYPE = {
  DATE: 'DATE',
  YES_NO: 'YES_NO',
};

export const GENDER = {
  Male: 'M',
  Female: 'F',
  Other: 'O',
};

export default CONSTANTS;
