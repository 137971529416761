import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import AlertPopup from 'components/alert-popup';
import IntlMessages from 'helpers/IntlMessages';
import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Button, Container, Spinner } from 'reactstrap';
import {
  addAssesment,
  editAssesment,
  deleteAssesment,
  getAllAssesment,
  getAllSpeciality,
} from 'utils/API/api';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';

let speciality = [];
const Assesment = ({ match }) => {
  const [assesmentList, setAssesmentlist] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [assesmentData, setAssesmentData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [formFields, setFormFields] = useState([]);

  const handleDeleteAssesment = (assesmentId) => {
    setDeleteId(assesmentId);
    setIsConfirmationOpen(true);
  };
  const confirmDeleteAssesment = async () => {
    if (deleteId) {
      const res = await deleteAssesment(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditAssesment = (editValue) => {
    if (editValue) {
      setAssesmentData({ ...editValue });
      setEditModalOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
      const respo = await getAllSpeciality();
      if (respo !== -1) {
        speciality = respo?.data?.data.map((item) => {
          return {
            label: item?.name,
            id: item?.name,
            value: item?.name,
          };
        });
      }
      console.log(speciality);
      setFormFields(() => {
        const tempValue = CONSTANTS.RIGHT_SIDEBAR_FIELD.ASSESMENT_MODAL.forEach(
          (field) => {
            const newObj = field;
            if (newObj.name === 'specialization') {
              newObj.option = speciality;
            }
            return newObj;
          }
        );

        return tempValue;
      });

      const res = await getAllAssesment();
      if (res !== -1) {
        setAssesmentlist(
          res?.data?.data.map((assesmentItem, index) => {
            return {
              no: index + 1,
              ...assesmentItem,
              // specialization: `${speciality[].label}`,
              deleteData: {
                buttonLabel: 'Delete',
                id: assesmentItem?.id,
                onClick: handleDeleteAssesment,
              },

              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...assesmentItem,
                },
                onClick: handleEditAssesment,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);
  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.ASSESMENT_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });

    return payload;
  };
  const editAssesmentHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (assesmentData?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await editAssesment(assesmentData?.id, formData);

        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };
  const addAssesmentHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      // if (formData.specialization) {
      //   formData.specialization = speciality[formData.speciality].label;
      // }
      setLoading(true);
      const res = await addAssesment(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addAssesment} />
                  </Button>
                </Container>

                <AddNewModalWithOutButton
                  sidebarMenu="ASSESMENT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.addAssesment}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addAssesmentHandler}
                  formData={{}}
                  formFields={formFields}
                />
                <AddNewModalWithOutButton
                  sidebarMenu="ASSESMENT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editAssesment}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editAssesmentHandler}
                  formData={assesmentData}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.ASSESMENT_LIST}
                  items={assesmentList}
                  advisorId={CONSTANTS.TABLE_ID.assesment}
                  filterParams="name"
                />
                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteAssesment}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};

export default Assesment;
