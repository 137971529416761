import React, { useEffect, useState } from 'react';
import {
  Row,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
  Form,
  CardTitle,
  Container,
  Spinner,
  Button,
} from 'reactstrap';
import { Colxx } from 'components/common/CustomBootstrap';
import IntlMessages from 'helpers/IntlMessages';
import CONSTANTS from 'utils/CONSTANTS';
import {
  addGroupNotification,
  addNotification,
  getAllDoctor,
  getAllPatient,
  getDoctorDetail,
  getPatientDetail,
} from 'utils/API/api';

const Notification = () => {
  const [texData, setTexData] = useState({});
  const [Loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState([]);
  const [formPatientFields, setFormPatientFields] = useState([]);
  const [doctorSelectValue, setDoctorSelectValue] = useState();
  const [patientSelectValue, setPatientSelectValue] = useState();
  const [allSelectValue, setAllSelectValue] = useState('login');

  let doctorList = [];
  let patientList = [];

  // 35589944;

  useEffect(() => {
    (async () => {
      const res = await getAllDoctor();
      if (res !== -1) {
        setDoctorSelectValue(res?.data?.data[0]?.id);
        doctorList = res?.data?.data.map((item) => {
          return {
            label: `${item?.firstName} ${item?.lastName}`,
            id: item?.id,
            value: item?.id,
          };
        });

        setFormFields(() => {
          const tempValue = CONSTANTS.PERSONAL_NOTIFICATION.map((field) => {
            const newObj = field;
            if (newObj.name === 'doctorlist') {
              newObj.option = doctorList;
            }
            return newObj;
          });

          return tempValue;
        });
        setLoading(true);
        setTexData({});
        setLoading(false);
      }

      const resP = await getAllPatient();
      if (resP !== -1) {
        setPatientSelectValue(resP?.data?.data?.data?.rows[0]?.id);
        patientList = resP?.data?.data?.data?.rows.map((item) => {
          return {
            label: `${item?.firstName} ${item?.lastName}`,
            id: item?.id,
            value: item?.id,
          };
        });
        setFormPatientFields(() => {
          const tempValue = CONSTANTS.PERSONAL_PATIENT_NOTIFICATION.map(
            (field) => {
              const newObj = field;
              if (newObj.name === 'patientlist') {
                newObj.option = patientList;
              }
              return newObj;
            }
          );

          return tempValue;
        });
        setLoading(true);
        setTexData({});
        setLoading(false);
      }
    })();
  }, []);

  const OnFromSubmitHandler = (e) => {
    e.preventDefault();
    (async () => {
      const res = await getDoctorDetail(doctorSelectValue);

      if (res !== -1) {
        const data = res?.data?.data?.data;

        const notification = await addNotification({
          token: data?.FcmToken?.toString(),
          userId: data?.id,
          title: e.target.title.value?.toString(),
          click_action: 'home_page',
          body: e.target.notificationMessage.value?.toString(),
          role: 'doctor',
        });
        console.log(notification);
      }
    })();
  };
  const OnFromPatientSubmitHandler = (e) => {
    e.preventDefault();

    (async () => {
      const res = await getPatientDetail(patientSelectValue);

      if (res !== -1) {
        const data = res?.data?.data;

        const notification = await addNotification({
          token: data?.FcmToken?.toString(),
          userId: data?.id,
          title: e.target.title.value?.toString(),
          click_action: 'home_page',
          body: e.target.notificationMessage.value?.toString(),
          role: 'patient',
        });
        console.log(notification);
      }
    })();
  };

  const OnGroupFromSubmitHandler = (e) => {
    e.preventDefault();

    (async () => {
      const notification = await addGroupNotification({
        topic: allSelectValue,
        title: e.target.title.value?.toString(),
        click_action: 'home_page',
        body: e.target.notificationMessage.value?.toString(),
      });
      console.log(notification);
    })();
  };
  return (
    <>
      {!Loading ? (
        <>
          <Row className="mb-4">
            <Colxx xxs="12">
              <Card>
                <CardTitle
                  style={{
                    marginTop: '31px',
                    marginBottom: '10px',
                    fontWeight: '800',
                    marginLeft: '27px',
                  }}
                >
                  <IntlMessages id="table.react-table-personal-notification" />
                </CardTitle>
                <CardBody>
                  {formFields.length && (
                    <Form onSubmit={OnFromSubmitHandler}>
                      {formFields.map((data) => (
                        <FormGroup key={data.id} row>
                          <Label for={data.label} sm={4}>
                            <IntlMessages id={data.tagId} />
                          </Label>
                          <Colxx sm={8}>
                            {data?.type === 'select' ? (
                              <>
                                <Input
                                  id={data.id}
                                  type={data.type}
                                  name={data.id}
                                  size="1"
                                  required={data?.required}
                                  onChange={(g) => {
                                    setDoctorSelectValue(g.target.value);
                                  }}
                                >
                                  {data?.option.map((el) => (
                                    <option
                                      key={`role_${el.id}`}
                                      value={el.value}
                                    >
                                      {el.label ? el.label : el.value}
                                    </option>
                                  ))}
                                </Input>
                              </>
                            ) : (
                              <Input
                                type={data.type}
                                name={data.name}
                                id={data.apiKey}
                                defaultValue={
                                  texData[data.apiKey]
                                    ? texData[data.apiKey]
                                    : ''
                                }
                                placeholder={data.messages}
                              />
                            )}
                          </Colxx>
                        </FormGroup>
                      ))}
                      <Button color="primary" disabled={Loading}>
                        <IntlMessages id="forms.submit" />
                      </Button>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Colxx>
          </Row>
          <Row className="mb-4">
            <Colxx xxs="12">
              <Card>
                <CardTitle
                  style={{
                    marginTop: '31px',
                    marginBottom: '10px',
                    fontWeight: '800',
                    marginLeft: '27px',
                  }}
                >
                  <IntlMessages id="table.react-table-patient-notification" />
                </CardTitle>
                <CardBody>
                  {formPatientFields.length && (
                    <Form onSubmit={OnFromPatientSubmitHandler}>
                      {formPatientFields.map((data) => (
                        <FormGroup key={data.id} row>
                          <Label for={data.label} sm={4}>
                            <IntlMessages id={data.tagId} />
                          </Label>
                          <Colxx sm={8}>
                            {data?.type === 'select' ? (
                              <>
                                <Input
                                  id={data.id}
                                  type={data.type}
                                  name={data.id}
                                  size="1"
                                  required={data?.required}
                                  onChange={(g) => {
                                    setPatientSelectValue(g.target.value);
                                  }}
                                >
                                  {data?.option.map((el) => (
                                    <option
                                      key={`role_${el.id}`}
                                      value={el.value}
                                    >
                                      {el.label ? el.label : el.value}
                                    </option>
                                  ))}
                                </Input>
                              </>
                            ) : (
                              <Input
                                type={data.type}
                                name={data.name}
                                id={data.apiKey}
                                defaultValue={
                                  texData[data.apiKey]
                                    ? texData[data.apiKey]
                                    : ''
                                }
                                placeholder={data.messages}
                              />
                            )}
                          </Colxx>
                        </FormGroup>
                      ))}
                      <Button color="primary" disabled={Loading}>
                        <IntlMessages id="forms.submit" />
                      </Button>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Colxx>
          </Row>
          <Row className="mb-4">
            <Colxx xxs="12">
              <Card>
                <CardTitle
                  style={{
                    marginTop: '31px',
                    marginBottom: '10px',
                    fontWeight: '800',
                    marginLeft: '27px',
                  }}
                >
                  <IntlMessages id="table.react-table-group-notification" />
                </CardTitle>
                <CardBody>
                  <Form onSubmit={OnGroupFromSubmitHandler}>
                    {CONSTANTS.PERSONAL_GROUP_NOTIFICATION.map((data) => (
                      <FormGroup key={data.id} row>
                        <Label for={data.label} sm={4}>
                          <IntlMessages id={data.tagId} />
                        </Label>
                        <Colxx sm={8}>
                          {data?.type === 'select' ? (
                            <>
                              <Input
                                id={data.id}
                                type={data.type}
                                name={data.id}
                                size="1"
                                required={data?.required}
                                value={allSelectValue}
                                onChange={(g) => {
                                  setAllSelectValue(g.target.value);
                                }}
                              >
                                <option id={0} value="login">
                                  Login
                                </option>
                                <option id={1} value="logout">
                                  Logout
                                </option>
                                <option id={1} value="announcement">
                                  New Announcement
                                </option>
                              </Input>
                            </>
                          ) : (
                            <Input
                              type={data.type}
                              name={data.name}
                              id={data.apiKey}
                              defaultValue={
                                texData[data.apiKey] ? texData[data.apiKey] : ''
                              }
                              placeholder={data.messages}
                            />
                          )}
                        </Colxx>
                      </FormGroup>
                    ))}

                    <Button color="primary" disabled={Loading}>
                      <IntlMessages id="forms.submit" />
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </>
      ) : (
        <Container className="d-flex justify-content-center align-items-center">
          <Spinner
            animation="border"
            className="d-inline-flex m-2 "
            color="$theme-color-yellow-granola"
          />
        </Container>
      )}
    </>
  );
};

export default Notification;
