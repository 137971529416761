import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS, { ROUTES } from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
// import {
//   addMedicineOrder,
//   deleteMedicineOrder,
//   editMedicineOrder,
//   getAllMedicine,
//   getAllMedicineOrder,
//   getAllOrder,
// } from 'utils/API/api';
// import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
// import IntlMessages from 'helpers/IntlMessages';
// import AlertPopup from 'components/alert-popup';
import useHttp from 'components/Hook/Use-http';
import moment from 'moment';

const MedicineOrder = ({ match }) => {
  const [patientList, setPatientList] = useState([])

  const api = useHttp();



  useEffect(() => {
    const ALL_USERS_LIST = { ...CONSTANTS.API.getAllOrders }
    api.sendRequest(ALL_USERS_LIST, (res) => {
      // console.log(res?.data);
      setPatientList(res?.data?.map((data, index) => {
        return {
          ...data,
          no: index + 1,
          name: `${data?.patient?.firstName || '-'} ${data?.patient?.lastName || ''}`,
          view: `${ROUTES.MEDICINE_ORDER}/${data?.id}`,
          mobile: data?.patient?.mobile || '-',
          status: data?.orderTracking?.status || '-',
          date: moment.utc(data?.createdAt).utcOffset('+05:30').format('DD MMM, YYYY'),
        }
      }))
    })
  }, [])


  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !api.isLoading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                />


                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.MEDICINE_ORDER_USER_LIST}
                  items={patientList}
                  advisorId={CONSTANTS.TABLE_ID.patientMedicineOrder}
                  filterParams="medicineName"
                />


              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default MedicineOrder;
