import React, { useEffect, useState, Suspense } from 'react';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import CONSTANTS from 'utils/CONSTANTS';
import { useParams } from 'react-router-dom';
import useHttp from 'components/Hook/Use-http';
import { Container, Spinner } from 'reactstrap';

import ViewTable from 'utils/ReactTableCards';

const PatientVisitPage = () => {
  const [patientVisitedDoctorList, setPatientVisitedDoctorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const API = useHttp();
  const { id: patientId } = useParams();
  const getAllReportHandler = async (res) => {
    // const result = res?.data.filter((el) => el.patientId === Number(patientId));
    // console.log(result, '2');
    setPatientVisitedDoctorList(
      res?.data.map((report, i) => {
        return {
          no: i + 1,
          name: `${report?.doctor?.firstName} ${report?.doctor?.lastName}`,
          ...report,
          isUserVisited: report.isUserVisited ? 'Yes' : 'No',
          isDoctorVisited: report.isDoctorVisited ? 'Yes' : 'No',
          isReschedule: report.isReschedule ? 'Yes' : 'No',
          slotStartTime: report.slotStartTime,
          isCanceled: report.isCanceled ? 'Yes' : 'No',
          isFakeBooking: report.isFakeBooking ? 'Yes' : 'No',
        };
      })
    );
  };
  useEffect(() => {
    setLoading(false);
    const APIpoint = { ...CONSTANTS.API.patientVisiterPage };
    APIpoint.endpoint = APIpoint.endpoint.replace(':id', `${patientId}`);
    API.sendRequest(APIpoint, getAllReportHandler);
  }, []);

  return (
    <Suspense fallback={<div className="loading" />}>
      {!loading ? (
        <>
          <ViewTable
            headers={CONSTANTS.TABLE_HEADER.PATIENT_VISITED_DOCTOR_LIST}
            items={patientVisitedDoctorList}
            advisorId={CONSTANTS.TABLE_ID.patientVisitedDoctorList}
            filterParams="name"
          />
        </>
      ) : (
        <>
          <Container className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              className="d-inline-flex m-2 "
              color="$theme-color-yellow-granola"
            />
          </Container>
        </>
      )}
    </Suspense>
  );
};

export default PatientVisitPage;
