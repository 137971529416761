import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  addLabTest,
  deleteLabTest,
  editLabTest,
  getAllLabTest,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';

const LabTest = ({ match }) => {
  const [labTestList, setLabTestList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [labTestData, setLabTestData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteLabTest = (labTestId) => {
    setDeleteId(labTestId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteLabTest = async () => {
    if (deleteId) {
      const res = await deleteLabTest(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditLabTest = (editValue) => {
    if (editValue) {
      setLabTestData({ ...editValue });
      setEditModalOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
      const res = await getAllLabTest();
      if (res !== -1) {
        setLabTestList(
          res?.data?.data.map((labTestItem, index) => {
            return {
              no: index + 1,
              ...labTestItem,
              deleteData: {
                buttonLabel: 'Delete',
                id: labTestItem?.id,
                onClick: handleDeleteLabTest,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...labTestItem,
                },
                onClick: handleEditLabTest,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.LAB_TEST_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });

    return payload;
  };

  const editLabTestHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (labTestData?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await editLabTest(labTestData?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addLabTestHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      setLoading(true);
      const res = await addLabTest(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addLabTest} />
                  </Button>
                </Container>
                <AddNewModalWithOutButton
                  sidebarMenu="LAB_TEST_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.addLabTest}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addLabTestHandler}
                  formData={{}}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="LAB_TEST_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editLabTest}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editLabTestHandler}
                  formData={labTestData}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.LAB_TEST_LIST}
                  items={labTestList}
                  advisorId={CONSTANTS.TABLE_ID.labTest}
                  filterParams="name"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteLabTest}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default LabTest;
