import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  addPatientEmergencyContact,
  deletePatientEmergencyContact,
  editPatientEmergencyContact,
  getAllPatientEmergencyContact,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';

const PatientEmergencyContact = () => {
  const { id: patientId } = useParams();
  const [patientEmergencyContactList, setPatientEmergencyContactList] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [patientEmergencyContactData, setPatientEmergencyContactData] =
    useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeletePatientEmergencyContact = (patientEmergencyContactId) => {
    setDeleteId(patientEmergencyContactId);
    setIsConfirmationOpen(true);
  };

  const confirmDeletePatientEmergencyContact = async () => {
    if (deleteId) {
      const res = await deletePatientEmergencyContact(deleteId, patientId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditPatientEmergencyContact = (editValue) => {
    if (editValue) {
      setPatientEmergencyContactData({ ...editValue });
      setEditModalOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
      const res = await getAllPatientEmergencyContact(patientId);
      if (res !== -1) {
        setPatientEmergencyContactList(
          res?.data?.data.map((patientEmergencyContactItem, index) => {
            return {
              no: index + 1,
              ...patientEmergencyContactItem,
              deleteData: {
                buttonLabel: 'Delete',
                id: patientEmergencyContactItem?.id,
                onClick: handleDeletePatientEmergencyContact,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...patientEmergencyContactItem,
                },
                onClick: handleEditPatientEmergencyContact,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_EMERGENCY_CONTACT_MODAL.forEach(
      (field) => {
        const tempValue = e.target[field.name].value;
        if (tempValue) {
          payload[field.name] = tempValue;
        }
      }
    );

    if (patientId) {
      payload.patientId = patientId;
    }

    return payload;
  };

  const editPatientEmergencyContactHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (patientEmergencyContactData?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await editPatientEmergencyContact(
          patientEmergencyContactData?.id,
          formData,
          patientId
        );
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addPatientEmergencyContactHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      setLoading(true);
      const res = await addPatientEmergencyContact(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      {!loading ? (
        <>
          <Container
            style={{
              display: 'flex',
              justifyContent: 'end',
              maxWidth: '100%',
              margin: '0px',
              padding: '0',
              marginTop: '-10px',
              marginBottom: '15px',
              marginLeft: '-27px',
            }}
          >
            <Button
              outline
              color="primary"
              className="top-right-button"
              onClick={() => setModalOpen(true)}
            >
              <IntlMessages
                id={CONSTANTS.TABLE_ID.addPatientEmergencyContact}
              />
            </Button>
          </Container>
          <AddNewModalWithOutButton
            sidebarMenu="PATIENT_EMERGENCY_CONTACT_MODAL"
            modalTitle={CONSTANTS.TABLE_ID.addPatientEmergencyContact}
            modalOpen={modalOpen}
            toggleModal={() => setModalOpen(!modalOpen)}
            onSubmit={addPatientEmergencyContactHandler}
            formData={{}}
          />

          <AddNewModalWithOutButton
            sidebarMenu="PATIENT_EMERGENCY_CONTACT_MODAL"
            modalTitle={CONSTANTS.TABLE_ID.editPatientEmergencyContact}
            modalOpen={editModalOpen}
            toggleModal={() => setEditModalOpen(!editModalOpen)}
            onSubmit={editPatientEmergencyContactHandler}
            formData={patientEmergencyContactData}
          />

          <ViewTable
            headers={CONSTANTS.TABLE_HEADER.PATIENT_EMERGENCY_CONTACT_LIST}
            items={patientEmergencyContactList}
            advisorId={CONSTANTS.TABLE_ID.patientEmergencyContact}
            filterParams="name"
          />

          <AlertPopup
            isOpen={isConfirmationOpen}
            positiveText="Yes"
            negativeText="No"
            warning="Are you sure you want to delete?"
            onNegative={() => {
              setIsConfirmationOpen(false);
              setDeleteId(null);
            }}
            onPositive={confirmDeletePatientEmergencyContact}
          />
        </>
      ) : (
        <>
          <Container className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              className="d-inline-flex m-2 "
              color="$theme-color-yellow-granola"
            />
          </Container>
        </>
      )}
    </Suspense>
  );
};
export default PatientEmergencyContact;
