import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS, { ROUTES } from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  addPatient,
  deletePatient,
  editPatient,
  getAllPatient,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';

const Patient = ({ match }) => {
  const [patientList, setPatientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeletePatient = (clientId) => {
    setDeleteId(clientId);
    // setIsConfirmationOpen(true);
  };

  const confirmDeletePatient = async () => {
    if (deleteId) {
      const res = await deletePatient(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditPatient = (editValue) => {
    if (editValue) {
      setPatientData({ ...editValue });
      setEditModalOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllPatient();
      if (res !== -1) {
        setPatientList(
          res?.data?.data?.data?.rows.map((patientItem, index) => {
            return {
              no: index + 1,
              ...patientItem,
              fullName: `${patientItem?.firstName || "-"} ${patientItem?.lastName || ""}`,
              name: `${patientItem?.firstName || "-"} ${patientItem?.lastName || ''}`,
              mobile: `${patientItem?.mobile || "-"}`?.slice(-10),
              view: `${ROUTES.PATIENT}/${patientItem?.id}`,
              deleteData: {
                buttonLabel: 'Delete',
                id: patientItem?.id,
                onClick: handleDeletePatient,
              },
              blockData: {
                id: patientItem?.id,
                checked: !patientItem?.isBlocked,
                onClick: (el) => {
                  console.log(el);
                  // const updateAPI = { ...CONSTANTS.API.editBrand };
                  // updateAPI.endpoint = updateAPI.endpoint.replace(
                  //   ":dataId",
                  //   el
                  // );
                  (async () => {
                    console.log(patientItem?.id);
                    if (patientItem?.id) {
                      // const formData = prepareEditFormData(e);

                      setLoading(true);
                      const ress = await editPatient(patientItem?.id, {
                        isBlocked: !patientItem?.isBlocked,
                      });
                      if (ress !== -1) {
                        setRefresh((previous) => !previous);
                      }
                      setLoading(false);
                    }
                  })();
                  // API.sendRequest(
                  //   updateAPI,
                  //   (res) => {
                  //     // console.log(res);
                  //     setRefresh((prev) => !prev);
                  //   },
                  //   { underMaintenance: !e?.underMaintenance }
                  // );
                },
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...patientItem,
                  mobile: `${patientItem?.mobile}`?.slice(-10),
                  profilePic: '',
                },
                onClick: handleEditPatient,
              },
            };
          })
        );
      }

      setLoading(false);
    })();
  }, [refresh]);

  const getGovernmentType = (type) => {
    let value = '';
    if (type === 'Aadhar Card') {
      value = 'aadharCard';
    }
    return value;
  };

  // const getGender = (gender) => {
  //   let value = 'other';
  //   if (gender === 'Male') {
  //     value = 'male';
  //   } else if (gender === 'Female') {
  //     value = 'female';
  //   }

  //   return value;
  // };

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
      if (
        field.name === 'isEmailVerified' ||
        field.name === 'isPremiumMembership' ||
        field.name === 'isMobileVerified'
      ) {
        payload[field.name] = e.target[field.name].checked;
      }
    });

    if (e.target?.governmentId?.files && e.target?.governmentId?.files[0]) {
      payload.govermentId = e.target?.governmentId?.files[0];
      delete payload.governmentId;
    }

    // if (payload?.gender) {
    //   payload.gender = getGender(payload.gender);
    // }
    if (payload?.governmentType) {
      payload.governmentType = getGovernmentType(payload?.governmentType);
    }
    if (payload?.loginWith) {
      payload.loginWith = payload.loginWith.toLowerCase();
    }
    if (payload?.mobile) {
      payload.mobile = `${payload.countryCode}${payload.mobile}`;
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return formData;
  };

  const prepareEditFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_EDIT_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      // console.log(tempValue);

      if (tempValue) {
        payload[field.name] = tempValue;
      }

      if (
        field.name === 'isEmailVerified' ||
        field.name === 'isPremiumMembership' ||
        field.name === 'isMobileVerified'
      ) {
        payload[field.name] = e.target[field.name].checked;
      }
    });

    if (e.target?.governmentId?.files && e.target?.governmentId?.files[0]) {
      payload.govermentId = e.target?.governmentId?.files[0];
      delete payload.governmentId;
    }

    // if (payload?.gender) {
    //   payload.gender = getGender(payload.gender);
    // }
    if (payload?.governmentType) {
      payload.governmentType = getGovernmentType(payload?.governmentType);
    }
    if (payload?.loginWith) {
      payload.loginWith = payload.loginWith.toLowerCase();
    }
    if (payload?.mobile) {
      payload.mobile = `${payload.countryCode}${payload.mobile}`;
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return formData;
  };

  const editPatientHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (patientData?.id) {
        const formData = prepareEditFormData(e);

        setLoading(true);
        const res = await editPatient(patientData?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addPatientHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      setLoading(true);
      const res = await addPatient(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addPatient} />
                  </Button>
                </Container>
                <AddNewModalWithOutButton
                  sidebarMenu="PATIENT_MODAL"
                  dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.addPatient}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addPatientHandler}
                  formData={{}}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="PATIENT_EDIT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editPatient}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editPatientHandler}
                  formData={patientData}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.PATIENT_LIST}
                  items={patientList}
                  advisorId={CONSTANTS.TABLE_ID.patient}
                  filterParams="fullName"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeletePatient}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default Patient;
