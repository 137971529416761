import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import { addFaq, deleteFaq, editFaq, getAllFaq } from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import moment from 'moment';

const Faq = ({ match }) => {
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [faqData, setFaqData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [questionModal, setQuestionModal] = useState(null)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  console.log(questionModal);

  const handleDeleteFaq = (clientId) => {
    setDeleteId(clientId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteFaq = async () => {
    if (deleteId) {
      const res = await deleteFaq(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditFaq = (editValue) => {
    if (editValue) {
      setFaqData({ ...editValue });
      setEditModalOpen(true);
    }
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;

  }
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllFaq();
      if (res !== -1) {
        setFaqList(
          res?.data?.data.map((faqItem, index) => {
            return {
              no: index + 1,
              ...faqItem,
              // questionD: `${faqItem?.question.substring(0, 50)}...`,
              questionD: truncateText(faqItem?.question, 60),
              answerD: truncateText(faqItem?.answer, 65),
              createdAt: moment.utc(faqItem?.createdAt).utcOffset('+05:30').format('DD MMM, YYYY'),
              isActive: faqItem.isActive ? 'Yes' : 'No',
              deleteData: {
                buttonLabel: 'Delete',
                id: faqItem?.id,
                onClick: handleDeleteFaq,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...faqItem,
                  profilePic: '',
                },
                onClick: handleEditFaq,
              },
              view: {
                id: faqItem?.id,
                onClick: () => {
                  setQuestionModal(faqItem)
                },
              },
            };
          })
        );
      }
      setLoading(false);
    })();
  }, [refresh]);

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.FAQ_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });

    return payload;
  };

  const editFaqHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (faqData?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await editFaq(faqData?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addFaqHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      setLoading(true);
      const res = await addFaq(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addFaq} />
                  </Button>
                </Container>
                <AddNewModalWithOutButton
                  sidebarMenu="FAQ_MODAL"
                  dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.addFaq}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addFaqHandler}
                  formData={{}}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="FAQ_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editFaq}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editFaqHandler}
                  formData={faqData}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.FAQ_LIST}
                  items={faqList}
                  advisorId={CONSTANTS.TABLE_ID.faq}
                  filterParams="question"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteFaq}
                />
                <Modal scrollable isOpen={questionModal !== null} toggle={() => setQuestionModal(null)} >
                  <ModalHeader toggle={() => setQuestionModal(null)}>FAQ Question</ModalHeader>
                  <ModalBody>
                    <div style={{ fontSize: '16px' }}>
                      <strong style={{ fontSize: '18px' }}>Question:</strong> {questionModal?.question || '-'}
                    </div>
                    <br />
                    <div style={{ fontSize: '16px' }}>
                      <strong style={{ fontSize: '18px' }}>Answer:</strong> {questionModal?.answer || '-'}
                    </div>
                  </ModalBody>

                </Modal>
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default Faq;
