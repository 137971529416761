/* eslint-disable no-unused-vars */
import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner, Col, CardBody, Card, Row } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import { getAllAnalysis } from 'utils/API/api';
import useInput from 'components/Hook/use-input';
import useHttp from 'components/Hook/Use-http';
import { isNonEmpty } from 'utils/function';

let cardAnalyticsData = [
  {
    id: "patient",
    icon: 'simple-icon-people',
    name: 'Patient',
    value: 'Loading...',
  },
  {
    id: "doctor",
    icon: 'iconsminds-stethoscope',
    name: 'Doctor',
    value: 'Loading...',
  },
  {
    id: "assessment",
    icon: 'iconsminds-check',
    name: 'Assessment',
    value: 'Loading...',
  },

  {
    id: "order",
    icon: 'iconsminds-shopping-bag',
    name: 'Order',
    value: 'Loading...',
  },
  {
    id: "plan",
    icon: 'simple-icon-notebook',
    name: 'Plan',
    value: 'Loading...',
  },
  {
    id: "patientVisitor",
    icon: 'simple-icon-people',
    name: 'Patient Visitor',
    value: 'Loading...',
  },
  // {
  //   id: 6,
  //   icon: 'iconsminds-notepad',
  //   name: 'Appointment',
  //   value: 'Loading...',
  // },
  {
    id: "totalAmountUserSide",
    icon: 'simple-icon-people',
    name: 'Amount Recevied From Patient',
    value: 'Loading...',
  },
  {
    id: "totalAmountDoctorSide",
    icon: 'iconsminds-stethoscope',
    name: 'Amount Paid to Doctor',
    value: 'Loading...',
  },
];
const Dashboard = ({ match }) => {
  const [AnalysisList, setAnalysisList] = useState({});
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const inpStartDate = useInput(isNonEmpty);
  const inpEndDate = useInput(isNonEmpty);

  const API = useHttp();


  const analysisDataHandler = (res) => {
    const dataArray = Object.entries(res?.data).map(([key, value]) => ({
      label: key,
      value,
    }));
    console.log(dataArray, res?.data)
    cardAnalyticsData = cardAnalyticsData.map((datas, index) => ({
      ...datas,
      value: res?.data[datas?.id]?.toFixed(0) || "00",
    }));
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllAnalysis();
      if (res !== -1) {
        setAnalysisList(res?.data);
        analysisDataHandler(res?.data);
      }
      setLoading(false);
    })();
  }, []);

  const loadDashboard = (params = null) => {
    const apiData = {
      type: 'GET',
      endpoint: '/admin/analysis',
    };
    if (params) {
      const queryParams = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&');

      apiData.endpoint = `${apiData.endpoint}?${queryParams}`;
    } else {
      apiData.endpoint = `/admin/analysis`;
    }

    API.sendRequest(apiData, (res) => {
      setAnalysisList(res?.data);
      analysisDataHandler(res);
    });
  };

  useEffect(() => { }, [refresh]);

  const handlerFormSubmission = (event) => {
    event.preventDefault();

    if (!inpStartDate.isValid || !inpEndDate.isValid) {
      return;
    }

    const params = {
      startDate: inpStartDate.value,
      endDate: inpEndDate.value,
    };

    loadDashboard(params);
    // setRefresh(prev => !prev)
  };

  const handlerFormReset = () => {
    inpStartDate.reset();
    inpEndDate.reset();
    loadDashboard();
    // setRefresh(prev => !prev)
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <div className="page-content mt-0 pt-0 scrollSticky">
                  <h4 className="page-title mb-3 font-size-20">Dashboard</h4>

                  <Row>
                    <Col className="col-12 OverFlowScroll">
                      <div
                        className="card p-4"
                        style={{ marginBottom: '25px' }}
                      >
                        <form name="submit" onSubmit={handlerFormSubmission}>
                          <div className="row">
                            <div className="form-group col-12 col-lg-6">
                              <p className="mb-2">
                                <strong>Start Date</strong>
                              </p>
                              <input
                                type="date"
                                className="form-control"
                                id="startDate"
                                placeholder="Enter Start Date"
                                autoComplete="off"
                                onChange={inpStartDate.valueChangeHandler}
                                onBlur={inpStartDate.inputBlurHandler}
                                value={inpStartDate.value}
                              />
                              {inpStartDate.hasError && (
                                <p className="text-danger fs-6">Required</p>
                              )}
                            </div>
                            <div className="form-group col-12 col-lg-6">
                              <p className="mb-2 ">
                                <strong>End Date</strong>
                              </p>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Enter End Date"
                                autoComplete="off"
                                onChange={inpEndDate.valueChangeHandler}
                                onBlur={inpEndDate.inputBlurHandler}
                                value={inpEndDate.value}
                              />
                              {inpEndDate.hasError && (
                                <p className="text-danger fs-6">Required</p>
                              )}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{
                              justifyContent: 'space-between',
                              marginTop: '15px',
                            }}
                          >
                            <button
                              type="submit"
                              className="btn btn-xs btn-primary mr-2"
                              onClick={handlerFormSubmission}
                              title="Submit"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-xs btn-primary "
                              onClick={handlerFormReset}
                              title="Reset"
                            >
                              Reset
                            </button>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row style={{ rowGap: '10px' }}>
                  {cardAnalyticsData.map((data) => {
                    return (
                      <Col key={data.id} sm={4}>
                        <Card>
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div
                                className=" font-size-20 me-3"
                                style={{
                                  height: '3rem',
                                  width: '3rem',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginRight: '20px',
                                }}
                              >
                                <i
                                  className={data.icon}
                                  style={{ fontSize: '35px', color: '#145388' }}
                                />
                              </div>
                              <div className="flex-1">
                                <div className="font-size-16 mt-2 ">
                                  <p
                                    style={{
                                      fontWeight: '700',
                                      color: '#000',
                                      padding: '0',
                                      margin: '0',
                                      fontSize: '20px',
                                    }}
                                  >
                                    {data.value}
                                  </p>

                                  <span style={{ fontSize: '15px' }}>
                                    {data?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default Dashboard;
