/* eslint-disable no-shadow */
import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  addFeedback,
  deleteFeedback,
  editFeedback,
  getAllFeedback,
  getAllLabTest,
  getAllPatient,
} from 'utils/API/api';
import { momentDateFormat } from 'helpers/Utils';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';


const role = localStorage.getItem('role')

const Report = ({ match }) => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackData, setFeedbackData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [editFormFields, setEditFormFields] = useState([]);
  const [data, setData] = useState([]);
  let labTestList = [];
  let patientList = [];

  console.log(formFields);
  console.log(editFormFields, 'edit');

  const confirmDeleteFeedback = async () => {
    if (deleteId) {
      const res = await deleteFeedback(deleteId, data);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditFeedback = (editValue) => {
    if (editValue) {
      setFeedbackData({ ...editValue });
      setEditModalOpen(true);
    }
  };
  const handleDeleteFeedback = (feedbackId) => {
    setDeleteId(feedbackId.id);
    setData(feedbackId.pid);
    setIsConfirmationOpen(true);
  };


  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.REPORT_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });
    if (e.target?.files?.files && e.target?.files?.files[0]) {
      payload.files = e.target?.files?.files[0];
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return formData;
  };
  const editFeedbackHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (feedbackData?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await editFeedback(
          feedbackData?.id,
          feedbackData?.patientId,
          formData
        );
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addFeedbackHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      setLoading(true);
      const res = await addFeedback(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(false);

      const resp = await getAllLabTest();
      if (resp !== -1) {
        labTestList = resp?.data?.data.map((item) => {
          return {
            label: item?.name,
            id: item?.id,
            value: item?.id,
          };
        });
      }
      const response = await getAllPatient();
      if (response !== -1) {
        patientList = response?.data?.data?.data?.rows.map((item) => {
          return {
            label: `${item?.firstName} ${item?.lastName}`,
            id: item?.id,
            value: item?.id,
          };
        });
      }
      setFormFields(() => {
        const tempValue = CONSTANTS.RIGHT_SIDEBAR_FIELD.REPORT_MODAL.forEach(
          (field) => {
            const newObj = field;
            if (newObj.name === 'labTestId') {
              newObj.option = labTestList;
            } else if (newObj.name === 'patientId') {
              newObj.option = patientList;
            }
            return newObj;
          }
        );
        return tempValue;
      });
      setEditFormFields(() => {
        const tempValue = CONSTANTS.RIGHT_SIDEBAR_FIELD.EDIT_REPORT_MODAL.forEach(
          (field) => {
            const newObj = field;
            if (newObj.name === 'labTestId') {
              newObj.option = labTestList;
            } else if (newObj.name === 'patientId') {
              newObj.option = patientList;
            }
            return newObj;
          }
        );
        return tempValue;
      });
      const res = await getAllFeedback();
      if (res !== -1) {
        setFeedbackList(
          res?.data?.data.map((feedbackItem, index) => {
            return {
              no: index + 1,
              ...feedbackItem,
              patientName: `${feedbackItem?.patient?.firstName || '-'} ${feedbackItem?.patient?.lastName || ''}`,
              labTestName: `${feedbackItem?.labTest?.name || '-'}`,
              charges: feedbackItem?.labTest?.charges || 0,
              fileView: {
                buttonLabel: 'View',
                id: { id: feedbackItem?.id, file: feedbackItem?.files },
                onClick: (data) => {
                  window.open(data.file);

                },
              },
              deleteData: {
                buttonLabel: 'Delete',
                id: {
                  id: feedbackItem?.id, pid: feedbackItem?.patientId
                },
                pid: feedbackItem?.patientId,
                onClick: handleDeleteFeedback,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...feedbackItem,
                  profilePic: '',
                  birthDate: momentDateFormat(
                    feedbackItem?.birthDate,
                    'yyyy-MM-DD'
                  ),
                },
                onClick: handleEditFeedback,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addFeedback} />
                  </Button>
                </Container>
                <AddNewModalWithOutButton
                  sidebarMenu="REPORT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.addFeedback}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addFeedbackHandler}
                  formData={{}}
                  formFields={formFields}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="EDIT_REPORT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editFeedback}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editFeedbackHandler}
                  formData={feedbackData}
                  formFields={editFormFields}
                />

                <ViewTable
                  headers={role === 'laboratoryManager' ? CONSTANTS.TABLE_HEADER.LAB_REPORT_LIST : CONSTANTS.TABLE_HEADER.REPORT_LIST}
                  items={feedbackList}
                  advisorId={CONSTANTS.TABLE_ID.feedback}
                  filterParams="patientName"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteFeedback}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default Report;
