import React, { useState } from 'react';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { displayRow, getGenderLabel } from 'helpers/Utils';
import { DISPLAY_TEXT_TYPE } from 'utils/CONSTANTS';
import { Button } from 'reactstrap';
import SelectionModel from 'components/selectionModal';
import { editDoctor } from 'utils/API/api';

const DoctorDetailTab = ({ doctorDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  function refreshPage() {
    window.location.reload(false);
  }

  const doctorName =
    doctorDetails?.firstName || doctorDetails?.lastName
      ? `${doctorDetails?.prefix ? doctorDetails?.prefix : ''} ${
          doctorDetails?.firstName
        } ${doctorDetails?.lastName}`
      : null;
  return (
    <div>
      {displayRow('Name', doctorName)}
      {displayRow('Email', doctorDetails?.email)}
      {displayRow('Mobile', doctorDetails?.mobile)}
      {displayRow('Gender', getGenderLabel(doctorDetails?.gender))}

      {displayRow(
        'Verified',
        doctorDetails?.isVerified,
        DISPLAY_TEXT_TYPE.YES_NO
      )}
      {displayRow('Address 1', doctorDetails?.addressLine1)}
      {displayRow('Address 2', doctorDetails?.addressLine2)}
      {displayRow('Category', doctorDetails?.category)}
      {displayRow('Country', doctorDetails?.country)}
      {displayRow('State', doctorDetails?.state)}
      {displayRow('City', doctorDetails?.city)}
      {displayRow(
        'Doctor Registration Number',
        doctorDetails?.doctorRegistrationNumber
      )}
      {displayRow('Experience', doctorDetails?.experience)}

      {displayRow('Master Education', doctorDetails?.masterEducation)}
      {displayRow('PHD Education', doctorDetails?.phdEducation)}
      {displayRow('Pin code', doctorDetails?.pinCode)}

      {displayRow('Doctor Fee', doctorDetails?.sessionChargePerFiftyMinute)}
      {displayRow('Speciality', doctorDetails?.speciality)}
      {/* {displayRow('language', doctorDetails?.language)} */}
      {/* {displayRow(, doctorDetails?.speciality)} */}
      {doctorDetails?.language != null && (
        <>
          <p className="mb-3">
            Languages : &nbsp;
            {doctorDetails?.language.toString().replaceAll(',', ', ')}
          </p>
          <Button
            outline
            color="primary"
            className="top-right-button"
            onClick={() => setIsOpen(true)}
          >
            Edit Language
          </Button>
        </>
      )}
      <SelectionModel
        isOpen={isOpen}
        toggleModal={() => {
          setIsOpen(false);
        }}
        onSubmit={async (selectedState) => {
          const res = await editDoctor(doctorDetails?.id, {
            language: selectedState
              .map((selectedItem) => selectedItem.value)
              .toString(),
          });
          if (res !== -1) {
            setIsOpen(false);
            refreshPage();
          }
        }}
        dataList={[
          { id: 0, value: 'English', label: 'English' },
          { id: 1, value: 'Gujarati', label: 'Gujarati' },
          { id: 2, value: 'Hindi', label: 'Hindi' },
          { id: 3, value: 'Bengali', label: 'Bengali' },
          { id: 4, value: 'Tamil', label: 'Tamil' },
          { id: 5, value: 'Telugu', label: 'Telugu' },
          { id: 6, value: 'Marathi', label: 'Marathi' },
          { id: 7, value: 'Urdu', label: 'Urdu' },
          { id: 8, value: 'Kannada', label: 'Kannada' },
          { id: 9, value: 'Malayalam', label: 'Malayalam' },
          { id: 10, value: 'Odia', label: 'Odia' },
          { id: 11, value: 'Punjabi', label: 'Punjabi' },
          { id: 12, value: 'Sanskrit', label: 'Sanskrit' },
          { id: 13, value: 'Assamese', label: 'Assamese' },
          { id: 14, value: 'Sindhi', label: 'Sindhi' },
          { id: 15, value: 'Nepali', label: 'Nepali' },
        ].map((item) => {
          return { key: item?.value, value: item?.value };
        })}
        selectedData={doctorDetails?.language.map((item) => {
          return { key: item, value: item };
        })}
      />
    </div>
  );
};

export default DoctorDetailTab;
