import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  deleteDoctorManagementFeedback,
  getAllDoctorManagementFeedback,
} from 'utils/API/api';
import AlertPopup from 'components/alert-popup';

const DoctorManagementFeedback = () => {
  const { id: doctorId } = useParams();
  const [doctorManagementFeedbackList, setDoctorManagementFeedbackList] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteDoctorManagementFeedback = (doctorManagementFeedbackId) => {
    setDeleteId(doctorManagementFeedbackId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteDoctorManagementFeedback = async () => {
    if (deleteId) {
      const res = await deleteDoctorManagementFeedback(deleteId, doctorId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
      const res = await getAllDoctorManagementFeedback(doctorId);
      if (res !== -1) {
        setDoctorManagementFeedbackList(
          res?.data?.data.map((doctorManagementFeedbackItem, index) => {
            return {
              no: index + 1,
              ...doctorManagementFeedbackItem,
              name: `${doctorManagementFeedbackItem?.doctor?.firstName} ${doctorManagementFeedbackItem?.doctor?.lastName}`,
              deleteData: {
                buttonLabel: 'Delete',
                id: doctorManagementFeedbackItem?.id,
                onClick: handleDeleteDoctorManagementFeedback,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      {!loading ? (
        <>
          <ViewTable
            headers={CONSTANTS.TABLE_HEADER.DOCTOR_MANAGEMENT_FEEDBACK_LIST}
            items={doctorManagementFeedbackList}
            advisorId={CONSTANTS.TABLE_ID.doctorManagementFeedback}
            filterParams="name"
          />

          <AlertPopup
            isOpen={isConfirmationOpen}
            positiveText="Yes"
            negativeText="No"
            warning="Are you sure you want to delete?"
            onNegative={() => {
              setIsConfirmationOpen(false);
              setDeleteId(null);
            }}
            onPositive={confirmDeleteDoctorManagementFeedback}
          />
        </>
      ) : (
        <>
          <Container className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              className="d-inline-flex m-2 "
              color="$theme-color-yellow-granola"
            />
          </Container>
        </>
      )}
    </Suspense>
  );
};
export default DoctorManagementFeedback;
