import React, { useEffect, useState } from 'react';
import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  CardHeader,
  Container,
  Spinner,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { Colxx } from 'components/common/CustomBootstrap';
import { injectIntl } from 'react-intl';
import IntlMessages from 'helpers/IntlMessages';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { editDoctor, getDoctorDetail, getAllSpeciality } from 'utils/API/api';
import LableValueCard from 'components/cards/LableValueCard';
import CONSTANTS from 'utils/CONSTANTS';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import DoctorDetailTab from './tabs/doctor-detail-tab';
import DoctorAvailability from './tabs/doctor-availability';
import DoctorPublishedPapers from './tabs/doctor-published-papers';
import DoctorManagementFeedback from './tabs/doctor-management-feedback';
import DoctorVisitPage from './tabs/doctor-visitpage';
import staticimg from '../../../assets/img/blog/small-2.jpg';
import DoctorCertification from './tabs/doctor-certification';

let speciality = [];
const DoctorDetail = () => {
  const { id: doctorId } = useParams();
  const [doctorDetails, setDoctorDetails] = useState({});
  const [activeTab, setActiveTab] = useState('details');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [formFields, setFormFields] = useState([]);

  console.log(doctorDetails);

  const doctorName =
    doctorDetails?.firstName || doctorDetails?.lastName
      ? `${doctorDetails?.prefix ? doctorDetails?.prefix : ''} ${doctorDetails?.firstName
      } ${doctorDetails?.lastName}`
      : null;

  // console.log(doctorDetails);

  const getActiveTab = (tab) => {
    switch (tab) {
      case 'details':
        return <DoctorDetailTab doctorDetails={doctorDetails} />;
      case 'availability':
        return <DoctorAvailability />;
      case 'publishedPapers':
        return <DoctorPublishedPapers />;
      case 'managementFeedback':
        return <DoctorManagementFeedback />;
      case 'visitPage':
        return <DoctorVisitPage />;
      case 'certificationPage':
        return <DoctorCertification />;
      default:
        return <DoctorDetailTab doctorDetails={doctorDetails} />;
    }
  };

  const prepareFormData = (e) => {
    const payload = {};
    // console.log('inside formdata');
    CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_EDIT_MODAL.forEach((field) => {
      console.log(e.target[field.name]?.value);
      // console.log(e.target[field.name]);
      // console.log(field.name, 'temp VAlue', e.target[field.name]?.value);
      if (field.type !== 'file' && e.target[field.name]?.value) {
        const tempValue = e.target[field.name].value;

        if (tempValue) {
          payload[field.name] = tempValue;
        }

      }
      if (field.name === 'isVerified') {
        payload[field.name] = e.target[field.name].checked;
      }
    });

    if (e.target?.profilePic?.files && e.target?.profilePic?.files[0]) {
      payload.profilePic = e.target?.profilePic?.files[0];
    }

    if (e.target?.aadharCard?.files && e.target?.aadharCard?.files[0]) {
      payload.aadharCard = e.target?.aadharCard?.files[0];
    }

    // if (payload?.gender) {
    //   payload.gender = GENDER[payload.gender];
    // }
    console.log(payload);
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    return formData;
  };

  const editDoctorHandler = (e) => {
    e.preventDefault();
    // console.log('edit doc');
    (async () => {
      if (doctorDetails?.id) {


        const payload = prepareFormData(e)


        // console.log(payload);
        // const formData = new FormData();

        // formData.append('password', e.target.password.value)

        setLoading(true);
        const res = await editDoctor(doctorDetails?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(false);
          setUpdatePassword(false);
        }
        setLoading(false);
      }
    })();
  };
  const editPasswordHandler = (e) => {
    e.preventDefault();
    console.log('edit doc');
    console.log(e.target.password.value);
    (async () => {
      if (doctorDetails?.id) {

        const payload = new FormData();

        payload.append('password', e.target.password.value)


        // console.log(payload);
        // const formData = new FormData();

        // formData.append('password', e.target.password.value)

        setLoading(true);
        const res = await editDoctor(doctorDetails?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(false);
          setUpdatePassword(false);
        }
        setLoading(false);
      }
    })();
  };

  const handleChange = (e) => {
    (async () => {
      const payload = new FormData();

      if (e.target?.files[0]) {
        payload.append('profilePic', e.target.files[0]);
        const res = await editDoctor(doctorDetails?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
        }
      }

      if (e.target?.files[0] === null) {
        payload.append('profilePic', staticimg);
        const res = await editDoctor(doctorDetails?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
        }
      }
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const respo = await getAllSpeciality();
      if (respo !== -1) {
        speciality = respo?.data?.data.map((item) => {
          return {
            label: item?.name,
            id: item?.name,
            value: item?.name,
          };
        });
        // setSpecialityList(
        //   respo?.data?.data.map((item) => {
        //     return {
        //       label: item?.name,
        //       id: item?.id,
        //       value: item?.id,
        //     };
        //   })
        // );
      }
      setFormFields(() => {
        const tempValue =
          CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_EDIT_MODAL.forEach((field) => {
            const newObj = field;
            if (newObj.name === 'speciality') {
              newObj.option = speciality;
            }
            return newObj;
          });
        return tempValue;
      });
      const res = await getDoctorDetail(doctorId);
      if (res !== -1) {
        setDoctorDetails(res?.data?.data?.data);

      }

      setLoading(false);
    })();
  }, [doctorId, refresh]);
  return (
    <>
      {doctorName && !loading ? <Row>
        <Colxx lg="12" md="6" xl="6">
          <Row>
            <Colxx lg="6" xl="12" className="mb-4">

              <LableValueCard
                title={doctorName}
                value={doctorDetails?.email}
                logo={doctorDetails?.profilePic}
                btn={handleChange}
              />

            </Colxx>
          </Row>
        </Colxx>
        <Colxx lg="12" md="6" xl="6">
          <Button
            outline
            color="primary"
            className="top-right-button"
            onClick={() => setUpdatePassword((previous) => !previous)}
          >
            <IntlMessages id="survey.add-update-password" />
          </Button>
        </Colxx>
      </Row> : <Container className="d-flex justify-content-center align-items-center">
        <Spinner
          animation="border"
          className="d-inline-flex m-2 "
          color="$theme-color-yellow-granola"
        />
      </Container>}
      <Row>
        {doctorName ? (
          <Colxx xxs="12">
            <Row>
              <Colxx xxs="12" xl="12" className="col-left">
                <Card className="mb-4">
                  <CardHeader>
                    <Nav tabs className="card-header-tabs">
                      {CONSTANTS.DOCTOR_TABS.map((data) => (
                        <NavItem key={data.id}>
                          <Card
                            className={classnames({
                              active: activeTab === data.tabId,
                              'nav-link': true,
                            })}
                            onClick={() => setActiveTab(data.tabId)}
                          >
                            <IntlMessages id={data.Label} />
                          </Card>
                        </NavItem>
                      ))}
                    </Nav>
                  </CardHeader>

                  <TabContent activeTab={activeTab}>
                    {CONSTANTS.DOCTOR_TABS.map((tab) => (
                      <TabPane tabId={tab.tabId} key={tab.tabId}>
                        <Row>
                          <Colxx sm="12">
                            <CardBody>{getActiveTab(tab.tabId)}</CardBody>
                          </Colxx>
                        </Row>
                      </TabPane>
                    ))}
                  </TabContent>
                  <Button
                    outline
                    color="primary"
                    style={{
                      marginLeft: 'auto',
                      marginBottom: '14px',
                      position: 'absolute',
                      right: '15px',
                      top: '-50px',
                      zIndex: '100',
                    }}
                    className="top-right-button"
                    onClick={() => setEditModalOpen((previous) => !previous)}
                  >
                    <IntlMessages id="survey.add-new-edit" />
                  </Button>
                  {doctorDetails?.aadharCard ? (
                    <a
                      href={doctorDetails?.aadharCard}
                      download="AdharCard"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        outline
                        color="primary"
                        style={{
                          marginLeft: 'auto',
                          marginBottom: '14px',
                          position: 'absolute',
                          right: '130px',
                          top: '-50px',
                          zIndex: '100',
                        }}
                        className="top-right-button"
                      >
                        <i
                          className="simple-icon-cloud-download"
                          style={{ marginRight: '8px' }}
                        />
                        <IntlMessages id="survey.add-new-download" />
                      </Button>
                    </a>
                  ) : (
                    ''
                  )}
                  <AddNewModalWithOutButton
                    sidebarMenu="DOCTOR_EDIT_MODAL"
                    modalTitle={CONSTANTS.TABLE_ID.editDoctor}
                    modalOpen={editModalOpen}
                    toggleModal={() => setEditModalOpen(!editModalOpen)}
                    onSubmit={editDoctorHandler}
                    formData={doctorDetails}
                    formFields={formFields}
                  />
                </Card>
              </Colxx>
            </Row>
          </Colxx>
        ) : null}
      </Row>

      <AddNewModalWithOutButton
        sidebarMenu="DOCTOR_EDIT_PASSWORD_MODAL"
        modalTitle={CONSTANTS.TABLE_ID.editDoctorUpdatePassword}
        modalOpen={updatePassword}
        toggleModal={() => setUpdatePassword((prev) => !prev)}
        onSubmit={editPasswordHandler}
        formData={{}}
      />
    </>
  );
};
export default injectIntl(DoctorDetail);
