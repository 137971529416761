import React from 'react';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { displayRow, getGenderPatientLabel } from 'helpers/Utils';
import { DISPLAY_TEXT_TYPE } from 'utils/CONSTANTS';

const PatientDetailTab = ({ patientDetails }) => {
  return (
    <div>
      {displayRow(
        'Name',
        `${patientDetails?.firstName} ${patientDetails?.lastName}`
      )}
      {displayRow('Email', patientDetails?.email)}
      {displayRow('Mobile', patientDetails?.mobile)}
      {displayRow('Gender', getGenderPatientLabel(patientDetails?.gender))}

      {displayRow(
        'Email Verified',
        patientDetails?.isEmailVerified,
        DISPLAY_TEXT_TYPE.YES_NO
      )}
      {displayRow(
        'Mobile Verified',
        patientDetails?.isMobileVerified,
        DISPLAY_TEXT_TYPE.YES_NO
      )}
      {displayRow(
        'Premium Membership',
        patientDetails?.isPremiumMembership,
        DISPLAY_TEXT_TYPE.YES_NO
      )}
    </div>
  );
};

export default PatientDetailTab;
