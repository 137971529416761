import React, { useEffect, useState } from 'react';
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import { Colxx } from 'components/common/CustomBootstrap';
// import { getAllLoginData } from 'utils/API/api';
import useHttp from 'components/Hook/Use-http';
import { displayRow } from 'helpers/Utils';
import CONSTANTS from 'utils/CONSTANTS';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import { editAppSetting, editLoginData, getAllAppSetting } from 'utils/API/api';
import IntlMessages from 'helpers/IntlMessages';
import { NotificationManager } from 'components/common/react-notifications';
// import CONSTANTS from 'utils/CONSTANTS';

const SettingPage = () => {
  //   const { id: doctorId } = useParams();
  const API = useHttp();
  const [chemistDetails, setChemistDetails] = useState({});
  //   const [activeTab, setActiveTab] = useState('details');
  const [editModalOpen, setEditModalOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [updateLabPassword, setUpdateLabPassword] = useState(false)
  const [formData, setFormData] = useState({});
  const [appData, setAppData] = useState({});
  const [labMangerDetails, setLabMangerDetails] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [chemsitModal, setChemsitModal] = useState(false)
  const [labMangerModal, setLabMangerModal] = useState(false)

  //   const [formFields, setFormFields] = useState([]);

  // console.log(chemistDetails, 'ch');
  // console.log(labMangerDetails, 'lab manager');



  // const prepareFormData = (e) => {
  //   const payload = {};
  //   CONSTANTS.RIGHT_SIDEBAR_FIELD.CEMISTDATA_EDIT_MODAL.forEach((field) => {
  //     const tempValue = e.target[field.name].value;

  //     if (tempValue) {
  //       payload[field.name] = tempValue;
  //     }
  //   });

  //   // const formData = new FormData();
  //   // Object.keys(payload).forEach((key) => {
  //   //   formData.append(key, payload[key]);
  //   // });
  //   return payload;
  // };

  const addChemistDataHandler = (e) => {
    e.preventDefault()

    const payload = {
      name: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      role: 'chemist',
    }
    const ADD_CHEMIST_DATA_API = { ...CONSTANTS.API.addRole }
    API.sendRequest(ADD_CHEMIST_DATA_API, () => {
      setChemsitModal(false);
      setRefresh(prev => !prev)
    }, payload)
  }

  const addLabManagerHandler = (e) => {
    e.preventDefault()

    const payload = {
      name: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      role: 'laboratoryManager',
    }
    const ADD_LAB_MANAGER_DATA_API = { ...CONSTANTS.API.addRole }

    API.sendRequest(ADD_LAB_MANAGER_DATA_API, () => {
      setLabMangerModal(false);
      setRefresh(prev => !prev)
    }, payload)
  }

  const editChemistDataHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (chemistDetails[0]?.id) {
        // const formDataa = prepareFormData(e);
        const payload = {
          name: e.target.name.value,
          email: e.target.email.value,
        }

        console.log(payload);

        const res = await editLoginData(chemistDetails[0]?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen((prev) => !prev)
          //   setUpdatePassword(false);
        }
        // setLoading(false);
      }
    })();
  }

  const editChemistPasswordHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (chemistDetails[0]?.id) {
        // const formDataa = prepareFormData(e);
        const payload = {
          password: e.target.password.value
        }

        const res = await editLoginData(chemistDetails[0]?.id, payload);
        if (res !== -1) {
          setUpdatePassword((prev) => !prev)
          setRefresh((previous) => !previous);
          //   setUpdatePassword(false);
        }
        // setLoading(false);
      }
    })();
  };

  const editLabManagerDataHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (labMangerDetails[0]?.id) {
        // const formDataa = prepareFormData(e);
        const payload = {
          name: e.target.name.value,
          email: e.target.email.value,
        }

        const res = await editLoginData(labMangerDetails[0]?.id, payload);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setIsModalOpen((prev) => !prev)
          //   setUpdatePassword(false);
        }
        // setLoading(false);
      }
    })();
  };

  const editLabManagerPasswordHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (labMangerDetails[0]?.id) {
        // const formDataa = prepareFormData(e);
        const payload = {
          password: e.target.password.value
        }

        const res = await editLoginData(labMangerDetails[0]?.id, payload);
        if (res !== -1) {
          setUpdateLabPassword((prev) => !prev)
          setRefresh((previous) => !previous);
          //   setUpdatePassword(false);
        }
        // setLoading(false);
      }
    })();
  };

  //   const handleChange = (e) => {
  //     (async () => {
  //       const payload = new FormData();

  //       if (e.target?.files[0]) {
  //         payload.append('profilePic', e.target.files[0]);
  //         const res = await editDoctor(doctorDetails?.id, payload);
  //         if (res !== -1) {
  //           setRefresh((previous) => !previous);
  //         }
  //       }

  //       if (e.target?.files[0] === null) {
  //         payload.append('profilePic', staticimg);
  //         const res = await editDoctor(doctorDetails?.id, payload);
  //         if (res !== -1) {
  //           setRefresh((previous) => !previous);
  //         }
  //       }
  //     })();
  //   };

  const handleAppSubmit = (event) => {
    event.preventDefault();
    // Perform any actions with the form data here

    if (formData) {
      (async () => {
        if (appData?.id) {
          //   const formDataa = prepareFormData(e);

          // setLoading(true);
          const res = await editAppSetting(appData?.id, formData);
          if (res !== -1) {
            setRefresh((previous) => !previous);
            setFormData({});
            NotificationManager.success(
              'Application Setting Updated Successfully!',
              'Success',
              3000,
              null,
              ''
            );
            // setEditModalOpen(false);
            //   setUpdatePassword(false);
          }
          // setLoading(false);
        }
      })();
    }
  };

  useEffect(() => {
    (async () => {
      const ress = await getAllAppSetting();

      if (ress !== -1) {
        setAppData(ress?.data?.data?.rows[0]);
      }

      API.sendRequest(
        {
          type: 'GET',
          endpoint: '/admin/getAll?role=chemist',
        },
        (res) => setChemistDetails(res?.data)
      );
    })();

    API.sendRequest({
      type: 'GET',
      endpoint: '/admin/getAll?role=laboratoryManager',
    }, (res) => setLabMangerDetails(res?.data))
  }, [refresh]);
  return (
    <>
      <Colxx xxs="12">
        <Row>
          <Colxx xxs="12" xl="12" className="col-left">
            <Card className="mb-4">
              <CardHeader
                style={{
                  fontSize: '30px',
                  marginTop: '35px',
                  marginLeft: '20px',
                }}
              >
                Chemist Account Info
              </CardHeader>
              <Row>
                <Colxx sm="12">
                  <CardBody>
                    <div>
                      {displayRow('Name', chemistDetails[0]?.name)}
                      {displayRow('Email', chemistDetails[0]?.email)}
                      {/* {displayRow('Password', chemistDetails[0]?.password)} */}
                    </div>
                    <div>
                      {chemistDetails.length > 0 ? <Button
                        outline
                        color="primary"
                        className="top-right-button"
                        onClick={() => setEditModalOpen(true)}
                      >
                        Edit Info
                      </Button> : <Button
                        outline
                        color="primary"
                        className="top-right-button"
                        onClick={() => setChemsitModal(true)}
                      >
                        Add Info
                      </Button>}
                      {chemistDetails.length > 0 ?
                        <Button
                          outline
                          color="primary"
                          style={{
                            marginLeft: 'auto',
                            marginBottom: '14px',
                            position: 'absolute',
                            right: '20px',
                            top: '-50px',
                            zIndex: '100',
                          }}
                          className="top-right-button"
                          onClick={() =>
                            setUpdatePassword((previous) => !previous)
                          }
                        >
                          <IntlMessages id="survey.add-update-password" />
                        </Button> : <></>}


                    </div>
                  </CardBody>
                </Colxx>
              </Row>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" xl="12" className="col-left">
            <Card className="mb-4">
              <CardHeader
                style={{
                  fontSize: '30px',
                  marginTop: '35px',
                  marginLeft: '20px',
                }}
              >
                Laboratery Manger Account Info
              </CardHeader>
              <Row>
                <Colxx sm="12">
                  <CardBody>
                    <div>
                      {displayRow('Name', labMangerDetails[0]?.name)}
                      {displayRow('Email', labMangerDetails[0]?.email)}
                      {/* {displayRow('Password', chemistDetails[0]?.password)} */}
                    </div>
                    <div>

                      {labMangerDetails.length > 0 ? <Button
                        outline
                        color="primary"
                        className="top-right-button"
                        onClick={() => setIsModalOpen(prev => !prev)}
                      >
                        Edit Info
                      </Button> : <Button
                        outline
                        color="primary"
                        className="top-right-button"
                        onClick={() => setLabMangerModal(true)}
                      >
                        Add Info
                      </Button>}
                      {labMangerDetails.length > 0 ?
                        <Button
                          outline
                          color="primary"
                          style={{
                            marginLeft: 'auto',
                            marginBottom: '14px',
                            position: 'absolute',
                            right: '20px',
                            top: '-50px',
                            zIndex: '100',
                          }}
                          className="top-right-button"
                          onClick={() =>
                            setUpdateLabPassword((previous) => !previous)
                          }
                        >
                          <IntlMessages id="survey.add-update-password" />
                        </Button> : <></>}

                    </div>
                  </CardBody>
                </Colxx>
              </Row>
            </Card>
          </Colxx>
        </Row>
      </Colxx>

      <Colxx xxs="12">
        <Row>
          <Colxx xxs="12" xl="12" className="col-left">
            <Card className="mb-4">
              <CardHeader
                style={{
                  fontSize: '30px',
                  marginTop: '35px',
                  marginLeft: '20px',
                }}
              >
                Application Settings Info
              </CardHeader>
              <Row>
                <Colxx sm="12">
                  <CardBody>
                    <div>
                      <Form onSubmit={handleAppSubmit}>
                        <FormGroup>
                          <Label for="userAndroidVersion">
                            User Android Version
                          </Label>
                          <Input
                            type="text"
                            id="userAppAndroidVersionCode"
                            defaultValue={appData?.userAppAndroidVersionCode}
                            onChange={
                              (e) =>
                                setFormData((prevState) => ({
                                  ...prevState,
                                  [e.target.id]: e.target.value,
                                }))
                              //   setUserAndroidVersion(e.target.value)
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="userIosVersion">User iOS Version</Label>
                          <Input
                            type="text"
                            id="userAppIosVersionCode"
                            defaultValue={appData?.userAppIosVersionCode}
                            onChange={
                              (e) =>
                                setFormData((prevState) => ({
                                  ...prevState,
                                  [e.target.id]: e.target.value,
                                }))
                              // setUserIosVersion(e.target.value)
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="doctorAndroidVersion">
                            Doctor Android Version
                          </Label>
                          <Input
                            type="text"
                            id="doctorAppAndroidVersionCode"
                            defaultValue={appData?.doctorAppAndroidVersionCode}
                            onChange={
                              (e) =>
                                setFormData((prevState) => ({
                                  ...prevState,
                                  [e.target.id]: e.target.value,
                                }))
                              //   setDoctorAndroidVersion(e.target.value)
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="doctorIosVersion">
                            Doctor iOS Version
                          </Label>
                          <Input
                            type="text"
                            id="doctorAppIosVersionCode"
                            defaultValue={appData?.doctorAppIosVersionCode}
                            onChange={
                              (e) =>
                                setFormData((prevState) => ({
                                  ...prevState,
                                  [e.target.id]: e.target.value,
                                }))
                              //   setDoctorIosVersion(e.target.value)
                            }
                          />
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              id="userAppInMaintenance"
                              //   defaultChecked={appData?.userAppInMaintenance}
                              checked={appData?.userAppInMaintenance}
                              onChange={
                                (e) => {
                                  setAppData((prev) => ({
                                    ...prev,
                                    [e.target.id]: e.target.checked,
                                  }));
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    [e.target.id]: e.target.checked,
                                  }));
                                }
                                // setUserAppMaintenance(!userAppMaintenance)
                              }
                            />{' '}
                            User App under maintenance
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              id="doctorAppInMaintenance"
                              //   defaultChecked={appData?.doctorAppInMaintenance}
                              checked={appData?.doctorAppInMaintenance}
                              onChange={
                                (e) => {
                                  setAppData((prev) => ({
                                    ...prev,
                                    [e.target.id]: e.target.checked,
                                  }));
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    [e.target.id]: e.target.checked,
                                  }));
                                }
                                // setDoctorAppMaintenance(!doctorAppMaintenance)
                              }
                            />{' '}
                            Doctor App under maintenance
                          </Label>
                        </FormGroup>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form>
                      {/* {displayRow('Password', chemistDetails[0]?.password)} */}
                    </div>
                  </CardBody>
                </Colxx>
              </Row>
            </Card>
          </Colxx>
        </Row>
      </Colxx>

      <AddNewModalWithOutButton
        modalAlign="center"
        sidebarMenu="CEMISTDATA_MODAL"
        modalTitle={CONSTANTS.TABLE_ID.addChemistData}
        modalOpen={chemsitModal}
        toggleModal={() => setChemsitModal(prev => !prev)}
        onSubmit={addChemistDataHandler}
        formData={{}}
      // formFields={formFields}
      />
      <AddNewModalWithOutButton
        modalAlign="center"
        sidebarMenu="CEMISTDATA_EDIT_MODAL"
        modalTitle={CONSTANTS.TABLE_ID.editChemistData}
        modalOpen={editModalOpen}
        toggleModal={() => setEditModalOpen(!editModalOpen)}
        onSubmit={editChemistDataHandler}
        formData={chemistDetails[0]}
      // formFields={formFields}
      />
      <AddNewModalWithOutButton
        modalAlign="center"
        sidebarMenu="CEMISTDATA_MODAL"
        modalTitle={CONSTANTS.TABLE_ID.addLabManagerData}
        modalOpen={labMangerModal}
        toggleModal={() => setLabMangerModal(prev => !prev)}
        onSubmit={addLabManagerHandler}
        formData={{}}
      // formFields={formFields}
      />
      <AddNewModalWithOutButton
        modalAlign="center"
        sidebarMenu="CEMISTDATA_EDIT_MODAL"
        modalTitle={CONSTANTS.TABLE_ID.editLLabManagerData}
        modalOpen={isModalOpen}
        toggleModal={() => setIsModalOpen(prev => !prev)}
        onSubmit={editLabManagerDataHandler}
        formData={labMangerDetails[0]}
      // formFields={formFields}
      />
      <AddNewModalWithOutButton
        sidebarMenu="DOCTOR_EDIT_PASSWORD_MODAL"
        modalTitle={CONSTANTS.TABLE_ID.editChemistUpdatePassword}
        modalOpen={updatePassword}
        toggleModal={() => setUpdatePassword((prev) => !prev)}
        onSubmit={editChemistPasswordHandler}
        formData={{}}
      />
      <AddNewModalWithOutButton
        sidebarMenu="DOCTOR_EDIT_PASSWORD_MODAL"
        modalTitle={CONSTANTS.TABLE_ID.editLabManagerUpdatePassword}
        modalOpen={updateLabPassword}
        toggleModal={() => setUpdateLabPassword((prev) => !prev)}
        onSubmit={editLabManagerPasswordHandler}
        formData={{}}
      />
    </>
  );
};
export default SettingPage;
