import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
    addMedicineOrder,
    deleteMedicineOrder,
    editMedicineOrder,
    getAllMedicine,
    getAllOrder,
    getOneOrder,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const UserMedicineOrder = ({ match }) => {
    const [medicineOrderList, setMedicineOrderList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [medicineOrderData, setMedicineOrderData] = useState({});
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [formFields, setFormFields] = useState([]);

    const handleDeleteMedicineOrder = (clientId) => {
        setDeleteId(clientId);
        setIsConfirmationOpen(true);
    };
    const { id } = useParams()

    const confirmDeleteMedicineOrder = async () => {
        if (deleteId) {
            const res = await deleteMedicineOrder(deleteId);
            if (res !== -1) {
                setRefresh((previous) => !previous);
                setIsConfirmationOpen(false);
            }
        }
    };

    const handleEditMedicineOrder = (editValue) => {
        if (editValue) {
            setMedicineOrderData({ ...editValue });
            setEditModalOpen(true);
        }
    };

    const MEDICINE_ORDER_OPTIONS = [
        { label: 'Pending', value: 'pending' },
        { label: 'Accepted', value: 'accepted' },
        { label: 'Dispatch', value: 'dispatch' },
        { label: 'Delivered', value: 'delivered' },
    ];


    const prepareFormData = (e) => {
        const payload = {};
        CONSTANTS.RIGHT_SIDEBAR_FIELD.MEDICINE_ORDER_MODAL.forEach((field) => {
            const tempValue = e.target[field.name].value;
            if (tempValue) {
                payload[field.name] = tempValue;
            }
        });

        return payload;
    };

    const editMedicineOrderHandler = (e) => {
        e.preventDefault();
        (async () => {
            if (medicineOrderData?.id) {
                const formData = prepareFormData(e);

                setLoading(true);
                const res = await editMedicineOrder(medicineOrderData?.id, formData);
                if (res !== -1) {
                    setRefresh((previous) => !previous);
                    setEditModalOpen(!editModalOpen);
                }
                setLoading(false);
            }
        })();
    };

    const addMedicineOrderHandler = (e) => {
        e.preventDefault();
        (async () => {
            const formData = prepareFormData(e);

            setLoading(true);
            const res = await addMedicineOrder(formData);
            if (res !== -1) {
                setRefresh((previous) => !previous);
                setModalOpen(!modalOpen);
            }
            setLoading(false);
        })();
    };
    useEffect(() => {
        (async () => {
            setLoading(true);
            let orderList = [];
            let medicineList = [];
            const resp = await getAllOrder();
            if (resp !== -1) {
                orderList = resp?.data?.data.map((item) => {
                    return {
                        label: `${item?.patient?.firstName} ${item?.patient?.lastName} - ${item?.id}`,
                        value: item?.id,
                    };
                });
            }
            const response = await getAllMedicine();
            if (response !== -1) {
                medicineList = response?.data?.data.map((item) => {
                    return {
                        label: item?.name,
                        value: item?.id,
                    };
                });
            }
            setFormFields(() => {
                const tempValue =
                    CONSTANTS.RIGHT_SIDEBAR_FIELD.MEDICINE_ORDER_MODAL.forEach(
                        (field) => {
                            const newObj = field;
                            if (newObj.name === 'status') {
                                newObj.option = MEDICINE_ORDER_OPTIONS;
                            } else if (newObj.name === 'medicineId') {
                                newObj.option = medicineList;
                            } else if (newObj.name === 'orderId') {
                                newObj.option = orderList;
                            }
                            return newObj;
                        }
                    );
                return tempValue;
            });
            const res = await getOneOrder(id);
            if (res !== -1) {
                console.log(res?.data?.data?.medicineOrders, 'resfaldjf');
                setMedicineOrderList(
                    res?.data?.data?.medicineOrders?.map((medicineOrderItem, index) => {
                        return {
                            no: index + 1,
                            ...medicineOrderItem,
                            medicineName: medicineOrderItem?.medicine?.name || '',
                            deleteData: {
                                buttonLabel: 'Delete',
                                id: medicineOrderItem?.id,
                                onClick: handleDeleteMedicineOrder,
                            },
                            strength: medicineOrderItem?.strength[0] || '-',
                            editData: {
                                buttonLabel: 'Edit',
                                id: {
                                    ...medicineOrderItem,
                                    profilePic: '',
                                },
                                onClick: handleEditMedicineOrder,
                            },
                        };
                    })
                );
            }
            setLoading(false);
        })();
    }, [refresh]);

    return (
        <Suspense fallback={<div className="loading" />}>
            <Switch>
                <Route
                    path={`${match.url}`}
                    exact
                    render={() =>
                        !loading ? (
                            <>
                                <Container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        maxWidth: '100%',
                                        margin: '0px',
                                        padding: '0',
                                        marginTop: '-10px',
                                        marginBottom: '15px',
                                        marginLeft: '-27px',
                                    }}
                                >
                                    <Button
                                        outline
                                        color="primary"
                                        className="top-right-button"
                                        onClick={() => setModalOpen(true)}
                                    >
                                        <IntlMessages id={CONSTANTS.TABLE_ID.addMedicineOrder} />
                                    </Button>
                                </Container>
                                <AddNewModalWithOutButton
                                    sidebarMenu="MEDICINE_ORDER_MODAL"
                                    dataId={CONSTANTS.TABLE_ID.add}
                                    modalTitle={CONSTANTS.TABLE_ID.addMedicineOrder}
                                    modalOpen={modalOpen}
                                    toggleModal={() => setModalOpen(!modalOpen)}
                                    onSubmit={addMedicineOrderHandler}
                                    formData={{}}
                                    formFields={formFields}
                                />

                                <AddNewModalWithOutButton
                                    sidebarMenu="MEDICINE_ORDER_MODAL"
                                    modalTitle={CONSTANTS.TABLE_ID.editMedicineOrder}
                                    modalOpen={editModalOpen}
                                    toggleModal={() => setEditModalOpen(!editModalOpen)}
                                    onSubmit={editMedicineOrderHandler}
                                    formData={medicineOrderData}
                                    formFields={formFields}
                                />

                                <ViewTable
                                    headers={CONSTANTS.TABLE_HEADER.MEDICINE_ORDER_LIST}
                                    items={medicineOrderList}
                                    advisorId={CONSTANTS.TABLE_ID.medicineOrder}
                                    filterParams="medicineName"
                                />

                                <AlertPopup
                                    isOpen={isConfirmationOpen}
                                    positiveText="Yes"
                                    negativeText="No"
                                    warning="Are you sure you want to delete?"
                                    onNegative={() => {
                                        setIsConfirmationOpen(false);
                                        setDeleteId(null);
                                    }}
                                    onPositive={confirmDeleteMedicineOrder}
                                />
                            </>
                        ) : (
                            <>
                                <Container className="d-flex justify-content-center align-items-center">
                                    <Spinner
                                        animation="border"
                                        className="d-inline-flex m-2 "
                                        color="$theme-color-yellow-granola"
                                    />
                                </Container>
                            </>
                        )
                    }
                />
                <Redirect to="/error" />
            </Switch>
        </Suspense>
    );
};

export default UserMedicineOrder