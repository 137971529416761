/* eslint-disable no-unused-vars */
import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner, Button } from 'reactstrap';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
// import PDF from 'react-pdf-scroll';
// import PSPDFKit from 'pspdfkit';
// import { getAllLabTest } from 'utils/API/api';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import AlertPopup from 'components/alert-popup';
import useHttp from 'components/Hook/Use-http';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import { useParams } from 'react-router-dom';
import IntlMessages from 'helpers/IntlMessages';
import { getAllLabTest, getAllPatientReportsviaId } from 'utils/API/api';
import { Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
// import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';





// const PatientReport = () => {
//   // const [modalOpen, setModalOpen] = useState(false);
//   const API = useHttp();
//   const [patientReportData, setPatientReportData] = useState(null);

//   const { id: patientId } = useParams();
//   const [patientReport, setPatientReport] = useState([]);
//   //  const [loading, setLoading] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);
//   const [refresh, setRefresh] = useState(true);
//   const [formFields, setFormFields] = useState([]);
//   const [editFormFields, setEditFormFields] = useState([]);
//   // let Labtest = [];
//   let labTestList = []
//   // console.log(formFields);
//   // console.log(editFormFields, 'ed');

//   const handleDeleteDoctorPublishedPapers = (doctorPublishedPapersId) => {
//     setDeleteId(doctorPublishedPapersId);
//   };

//   const confirmDeletePatientReport = () => {
//     const APIpoint = { ...CONSTANTS.API.deleteReport };
//     APIpoint.endpoint = APIpoint.endpoint.replace(
//       ':id',
//       `${deleteId}?patientId=${patientId}`
//     );

//     API.sendRequest(APIpoint, () => {
//       setPatientReport((previous) =>
//         previous.filter((Patient) => Patient.id !== deleteId)
//       );
//       setDeleteId(null);
//       setRefresh((prev) => !prev);
//     });
//   };

//   const handleEditPatientReport = (editValue) => {
//     setPatientReportData(editValue);
//   };

//   const getAllReportHandler = (res) => {
//     // const result = res?.data.filter((el) => el.patientId === Number(patientId));
//     // console.log(result, '2');

//     setPatientReport(
//       res?.data.map((report, index) => {
//         return {
//           no: index + 1,
//           ...report.labTest,
//           files: report.files,
//           deleteData: {
//             buttonLabel: 'Delete',
//             id: report?.id,
//             onClick: handleDeleteDoctorPublishedPapers,
//           },
//           fileView: {
//             buttonLabel: 'View',
//             id: { id: report?.id, file: report?.files },
//             onClick: (data) => {
//               window.open(data.file);

//             },
//           },
//           editData: {
//             buttonLabel: 'Edit',
//             id: {
//               ...report,
//             },
//             onClick: handleEditPatientReport,
//           },
//         };
//       })
//     );
//   };


//   // console.log(formFields, 'adsf');
//   useEffect(() => {
//     (async () => {
//       //  setLoading(true);
//       const resp = await getAllLabTest();
//       if (resp !== -1) {
//         // console.log(resp?.data?.data, 'res');
//         labTestList = resp?.data?.data.map((item) => {
//           return {
//             label: item?.name,
//             id: item?.id,
//             value: item?.id,
//           };
//         });
//       }
//       // console.log(labTestList, 'list');

//       setFormFields(() => {
//         const tempValue = CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_REPORT_MODAL.forEach(
//           (field) => {
//             const newObj = field;
//             // console.log(labTestList, 'fpr,');
//             console.log(newObj, 'fpr,');
//             if (newObj.name === 'labTestId') {
//               newObj.option = labTestList;
//             }
//             console.log(newObj, 'afr,');
//             return newObj;
//           }
//         );
//         // console.log(tempValue, 'fp');
//         return tempValue;
//       });
//       setEditFormFields(() => {
//         const tempValue = CONSTANTS.RIGHT_SIDEBAR_FIELD.EDIT_PATIENT_REPORT_MODAL.forEach(
//           (field) => {
//             const newObj = field;
//             // console.log(labTestList, 'edit,');

//             if (newObj.name === 'labTestId') {
//               newObj.option = labTestList;
//             }
//             return newObj;
//           }
//         );
//         return tempValue;
//       });
//     })();
//     CONSTANTS.API.getAllReportById.endpoint =
//       CONSTANTS.API.getAllReportById.endpoint.replace(':id', `${patientId}`);
//     API.sendRequest(CONSTANTS.API.getAllReportById, getAllReportHandler);
//     // API.sendRequest(CONSTANTS.API.getAllReport, getAllReportHandler);
//     // setLoading(false);
//   }, [refresh]);

//   const EditpationReportHandler = (e) => {
//     e.preventDefault();

//     const payload = new FormData();
//     CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_REPORT_MODAL.map((field) => {
//       payload.append(field.id, e.target[field.id].value);
//       return 0;
//     });
//     CONSTANTS.API.updatereport.endpoint =
//       CONSTANTS.API.updatereport.endpoint.replace(
//         ':id',
//         `${patientReportData?.id}?patientId=${patientId}`
//       );

//     API.sendRequest(
//       CONSTANTS.API.updatereport,
//       () => setRefresh((previous) => !previous),
//       payload
//     );
//     setPatientReportData(null);
//   };
//   const AddpationReportHandler = (e) => {
//     e.preventDefault();
//     const payload = new FormData();
//     CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_REPORT_MODAL.map((field) => {
//       payload.append(field.id, e.target[field.id].value);
//       console.log(field.id, e.target[field.id].value);
//       return 0;
//     });
//     const photo = document.querySelector('#files').files[0];
//     payload.append('files', photo);
//     payload.append('patientId', patientId);
//     API.sendRequest(
//       CONSTANTS.API.addReport,
//       () => setRefresh((previous) => !previous),
//       payload
//     );
//     setPatientReportData(null);
//     // setPatientReportData(patientReportData === true);
//   };

//   return (
//     <Suspense fallback={<div className="loading" />}>
//       {!API.isLoading ? (
//         <>
//           <Container
//             style={{
//               display: 'flex',
//               justifyContent: 'end',
//               maxWidth: '100%',
//               margin: '0px',
//               padding: '0',
//               marginTop: '-10px',
//               marginBottom: '15px',
//               marginLeft: '-27px',
//             }}
//           >
//             <Button
//               outline
//               color="primary"
//               className="top-right-button"
//               onClick={() => {
//                 setPatientReportData({
//                   new: true,
//                 });
//               }}
//             >
//               <IntlMessages id={CONSTANTS.TABLE_ID.addPatientReport} />
//             </Button>
//           </Container>
//           <ViewTable
//             headers={CONSTANTS.TABLE_HEADER.PATIENT_REPORT}
//             items={patientReport}
//             advisorId={CONSTANTS.TABLE_ID.patientReport}
//             filterParams="name"
//           />
//           <AddNewModalWithOutButton
//             sidebarMenu="PATIENT_REPORT_MODAL"
//             modalTitle={CONSTANTS.TABLE_ID.addPatientReport}
//             modalOpen={patientReportData !== null && patientReportData?.new}
//             toggleModal={() => setPatientReportData(null)}
//             onSubmit={AddpationReportHandler}
//             formData={{}}
//             formFields={formFields}
//           />
//           <AddNewModalWithOutButton
//             sidebarMenu="EDIT_PATIENT_REPORT_MODAL"
//             modalTitle={CONSTANTS.TABLE_ID.editPatientReport}
//             modalOpen={
//               patientReportData !== null && patientReportData?.new !== true
//             }
//             toggleModal={() => setPatientReportData(null)}
//             onSubmit={EditpationReportHandler}
//             formData={{ patientReportData }}
//             formFields={editFormFields}
//           />
//           <AlertPopup
//             isOpen={deleteId != null}
//             positiveText="Yes"
//             negativeText="No"
//             warning="Are you sure you want to delete?"
//             onNegative={() => {
//               setDeleteId(null);
//             }}
//             onPositive={confirmDeletePatientReport}
//           />
//         </>
//       ) : (
//         <>
//           <Container className="d-flex justify-content-center align-items-center">
//             <Spinner
//               animation="border"
//               className="d-inline-flex m-2 "
//               color="$theme-color-yellow-granola"
//             />
//           </Container>
//         </>
//       )}
//     </Suspense>
//   );
// };
// export default PatientReport;

const PatientReport = ({ match }) => {
  const [reportList, setReportList] = useState([])
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editReportData, setEditReportData] = useState({})
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [editFormFields, setEditFormFields] = useState([]);
  const { id: patientId } = useParams();
  let labTestList = [];

  const API = useHttp()


  const AddpationReportHandler = (e) => {
    e.preventDefault();
    const payload = new FormData();
    CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_REPORT_MODAL.map((field) => {
      payload.append(field.id, e.target[field.id].value);
      // console.log(field.id, e.target[field.id].value);
      return 0;
    });
    const photo = document.querySelector('#files').files[0];
    payload.append('files', photo);
    payload.append('patientId', patientId);
    API.sendRequest(
      CONSTANTS.API.addReport,
      () => {
        setRefresh((previous) => !previous)
        setModalOpen(false)
      },
      payload
    );
    // setPatientReportData(null);
    // setPatientReportData(patientReportData === true);
  };


  const handleEditReport = (editValue) => {
    if (editValue) {
      setEditReportData({ ...editValue });
      setEditModalOpen(true);
    }
  };
  // const EditpationReportHandler = (e) => {
  //   e.preventDefault();

  //   const payload = new FormData();
  //   CONSTANTS.RIGHT_SIDEBAR_FIELD.EDIT_PATIENT_REPORT_MODAL.map((field) => {
  //     if (e.target[field.id].value) {

  //       payload.append(field.id, e.target[field.id].value);
  //     }
  //     return 0;
  //   });
  //   CONSTANTS.API.updatereport.endpoint =
  //     CONSTANTS.API.updatereport.endpoint.replace(
  //       ':id',
  //       `${editReportData?.id}?patientId=${patientId}`
  //     );

  //   API.sendRequest(
  //     CONSTANTS.API.updatereport,
  //     () => {
  //       setRefresh((previous) => !previous)
  //       setEditModalOpen(false)
  //     },
  //     payload
  //   );
  //   // setPatientReportData(null);
  // };
  const EditpationReportHandler = (e) => {
    e.preventDefault();
    const payload = new FormData();
    CONSTANTS.RIGHT_SIDEBAR_FIELD.EDIT_PATIENT_REPORT_MODAL.map((field) => {
      if (e.target[field.id].value && field.id !== 'files') {
        payload.append(field.id, e.target[field.id].value);

      }
      // console.log(field.id, e.target[field.id].value);
      return 0;
    });
    const photo = document.querySelector('#files').files[0];
    payload.append('files', photo);
    payload.append('patientId', patientId);
    CONSTANTS.API.updatereport.endpoint =
      CONSTANTS.API.updatereport.endpoint.replace(
        ':id',
        `${editReportData?.id}?patientId=${patientId}`
      );
    API.sendRequest(
      CONSTANTS.API.updatereport,
      () => {
        setRefresh((previous) => !previous)
        setEditModalOpen(false)
      },
      payload
    );
    // setPatientReportData(null);
    // setPatientReportData(patientReportData === true);
  };


  const handleDeleteReport = (reportId) => {
    setDeleteId(reportId);
    // setData(feedbackId.pid);
    setIsConfirmationOpen(true);
  };
  const confirmDeletePatientReport = () => {
    console.log('delete');
    const APIpoint = { ...CONSTANTS.API.deleteReport };
    APIpoint.endpoint = APIpoint.endpoint.replace(
      ':id',
      `${deleteId}?patientId=${patientId}`
    );
    API.sendRequest(APIpoint, () => {

      setDeleteId(null);
      setIsConfirmationOpen(false);
      setRefresh((prev) => !prev);
    });
  };





  useEffect(() => {
    (async () => {

      const resp = await getAllLabTest();
      if (resp !== -1) {
        labTestList = resp?.data?.data.map((item) => {
          return {
            label: item?.name,
            id: item?.id,
            value: item?.id,
          };
        });
      }

      setFormFields(() => {
        const tempValue = CONSTANTS.RIGHT_SIDEBAR_FIELD.PATIENT_REPORT_MODAL.forEach(
          (field) => {
            const newObj = field;
            if (newObj.name === 'labTestId') {
              newObj.option = labTestList;
            }
            // console.log(newObj, 'edit');
            return newObj;
          }
        );
        return tempValue;
      });
      setEditFormFields(() => {
        const tempValue = CONSTANTS.RIGHT_SIDEBAR_FIELD.EDIT_PATIENT_REPORT_MODAL.forEach(
          (field) => {
            const newObj = field;
            if (newObj.name === 'labTestId') {
              newObj.option = labTestList;
            }
            console.log(newObj, 'edit');
            return newObj;
          }
        );
        return tempValue;
      });
      const res = await getAllPatientReportsviaId(patientId);
      if (res !== -1) {
        setReportList(
          res?.data?.data.map((report, index) => {
            return {
              no: index + 1,
              ...report.labTest,

              fileView: {
                buttonLabel: 'View',
                id: { id: report?.id, file: report?.files },
                onClick: (data) => {
                  window.open(data.file);

                },
              },
              deleteData: {
                buttonLabel: 'Delete',
                id: report?.id,
                onClick: handleDeleteReport,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...report,
                  profilePic: '',

                },
                onClick: handleEditReport,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path=""
          exact
          render={() =>
            !API.isLoading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addFeedback} />
                  </Button>
                </Container>
                <AddNewModalWithOutButton
                  sidebarMenu="PATIENT_REPORT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.addFeedback}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={AddpationReportHandler}
                  formData={{}}
                  formFields={formFields}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="EDIT_PATIENT_REPORT_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editFeedback}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={EditpationReportHandler}
                  formData={editReportData}
                  formFields={editFormFields}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.PATIENT_REPORT}
                  items={reportList}
                  advisorId={CONSTANTS.TABLE_ID.feedback}
                  filterParams="name"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeletePatientReport}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};

export default PatientReport