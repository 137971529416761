import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
} from 'reactstrap';
import IntlMessages from 'helpers/IntlMessages';
import CONSTANTS from 'utils/CONSTANTS';

const AddNewModalWithOutButton = ({
  modalAlign = 'modal-right',
  modalOpen,
  toggleModal,
  sidebarMenu,
  modalTitle,
  onSubmit = () => { },
  formData,
  formFields = [],
}) => {
  const getInputFormate = (data) => {
    switch (data.type) {
      case 'select':
        return (
          <>
            <Label>
              <IntlMessages id={data.Label} />
            </Label>
            <Input
              id={data.id}
              type={data.type}
              size="1"
              required={data?.required}
              defaultValue={formData[data.name]}
            >
              {data.option &&
                data.option.length > 0 &&
                data.option.map((item) => (
                  <option
                    key={`role_${item.id}`}
                    value={item.value}
                  // defaultValue={formData[data.name] === item.value}
                  // selected={formData[data.name] === item.value}
                  >
                    {item.label ? item.label : item.value}
                  </option>
                ))}

              {formData[data.name] &&
                Array.isArray(formData[data.name]) &&
                formData[data.name].length > 0 &&
                formData[data.name].map((item) => (
                  <option
                    key={`role_${item.id}`}
                    value={item.value}
                    selected={formData[data.name] === item.value}
                  >
                    {item.value}
                  </option>
                ))}
            </Input>
          </>
        );
      case 'checkbox':
        return (
          <div style={{ padding: '0px 23px' }}>
            <Label for={data.id}>
              <Input
                id={data.id}
                name={data.name}
                type={data.type}
                size="1"
                required={data?.required}
                value={data?.value}
                defaultChecked={formData[data.name]}
              />
              <IntlMessages id={data.Label} />
            </Label>
          </div>
        );
      case 'textarea':
        return (
          <>
            <Label>
              <IntlMessages id={data.Label} />
            </Label>
            <Input
              id={data.id}
              name={data.name}
              type={data.type}
              style={{ height: data?.height || '35px' }}
              defaultValue={
                formData && data.type !== 'file' ? formData[data.name] : ''
              }
              required={data?.required}
            />
          </>
        );
      case 'password':
        return (
          <>
            <Label>
              <IntlMessages id={data.Label} />
            </Label>
            <Input
              id={data.id}
              name={data.name}
              type={data.type}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              title="Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character."

              defaultValue={
                formData && data.type !== 'file' ? formData[data.name] : ''
              }
              required={data?.required}
            />
          </>
        );
      default:
        return (
          <>
            <Label>
              <IntlMessages id={data.Label} />
            </Label>
            <Input
              id={data.id}
              name={data.name}
              type={data.type}
              defaultValue={
                formData && data.type !== 'file' ? formData[data.name] : ''
              }
              required={data?.required}
            />
          </>
        );
    }
  };
  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName={modalAlign}
      backdrop="static"
    >
      <ModalHeader toggle={toggleModal}>
        <IntlMessages id={modalTitle} />
      </ModalHeader>

      <Form onSubmit={onSubmit}>
        <ModalBody>
          {formFields && Array.isArray(formFields) && formFields.length > 0
            ? formFields.map((data) => (
              <div key={data.id} className="mt-2">
                {getInputFormate(data)}
              </div>
            ))
            : CONSTANTS.RIGHT_SIDEBAR_FIELD[sidebarMenu].map((data) => (
              <div key={data.id} className="mt-2">
                {getInputFormate(data)}
              </div>
            ))}
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" outline onClick={toggleModal}>
            <IntlMessages id="advisor.cancel" />
          </Button>
          <Button color="primary">
            <IntlMessages id="advisor.submit" />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

AddNewModalWithOutButton.defaultProps = {
  modalOpen: '',
  formData: [],
};

export default AddNewModalWithOutButton;
