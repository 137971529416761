import React, { useEffect, useState, Suspense } from 'react';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import CONSTANTS from 'utils/CONSTANTS';
import { useParams } from 'react-router-dom';
import useHttp from 'components/Hook/Use-http';
import { Container, Spinner } from 'reactstrap';
import { visiterEditPage } from 'utils/API/api';

import ViewTable from 'utils/ReactTableCards';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';

function toHoursAndMinutes(totalSeconds) {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let alpha = 0;
  if (hours > 0) {
    alpha = `${hours} hour`;
  } else if (minutes > 0) {
    alpha = `${minutes} min`;
  } else if (seconds > 0) {
    alpha = `${seconds} sec`;
  }

  return alpha;
}
function statutCheck(userVisited, doctorVisited) {
  let alpha = '';
  console.log(userVisited, 'jj', doctorVisited);
  if (userVisited === true && doctorVisited === true) {
    alpha = `Success`;
  } else if (userVisited === false && doctorVisited === false) {
    alpha = `Pending`;
  } else if (userVisited) {
    alpha = `User Visited`;
  } else if (doctorVisited) {
    alpha = `Doctor Visited`;
  }

  return alpha;
}

const DoctorVisitPage = () => {
  const [doctorVisitedPatientList, setDoctorVisitedPatientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visitData, setVisitData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const API = useHttp();
  const { id: doctorId } = useParams();

  const handleEditVisitPage = (editValue) => {
    if (editValue) {
      setVisitData({ ...editValue });
      setEditModalOpen(true);
    }
  };

  const getAllReportHandler = async (res) => {
    setDoctorVisitedPatientList(
      res?.data.map((report, i) => {
        return {
          no: i + 1,
          name: `${report?.patient?.firstName} ${report?.patient?.lastName}`,
          ...report,
          status: statutCheck(report.isUserVisited, report.isDoctorVisited),

          isUserVisited: report.isUserVisited ? 'Yes' : 'No',
          isDoctorVisited: report.isDoctorVisited ? 'Yes' : 'No',
          isReschedule: report.isReschedule ? 'Yes' : 'No',
          slotStartTime: report.slotStartTime,
          isCanceled: report.isCanceled ? 'Yes' : 'No',
          isFakeBooking: report.isFakeBooking ? 'Yes' : 'No',
          cancellationDuration: toHoursAndMinutes(report?.cancellationDuration),
          // `${(report?.cancellationDuration / 60).toFixed()} min`,

          editData: {
            buttonLabel: 'Edit',
            id: {
              ...report,
            },
            onClick: handleEditVisitPage,
          },
        };
      })
    );
  };

  useEffect(() => {
    setLoading(false);
    const APIpoint = { ...CONSTANTS.API.visiterPage };
    APIpoint.endpoint = APIpoint.endpoint.replace(':id', `${doctorId}`);

    API.sendRequest(APIpoint, getAllReportHandler);
  }, [refresh]);

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_EDIT_VISIT_PAGE.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });

    return payload;
  };

  const editVisitHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (visitData?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await visiterEditPage(visitData?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      {!loading ? (
        <>
          <ViewTable
            headers={CONSTANTS.TABLE_HEADER.DOCTOR_VISITED_PATIENT_LIST}
            items={doctorVisitedPatientList}
            advisorId={CONSTANTS.TABLE_ID.doctorVisitedPatientList}
            filterParams="name"
          />
          <AddNewModalWithOutButton
            sidebarMenu="DOCTOR_EDIT_VISIT_PAGE"
            modalTitle={CONSTANTS.TABLE_ID.editAppointment}
            modalOpen={editModalOpen}
            toggleModal={() => setEditModalOpen(!editModalOpen)}
            onSubmit={editVisitHandler}
            formData={visitData}
          />
        </>
      ) : (
        <>
          <Container className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              className="d-inline-flex m-2 "
              color="$theme-color-yellow-granola"
            />
          </Container>
        </>
      )}
    </Suspense>
  );
};

export default DoctorVisitPage;
