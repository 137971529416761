import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  addDoctorAvailability,
  deleteDoctorAvailability,
  editDoctorAvailability,
  getAllDoctorAvailability,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import moment from 'moment';
import { NotificationManager } from 'components/common/react-notifications';

const DoctorAvailability = () => {
  const { id: doctorId } = useParams();
  const [doctorAvailabilityList, setDoctorAvailabilityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [doctorAvailabilityData, setDoctorAvailabilityData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteDoctorAvailability = (doctorAvailabilityId) => {
    setDeleteId(doctorAvailabilityId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteDoctorAvailability = async () => {
    if (deleteId) {
      const res = await deleteDoctorAvailability(deleteId, doctorId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditDoctorAvailability = (editValue) => {
    console.log(editValue);
    if (editValue) {
      setDoctorAvailabilityData({ ...editValue });
      setEditModalOpen(true);
    }
  };



  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_AVAILABILITY_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });

    if (doctorId) {
      payload.doctorId = doctorId;
    }

    return payload;
  };
  const prepareEditFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_EDIT_AVAILABILITY_MODAL.forEach(
      (field) => {
        const tempValue = e.target[field.name].value;
        if (tempValue) {
          payload[field.name] = tempValue;
        }
      }
    );

    if (doctorId) {
      payload.doctorId = doctorId;
    }

    return payload;
  };

  const editDoctorAvailabilityHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (doctorAvailabilityData?.id) {
        const formData = prepareEditFormData(e);

        const utcStartTime = moment(moment(formData?.startTime, 'HH:mm').utc().utcOffset('+05:30').format())
        const utcEndTime = moment(moment(formData?.endTime, 'HH:mm').utc().utcOffset('+05:30').format())
        if (!utcStartTime.isBefore(utcEndTime)) {
          // console.log("Start time is not before end time.");
          NotificationManager.error(
            'Please ensure that the start time is earlier than the end time.',
            '',
            3000,
            null,
            null,
            ''
          );
          return;
        }

        // console.log(moment(formData?.startTime, 'HH:mm').utc().format());
        formData.startTime = moment(formData?.startTime, 'HH:mm').utc().utcOffset('+05:30').format('HH:mm:ss')

        formData.endTime = moment(formData?.endTime, 'HH:mm').utc().utcOffset('+05:30').format('HH:mm:ss')

        // if (!((formData.startTime).isBefore(formData.endTime))) {
        //   console.log("Start time is not before end time.");
        // }
        setLoading(true);
        const res = await editDoctorAvailability(
          doctorAvailabilityData?.id,
          doctorId,
          formData
        );
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addDoctorAvailabilityHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);
      // console.log(formData);

      formData.startTime = moment(formData?.startTime, 'HH:mm').utc().utcOffset('+05:30').format('HH:mm:ss')

      formData.endTime = moment(formData?.endTime, 'HH:mm').utc().utcOffset('+05:30').format('HH:mm:ss')

      const beginningTime = moment(formData.startTime, 'HH:mm');
      const endTime = moment(formData.endTime, 'HH:mm');

      // console.log(formData);
      if (beginningTime.isBefore(endTime)) {
        setLoading(true);
        const res = await addDoctorAvailability(formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setModalOpen(!modalOpen);
        }
        setLoading(false);
      } else {
        NotificationManager.warning(
          'Start time must be before End time ',
          'Time Duration not correct',
          3000,
          null,
          null,
          ''
        );
      }
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
      const res = await getAllDoctorAvailability(doctorId);
      if (res !== -1) {
        const availableWeeks = Object.values(res?.data?.weeklyData);
        let availableDays = [];
        availableWeeks.forEach((el) => {
          availableDays.push(...el);
        });
        // let demoDays = [...availableDays];
        availableDays = availableDays.map((el) => {
          return {
            ...el,
            endTime: moment(el.endTime, 'HH:mm:ss').format('HH:mm:ss'),
            startTime: moment(el.startTime, 'HH:mm:ss').format('HH:mm:ss'),
            endTime2: moment(el.endTime, 'HH:mm:ss').format('LT'),
            startTime2: moment(el.startTime, 'HH:mm:ss').format('LT'),
          };
        });
        setDoctorAvailabilityList(
          availableDays.map((doctorAvailabilityItem, index) => {
            const duration = moment.duration(
              moment(doctorAvailabilityItem.endTime, 'HH:mm:ss').diff(
                moment(doctorAvailabilityItem.startTime, 'HH:mm:ss')
              )
            );
            const hours = duration.asHours();

            return {
              no: index + 1,
              ...doctorAvailabilityItem,
              duration: `${hours?.toFixed(2)} Hour`,
              deleteData: {
                buttonLabel: 'Delete',
                id: doctorAvailabilityItem?.id,
                onClick: handleDeleteDoctorAvailability,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...doctorAvailabilityItem,
                },
                onClick: handleEditDoctorAvailability,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      {!loading ? (
        <>
          <Container
            style={{
              display: 'flex',
              justifyContent: 'end',
              maxWidth: '100%',
              margin: '0px',
              padding: '0',
              marginTop: '-10px',
              marginBottom: '15px',
              marginLeft: '-27px',
            }}
          >
            <Button
              outline
              color="primary"
              className="top-right-button"
              onClick={() => setModalOpen(true)}
            >
              <IntlMessages id={CONSTANTS.TABLE_ID.addDoctorAvailability} />
            </Button>
          </Container>
          <AddNewModalWithOutButton
            sidebarMenu="DOCTOR_AVAILABILITY_MODAL"
            modalTitle={CONSTANTS.TABLE_ID.addDoctorAvailability}
            modalOpen={modalOpen}
            toggleModal={() => setModalOpen(!modalOpen)}
            onSubmit={addDoctorAvailabilityHandler}
            formData={{}}
          />

          <AddNewModalWithOutButton
            sidebarMenu="DOCTOR_EDIT_AVAILABILITY_MODAL"
            modalTitle={CONSTANTS.TABLE_ID.editDoctorAvailability}
            modalOpen={editModalOpen}
            toggleModal={() => setEditModalOpen(!editModalOpen)}
            onSubmit={editDoctorAvailabilityHandler}
            formData={doctorAvailabilityData}
          />

          <ViewTable
            headers={CONSTANTS.TABLE_HEADER.DOCTOR_AVAILABILITY_LIST}
            items={doctorAvailabilityList}
            advisorId={CONSTANTS.TABLE_ID.doctorAvailability}
            filterParams="day"
          />

          <AlertPopup
            isOpen={isConfirmationOpen}
            positiveText="Yes"
            negativeText="No"
            warning="Are you sure you want to delete?"
            onNegative={() => {
              setIsConfirmationOpen(false);
              setDeleteId(null);
            }}
            onPositive={confirmDeleteDoctorAvailability}
          />
        </>
      ) : (
        <>
          <Container className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              className="d-inline-flex m-2 "
              color="$theme-color-yellow-granola"
            />
          </Container>
        </>
      )}
    </Suspense>
  );
};
export default DoctorAvailability;
