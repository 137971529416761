import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  addDoctorPublishedPapers,
  deleteDoctorPublishedPapers,
  editDoctorPublishedPapers,
  getAllDoctorPublishedPapers,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';

const DoctorPublishedPapers = () => {
  const { id: doctorId } = useParams();
  const [doctorPublishedPapersList, setDoctorPublishedPapersList] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [doctorPublishedPapersData, setDoctorPublishedPapersData] = useState(
    {}
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteDoctorPublishedPapers = (doctorPublishedPapersId) => {
    setDeleteId(doctorPublishedPapersId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteDoctorPublishedPapers = async () => {
    if (deleteId) {
      const res = await deleteDoctorPublishedPapers(deleteId, doctorId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditDoctorPublishedPapers = (editValue) => {
    if (editValue) {
      setDoctorPublishedPapersData({ ...editValue });
      setEditModalOpen(true);
    }
  };



  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.DOCTOR_PUBLISHED_PAPERS_MODAL.forEach(
      (field) => {
        const tempValue = e.target[field.name].value;
        if (tempValue) {
          payload[field.name] = tempValue;
        }
      }
    );

    if (doctorId) {
      payload.doctorId = doctorId;
    }

    if (e.target?.file?.files && e.target?.file?.files[0]) {
      payload.file = e.target?.file?.files[0];
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return formData;
  };

  const editDoctorPublishedPapersHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (doctorPublishedPapersData?.id) {
        const formData = prepareFormData(e);
        setLoading(true);
        const res = await editDoctorPublishedPapers(
          doctorPublishedPapersData?.id,
          doctorId,
          formData
        );
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addDoctorPublishedPapersHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      setLoading(true);
      const res = await addDoctorPublishedPapers(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
      const res = await getAllDoctorPublishedPapers(doctorId);
      if (res !== -1) {
        setDoctorPublishedPapersList(
          res?.data?.data.map((doctorPublishedPapersItem, index) => {
            return {
              no: index + 1,
              ...doctorPublishedPapersItem,
              deleteData: {
                buttonLabel: 'Delete',
                id: doctorPublishedPapersItem?.id,
                onClick: handleDeleteDoctorPublishedPapers,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...doctorPublishedPapersItem,
                },
                onClick: handleEditDoctorPublishedPapers,
              },
            };
          })
        );
      }
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      {!loading ? (
        <>
          <Container
            style={{
              display: 'flex',
              justifyContent: 'end',
              maxWidth: '100%',
              margin: '0px',
              padding: '0',
              marginTop: '-10px',
              marginBottom: '15px',
              marginLeft: '-27px',
            }}
          >
            <Button
              outline
              color="primary"
              className="top-right-button"
              onClick={() => setModalOpen(true)}
            >
              <IntlMessages id={CONSTANTS.TABLE_ID.addDoctorPublishedPapers} />
            </Button>
          </Container>
          <AddNewModalWithOutButton
            sidebarMenu="DOCTOR_PUBLISHED_PAPERS_MODAL"
            modalTitle={CONSTANTS.TABLE_ID.addDoctorPublishedPapers}
            modalOpen={modalOpen}
            toggleModal={() => setModalOpen(!modalOpen)}
            onSubmit={addDoctorPublishedPapersHandler}
            formData={{}}
          />

          <AddNewModalWithOutButton
            sidebarMenu="EDIT_DOCTOR_PUBLISHED_PAPERS_MODAL"
            modalTitle={CONSTANTS.TABLE_ID.editDoctorPublishedPapers}
            modalOpen={editModalOpen}
            toggleModal={() => setEditModalOpen(!editModalOpen)}
            onSubmit={editDoctorPublishedPapersHandler}
            formData={doctorPublishedPapersData}
          />

          <ViewTable
            headers={CONSTANTS.TABLE_HEADER.DOCTOR_PUBLISHED_PAPERS_LIST}
            items={doctorPublishedPapersList}
            advisorId={CONSTANTS.TABLE_ID.doctorPublishedPapers}
            filterParams="name"
          />

          <AlertPopup
            isOpen={isConfirmationOpen}
            positiveText="Yes"
            negativeText="No"
            warning="Are you sure you want to delete?"
            onNegative={() => {
              setIsConfirmationOpen(false);
              setDeleteId(null);
            }}
            onPositive={confirmDeleteDoctorPublishedPapers}
          />
        </>
      ) : (
        <>
          <Container className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              className="d-inline-flex m-2 "
              color="$theme-color-yellow-granola"
            />
          </Container>
        </>
      )}
    </Suspense>
  );
};
export default DoctorPublishedPapers;
