import React, { Suspense, useState, useEffect } from 'react';
import { Button, Container, Input, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import Papa from 'papaparse';
import {
  addMedicine,
  deleteMedicine,
  editMedicine,
  getAllMedicine,
} from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';

const Medicine = ({ match }) => {
  const [medicineList, setMedicineList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [medicineData, setMedicineData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const commonConfig = { delimiter: ',' };
  const handleDeleteMedicine = (clientId) => {
    setDeleteId(clientId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteMedicine = async () => {
    if (deleteId) {
      const res = await deleteMedicine(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleEditMedicine = (editValue) => {
    if (editValue) {
      setMedicineData({ ...editValue });
      setEditModalOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllMedicine();
      if (res !== -1) {
        setMedicineList(
          res?.data?.data.map((medicineItem, index) => {
            return {
              no: index + 1,
              ...medicineItem,
              name: medicineItem?.name || '-',
              strength: medicineItem?.strength?.toString() || '-',
              deleteData: {
                buttonLabel: 'Delete',
                id: medicineItem?.id,
                onClick: handleDeleteMedicine,
              },
              editData: {
                buttonLabel: 'Edit',
                id: {
                  ...medicineItem,
                  profilePic: '',
                },
                onClick: handleEditMedicine,
              },
            };
          })
        );
      }
      setLoading(false);
    })();
  }, [refresh]);

  const prepareFormData = (e) => {
    const payload = {};
    CONSTANTS.RIGHT_SIDEBAR_FIELD.MEDICINE_MODAL.forEach((field) => {
      const tempValue = e.target[field.name].value;
      if (tempValue) {
        payload[field.name] = tempValue;
      }
    });

    return payload;
  };

  const editMedicineHandler = (e) => {
    e.preventDefault();
    (async () => {
      if (medicineData?.id) {
        const formData = prepareFormData(e);

        setLoading(true);
        const res = await editMedicine(medicineData?.id, formData);
        if (res !== -1) {
          setRefresh((previous) => !previous);
          setEditModalOpen(!editModalOpen);
        }
        setLoading(false);
      }
    })();
  };

  const addMedicineHandler = (e) => {
    e.preventDefault();
    (async () => {
      const formData = prepareFormData(e);

      setLoading(true);
      const res = await addMedicine(formData);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setModalOpen(!modalOpen);
      }
      setLoading(false);
    })();
  };
  const csvHandler = (e) => {
    const fileInput = e.target.files[0];

    Papa.parse(fileInput, {
      ...commonConfig,
      header: true,
      complete: (result) => {
        const csvData = result.data?.filter((ele) => ele.name);
        console.log(csvData);
        (async () => {
          let count = 0;
          csvData?.forEach(async (ele) => {
            const payload = new FormData();
            payload.append('name', ele?.name);
            payload.append('price', ele?.price);
            payload.append('discount', ele?.discount);
            payload.append('description', ele?.description);
            payload.append('strength', ele?.strength);

            const res = await addMedicine(payload);
            if (res !== -1) {
              count += 1;
              if (count === csvData.length) {
                console.log('hjgj');
                setRefresh((previous) => !previous);
              }
            }
          });
        })();
      },
    });
  };

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addMedicine} />
                  </Button>
                </Container>
                <AddNewModalWithOutButton
                  sidebarMenu="MEDICINE_MODAL"
                  dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.addMedicine}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addMedicineHandler}
                  formData={{}}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="MEDICINE_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editMedicine}
                  modalOpen={editModalOpen}
                  toggleModal={() => setEditModalOpen(!editModalOpen)}
                  onSubmit={editMedicineHandler}
                  formData={medicineData}
                />
                <Button
                  outline
                  color="primary"
                  style={{
                    position: 'relative',
                    top: '-12px',
                    left: '-15px',
                    margin: '12px',
                    overflow: 'hidden',
                  }}
                  onClick={() => { }}
                >
                  <Input
                    style={{
                      position: 'absolute',
                      marginLeft: '-20px',
                      marginTop: '-10px',
                      height: '40px',
                      opacity: '0',
                      cursor: 'pointer',
                    }}
                    type="file"
                    id="myfile"
                    name="myfile"
                    onChange={csvHandler}
                  />
                  <IntlMessages id="button.import" />
                </Button>
                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.MEDICINE_LIST}
                  items={medicineList}
                  advisorId={CONSTANTS.TABLE_ID.medicine}
                  filterParams="name"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteMedicine}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default Medicine;
