import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
import React, { useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
// import { Button } from 'reactstrap';

import CONSTANTS from 'utils/CONSTANTS';
import Profile from '../../assets/img/blog/small-3.jpg';
import classes from '../../assets/css/custome/topp.module.css';

const countStyle = {
  marginTop: '9px',
  fontWeight: '800',
};

const LableValueCard = ({ title, value, logo, displayLogo, btn }) => {
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  return (
    <Card className="w-100">
      <CardBody className="d-flex align-items-center">
        <div>
          {displayLogo ? (
            <div className={`${classes.container} w-30`}>
              {/* <img src="img_avatar.png" alt="Avatar" class="image" style="width:100%" /> */}

              <img
                className={classes.image}
                style={{
                  borderRadius: '50%',
                  border: '1px solid yellow',
                  objectFit: 'cover',
                }}
                src={logo || Profile}
                height="70px"
                width="70px"
                alt="Logo"
              />
              <div className={classes.middle}>
                <div className={`${classes.text}`}>
                  <i className="iconsminds-file-edit d-block" />
                </div>
                <input
                  className={`${classes.text} alpha-second`}
                  style={{
                    position: 'relative',
                    top: '-100%',
                    // left: '-100%',
                    zIndex: 1000,
                    opacity: 0,
                  }}
                  onChangeCapture={btn}
                  type="file"
                />

                {/* <input type="file" /> */}
              </div>
            </div>
          ) : (
            <div className={classes.container}>
              {/* <img src="img_avatar.png" alt="Avatar" class="image" style="width:100%" /> */}
              <img
                style={{
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                src={logo || Profile}
                height="70px"
                width="70px"
                alt="Logo"
              />
              {/* <div className={classes.middle}>
                <div className={`${classes.text}`}>
                  
                </div>
                <input
                  className={`${classes.text} alpha-second`}
                  style={{ position: 'relative', top: '-100%' }}
                  onChangeCapture={btn}
                  type="file"
                /> */}
              {/* <input type="file" /> */}
              {/* </div> */}
            </div>
          )}
        </div>
        <div
          className={`d-flex justify-content-between align-items-center w-100 ${
            logo ? 'ml-4' : ''
          }`}
          style={{ marginLeft: '20px' }}
        >
          {/* <input type="file" onChange={handleChange} /> */}
          <CardTitle className="mb-0">
            {title}
            <h2 style={countStyle}>{value}</h2>
          </CardTitle>
        </div>
      </CardBody>
      <AddNewModalWithOutButton
        sidebarMenu="DOCTOR_EDIT_PIC"
        modalTitle={CONSTANTS.TABLE_ID.editDoctor}
        modalOpen={editProfileModalOpen}
        toggleModal={() => setEditProfileModalOpen(!editProfileModalOpen)}
        onSubmit={btn}
        formData={{}}
      />
    </Card>
  );
};

LableValueCard.defaultProps = {
  title: 'title',
  value: 0,
  displayLogo: true,
};

export default React.memo(LableValueCard);
