import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS, { ROUTES } from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import { deleteOrder, editOrderTracking, getAllOrder } from 'utils/API/api';
import AddNewModalWithOutButton from 'components/advisor/add-new-Modal-with-out-button';
// import IntlMessages from 'helpers/IntlMessages';
import AlertPopup from 'components/alert-popup';
import moment from 'moment';
import { NotificationManager } from 'components/common/react-notifications';

const Order = ({ match }) => {
  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState({});

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteOrder = (clientId) => {
    setDeleteId(clientId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteOrder = async () => {
    if (deleteId) {
      const res = await deleteOrder(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };

  const handleAddDeliveryData = (editValue) => {
    console.log(editValue);
    if (editValue) {
      setOrderData({ ...editValue });
      setModalOpen((prev) => !prev);
    }
  };

  const MEDICINE_ORDER_OPTIONS = [
    { id: 'Pending', label: 'Pending', value: 'pending' },
    { id: 'Accepted', label: 'Accepted', value: 'accepted' },
    { id: 'Dispatch', label: 'Dispatch', value: 'dispatch' },
    { id: 'Delivered', label: 'Delivered', value: 'delivered' },
  ];

  //   const handleEditOrder = (editValue) => {
  //     if (editValue) {
  //       console.log(editValue);
  //       setOrderData({ ...editValue });
  //       setModalOpen(true);
  //     }
  //   };



  // const prepareFormData = (e) => {
  //   const payload = {};
  //   CONSTANTS.RIGHT_SIDEBAR_FIELD.ORDER_FORM_MODAL.forEach((field) => {
  //     const tempValue = e.target[field.name].value;
  //     console.log(field.name, tempValue);
  //     if (tempValue) {
  //       payload[field.name] = tempValue;
  //     }
  //   });

  //   return payload;
  // };

  const editOrderHandler = (e) => {
    e.preventDefault();

    if (e.target.deliveryBoyContactNumber.value.length !== 10) {
      NotificationManager.error(
        'Error message',
        'Mobile no should be valid 10 digits',
        2000,
        () => {
          alert('callback');
        },
        null,
        null
      );
    } else {
      (async () => {
        if (orderData?.orderTracking?.id) {
          // const formData = prepareFormData(e);
          const payload = {
            status: 'dispatch',
            deliveryBoyName: e?.target?.deliveryBoyName?.value,
            deliveryBoyContactNumber: e?.target?.deliveryBoyContactNumber?.value
          }

          setLoading(true);
          const res = await editOrderTracking(
            orderData?.orderTracking?.id,
            payload
          );
          if (res !== -1) {

            setModalOpen(!modalOpen);
            NotificationManager.success(
              'Success message',
              'Delivery Boy Data Successfully Added',
              2000,
              () => {
                alert('callback');
              },
              null,
              null
            );
            setRefresh((previous) => !previous);
          }
          setLoading(false);
        }
      })();
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAllOrder();
      if (res !== -1) {
        setOrderList(
          res?.data?.data.map((orderItem, index) => {
            return {
              no: index + 1,
              ...orderItem,
              name: `${orderItem?.patient?.firstName || "-"} ${orderItem?.patient?.lastName || ''}`,
              mobile: orderItem?.patient?.mobile,
              total: ` ₹ ${(orderItem?.total)?.toFixed(2) || 0}`,
              view: `${ROUTES.ORDER}/${orderItem?.id}`,
              deleteData: {
                buttonLabel: 'Delete',
                id: orderItem?.id,
                onClick: handleDeleteOrder,
              },

              deliveryDate: orderItem?.orderTracking?.orderDeliveryDate &&
                moment(orderItem?.orderTracking?.orderDeliveryDate).format('YYYY-MM-DD'),

              orderDispatchDate: orderItem?.orderTracking?.orderDispatchDate &&
                moment(orderItem?.orderTracking?.orderDispatchDate).format('YYYY-MM-DD'),

              estimateDeliveryTime: orderItem?.orderTracking?.estimateDeliveryTime &&
                moment(orderItem?.orderTracking?.estimateDeliveryTime).format('YYYY-MM-DD'),


              status: {
                options: MEDICINE_ORDER_OPTIONS,
                defaultOption: orderItem?.orderTracking?.status,
                onChange: (val) => {
                  (async () => {
                    if (orderItem?.orderTracking?.id) {
                      let payload = {};
                      const tempValue = val.target.value;
                      if (tempValue) {
                        if (tempValue === 'dispatch') {
                          payload = { status: `${tempValue}` };
                          handleAddDeliveryData(orderItem);
                        } else {
                          payload = { status: `${tempValue}` };
                          const respo = await editOrderTracking(
                            orderItem?.orderTracking?.id,
                            payload
                          );
                          if (respo !== -1) {
                            NotificationManager.success(
                              'Status Updated Successfully!',
                              'Success',
                              3000,
                              null,
                              ''
                            );
                          }
                        }
                      }


                    }
                  })();
                },
              },

              // pickDate: orderItem,
              // transitDate,
              //   editData: {
              //     buttonLabel: 'Edit',
              //     id: {
              //       ...orderItem,
              //       profilePic: '',
              //     },
              //     onClick: handleEditOrder,
              //   },
            };
          })
        );
      }
      setLoading(false);
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                {/* <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    className="top-right-button"
                    onClick={() => setModalOpen(true)}
                  >
                    <IntlMessages id={CONSTANTS.TABLE_ID.addOrder} />
                  </Button>
                </Container> */}
                {/* <AddNewModalWithOutButton
                  sidebarMenu="PLAN_MODAL"
                  dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.addOrder}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={addOrderHandler}
                  formData={{}}
                />

                <AddNewModalWithOutButton
                  sidebarMenu="PLAN_MODAL"
                  modalTitle={CONSTANTS.TABLE_ID.editOrder}
                  modalOpen={modalOpen}
                  toggleModal={() => setModalOpen(!modalOpen)}
                  onSubmit={editOrderHandler}
                  formData={orderData}
                /> */}

                <AddNewModalWithOutButton
                  modalAlign="center"
                  sidebarMenu="ORDER_FORM_MODAL"
                  dataId={CONSTANTS.TABLE_ID.add}
                  modalTitle={CONSTANTS.TABLE_ID.addDeliveryboyInfo}
                  modalOpen={modalOpen}
                  toggleModal={() => {
                    setModalOpen(!modalOpen)
                    setRefresh(prev => !prev)
                  }}
                  onSubmit={editOrderHandler}
                  formData={{}}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.ORDER_LIST}
                  items={orderList}
                  advisorId={CONSTANTS.TABLE_ID.order}
                  filterParams="name"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteOrder}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default Order;
